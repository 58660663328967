import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import ShopUrlRepository from './services/shopUrlRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'shopUrl',
})
@AutoMutations
export class ShopUrl extends VuexModule {
    private url: string|null = null;

    private navUrl: string|null = null;

    private loading: boolean = false;

    private navigationLoading: boolean = false;

    @Mutation
    public setUrl(val: string|null) {
        this.url = val;
    }

    @Mutation
    public setNavigationUrl(val: string|null) {
        this.navUrl = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setNavigationLoading(val: boolean) {
        this.navigationLoading = val;
    }

    @Action()
    public async getUrl(openLink: string|null = null) {
        try {
            this.setUrl(null);
            this.setLoading(true);

            const result = await ShopUrlRepository
                .getShopUrl({ redirectTo: openLink });
            const { shopPreAuthUrl } = result.profile;
            this.setUrl(shopPreAuthUrl);

            if (this.url === null) {
                throw new Error();
            }
        } catch (e) {
            ErrorsProcessor.process(e);
            throw e;
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async getNavUrl(openLink: string|null = null) {
        try {
            this.setNavigationUrl(null);
            this.setNavigationLoading(true);

            const result = await ShopUrlRepository
                .getShopUrl({ redirectTo: openLink });
            const { shopPreAuthUrl } = result.profile;
            this.setNavigationUrl(shopPreAuthUrl);

            if (this.navUrl === null) {
                throw new Error();
            }
        } catch (e) {
            ErrorsProcessor.process(e);
            throw e;
        } finally {
            this.setNavigationLoading(false);
        }
    }
}

export default getModule(ShopUrl);
