
import { RankRequirementsType } from '@/api/graphQL/graphNodes/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import ListItem from '@/views/BusinessShape/ListItem/index.vue';
import BusinessShapeLegend from '@/views/BusinessShape/Legend/index.vue';

@Component({
    components: {
        ListItem,
        BusinessShapeLegend,
    },
})
export default class BusinessShapeItem extends Vue {
    @Prop({ required: true }) private activeCount!: RankRequirementsType[];

    @Prop({ required: true }) private inactiveCount!: RankRequirementsType[];

    @Prop({ required: true }) private partiallyActiveCount!: RankRequirementsType[];

    get maxValue(): number {
        const maxDictionary = new Array<number>(this.levelCount);
        for (let i = 0; i <= this.levelCount; i++) {
            maxDictionary[i] = 0;
        }
        [...this.activeCount, ...this.inactiveCount]
            .forEach((item: RankRequirementsType) => {
                maxDictionary[item.metric] += Number(item.value);
            });
        const maxValue = Math.max(...maxDictionary);

        return maxValue;
    }

    get levelCount(): number {
        const allLevels: number[] = [0];

        this.activeCount.forEach((item) => allLevels.push(Number(item.metric)));
        this.inactiveCount.forEach((item) => allLevels.push(Number(item.metric)));
        this.partiallyActiveCount.forEach((item) => allLevels.push(Number(item.metric)));

        return Math.max(...allLevels);
    }

    active(metric) {
        // eslint-disable-next-line eqeqeq
        return this.activeCount.find((item) => item.metric == metric);
    }

    inactive(metric) {
        // eslint-disable-next-line eqeqeq
        return this.inactiveCount.find((item) => item.metric == metric);
    }

    partiallyActive(metric) {
    // eslint-disable-next-line eqeqeq
        return this.partiallyActiveCount.find((item) => item.metric == metric);
    }
}
