import {
    enrollee, directDownline, personalRecruits, getRunTotals,
} from './enrollee';
import { ranks } from './ranks';
import { enrolleeBreadcrumbs } from './enrolleeBreadcrumbs';
import { eventInvitation } from './eventInvitations';
import { stats } from './stats';
import { rankRequirement } from './rankRequirements';
import { me } from './me';
import { customersSales, order, orderTotal } from './sales';
import { hostesses } from './hostesses';
import { event, findEvents, eventsStatistics } from './events';
import { totalCustomerSales } from './totalSales';
import { products, product } from './products';
import { ambassadorCountStat } from './ambassadorsCounts';
import { calendar } from './calendar';
import { Data } from '../types';
import getToken from '../services/tokenResolver';
import ambassadorInvitation from './invitation';
import { filteredAssetList as assets, searchAssets, searchAssetsCount } from './assets';
import { assetCategories } from './assetCategories';
import { risingStars } from './risingStars';
import { newStarters } from './newStarters';
import { termsAndConditions } from './termsAndConditions';
import { incomeSummary } from './incomeSummary';
import { transactions, balance } from './transactions';
import { hostessRewards } from './hostessReward';
import { runs } from './runs';
import { reports } from './reports';
import { countries, regions } from './countries';
import { businessDetails } from './businessDetails';
import { achievementAward, achievementAwardByType } from './achievementAward';
import { milestone } from './milestones';

const data: Data = {
    ranks,
    enrollee,
    directDownline,
    enrolleeBreadcrumbs,
    eventInvitation,
    profile: enrollee(getToken()),
    stats,
    rankRequirement,
    me,
    sales: customersSales,
    order,
    hostesses,
    event,
    findEvents,
    eventsStatistics,
    totalCustomerSales,
    products,
    product,
    orderTotal,
    ambassadorCountStat,
    ambassadorInvitation,
    assets,
    assetCategories,
    searchAssets,
    searchAssetsCount,
    calendar,
    personalRecruits,
    newStarters,
    risingStars,
    termsAndConditions,
    incomeSummary,
    transactions,
    balance,
    hostessRewards,
    runs,
    reports,
    countries,
    regions,
    businessDetails,
    achievementAward,
    achievementAwardByType,
    milestone,
    getRunTotals,
};

export default data;
