
// @ts-ignore
import Pdf from 'vue-pdf';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    components: {
        Pdf,
    },
})
export default class PdfViewer extends Vue {
    @Prop({ required: true }) private src!: string;

    private task = Pdf.createLoadingTask(this.src);

    private pages: number = 0

    get elementClasses() {
        return [
            'PdfViewer__Container',
            'pts-layout pts-gutter',
        ];
    }

    handleErrors(e) {
        console.error(e);
    }

    async handlePageCalculation() {
        const task = await this.task;

        task.promise.then((pdf) => {
            // eslint-disable-next-line no-underscore-dangle
            this.pages = pdf._pdfInfo.numPages;
        });
    }

    handleLoaded() {
        this.$emit('loaded');
    }

    async mounted() {
        this.handlePageCalculation();
    }
}
