import {
    Module, VuexModule, Mutation, getModule,
} from 'vuex-module-decorators';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import LocalStorageManager from '@/utils/local-storage-manager';

export const APP_SHOWN_STORAGE_KEY = 'app-install-shown';

export interface UserSettings {
    shown: boolean
    theme: string
}

@Module({
    namespaced: true, dynamic: true, store, name: 'settings',
})
@AutoMutations
class Settings extends VuexModule {
    userSettings: UserSettings = {
        shown: LocalStorageManager.getStorage(APP_SHOWN_STORAGE_KEY) === 'true',
        theme: 'default',
    }

    installReady = false;

    private cameraMaxSizeMobile: number = 300;

    private cameraMaxSizeTablet: number = 540;

    private cameraMaxSizeDesktop: number = 800;

    private eventGuestsInviteOptionEnabled: boolean = false;

    get getEventGuestsInviteOptionEnabled() {
        return this.eventGuestsInviteOptionEnabled;
    }

    @Mutation
    setEventGuestsInviteOptionEnabled(val: boolean) {
        this.eventGuestsInviteOptionEnabled = val;
    }

    @Mutation
    setSettings(settings: UserSettings) {
        this.userSettings = settings;
    }

    @Mutation
    setInstallReady(value: boolean) {
        this.installReady = value;
    }

    @Mutation
    setShown(value: boolean) {
        LocalStorageManager.setStorage(APP_SHOWN_STORAGE_KEY, value);
        this.userSettings = { ...this.userSettings, shown: value };
    }

    @Mutation
    setTheme(value: string) {
        this.userSettings = { ...this.userSettings, theme: value };
    }
}

export default getModule(Settings);
