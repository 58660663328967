
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import downlineExplorer from '@/modules/DownlineExplorer';
import profile from '@/modules/Profile';
import DetailsDialog from '@/projectComponents/enrolleeLegItem/DetailsDialogContent/index.vue';
import { DownlineExplorerType, DownlineLegType } from '@/api/graphQL/graphNodes/types';
import businessShape from '@/modules/BusinessShape';
import { Debounced } from '@/utils/debounced';
import { SelectOptionsType } from '@/components/select/types';
import system from '@/modules/System';

@Component({
    components: {
        DetailsDialog,
    },
})
export default class HeaderWrapper extends Vue {
    @Get(downlineExplorer, 'data') private leg!: DownlineExplorerType;

    @Get(downlineExplorer) private headerDetailsOpen!: boolean;

    @Get(downlineExplorer) private level!: number;

    @Get(downlineExplorer) private filter!: string|null;

    @Sync(downlineExplorer) private searchQuery!: string;

    @Get(downlineExplorer) private searchLoading!: boolean;

    @Get(downlineExplorer) private minSearchLength!: number;

    @Get(downlineExplorer) private searchedLegsOptions!: DownlineLegType[];

    @Get(downlineExplorer) private displaySearchResults!: boolean;

    @Get(downlineExplorer) private offset!: number;

    @Get(downlineExplorer) private displaySearch!: boolean;

    @Get(profile, 'data.id') private myId!: string;

    @Get(system) private screenType!: string;

    get searchOptions(): SelectOptionsType[] {
        return this.searchedLegsOptions.map((leg) => ({
            name: `${leg.firstName} ${leg.lastName}`,
            value: `${leg.firstName} ${leg.lastName}`,
        }));
    }

    get fullName():string {
        return `${this.leg.firstName} ${this.leg.lastName}`;
    }

    get rankTitle():string {
        return this.leg.rank.label;
    }

    get disableUp(): boolean {
        return this.level === 0;
    }

    get showSearch() {
        return this.screenType !== 'mobile' || this.displaySearch;
    }

    get searchContainerClasses() {
        return [
            'pts-layout-item',
            'pts-size-50',
            'pts-xsmall-size-100',
            'HeaderWrapper__SearchWrapper',
            { HeaderWrapper__ContainerAnimated: this.screenType === 'mobile' },
            { 'HeaderWrapper__SearchWrapper--show': this.showSearch },
        ];
    }

    get headerNavigationClasses() {
        return [
            'HeaderWrapper__Navigation',
            'pts-layout',
            { 'HeaderWrapper__Navigation--hide': !this.showSearch },
        ];
    }

    get filterOptions(): SelectOptionsType[] {
        return [
            {
                name: 'All',
                value: null,
            },
            {
                name: 'N1',
                value: 'N1',
            },
            {
                name: 'N2',
                value: 'N2',
            },
            {
                name: 'N3',
                value: 'N3',
            },
            {
                name: 'N4',
                value: 'N4',
            },
            {
                name: 'N5',
                value: 'N5',
            },
        ];
    }

    handleDetailModal(val: boolean) {
        downlineExplorer.setHeaderDetailsOpen(val);
    }

    handleUp(legId: string) {
        downlineExplorer.setOffset(0);
        downlineExplorer.getDownlineData({ id: legId });
        businessShape.getAmbassadorsCounts(legId);
    }

    handleSelect(val) {
        downlineExplorer.setOffset(0);
        downlineExplorer.setSearchQuery(val.value);
        downlineExplorer.setDisplaySearchResults(true);
        downlineExplorer.search({ selectedSearch: true });
    }

    @Debounced(1000)
    handleChange() {
        if (this.searchQuery === '') {
            downlineExplorer.setDisplaySearchResults(false);
            downlineExplorer.setSearchedLegs([]);
            downlineExplorer.setSearchedOptionsLegs([]);
        }

        if (this.searchQuery.length >= this.minSearchLength) {
            downlineExplorer.search();
        }
    }
}
