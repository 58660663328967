
import { availableRoute } from '@/router/services/availableRoute';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { RouteType } from './types';

@Component
export default class NavItem extends Vue {
    @Prop() private icon!: string;

    @Prop({ default: '' }) private iconUrl!: string;

    @Prop({ required: true }) private title!: string;

    @Prop() private route!: RouteType;

    @Prop({ default: '_self' }) private target!: string;

    @Prop({ default: '' }) private externalLink!: string

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: true }) private ariaHidden!: boolean;

    @Prop({ default: false }) private actionOnClick!: boolean;

    get isDisabled() {
        return this.disabled || (this.route && !availableRoute(this.route.name));
    }

    handleClick() {
        this.$emit('click');
    }
}
