import {
    STOPLIGHT_STATUS_TYPE_ALMOST, STOPLIGHT_STATUS_TYPE_FULFILLED,
    STOPLIGHT_STATUS_TYPE_INSUFFICIENT,
} from '@/modules/constants';

export default (percentage: number): string => {
    if (percentage < 75) {
        return STOPLIGHT_STATUS_TYPE_INSUFFICIENT;
    } if (percentage >= 75 && percentage < 100) {
        return STOPLIGHT_STATUS_TYPE_ALMOST;
    }

    return STOPLIGHT_STATUS_TYPE_FULFILLED;
};
