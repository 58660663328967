
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import personalStatements from '@/modules/IncomeSummary/personalStatements';
import { AssetType, PersonalCommissionStatementsType } from '@/api/graphQL/graphNodes/types';
import env from '@/environment';
import IncomeSummaryPreview from '@/views/IncomeSummary/Statements/Preview/index.vue';
import authenticator from '@/modules/Auth/services/authenticator';
import { PaginationParamsType } from '@/components/pagination/types';

@Component({ components: { IncomeSummaryPreview } })
export default class IncomeSummaryPersonalStatements extends Vue {
    @Get(personalStatements) private loadingInBackground!: boolean;

    @Get(personalStatements) private files!: PersonalCommissionStatementsType[];

    @Get(personalStatements) private previewOpen!: boolean;

    @Get(personalStatements) private offset!: number;

    @Get(personalStatements) private limit!: number;

    @Get(personalStatements) private count!: number;

    @Get(personalStatements) private previewFileId!: string;

    get serverUrl(): string {
        return env.VUE_APP_SERVER_URL;
    }

    get fileList(): AssetType[] {
        return personalStatements.fileList;
    }

    get token(): string {
        return authenticator.authTokenFromStorage || '';
    }

    get noFiles(): boolean {
        return this.fileList.length === 0;
    }

    openPreview(id: string): void {
        personalStatements.setPreviewOpen(true);
        personalStatements.setPreviewFileId(id);
    }

    generateDownloadLink(file: AssetType) {
        const asset = this.files.find((item) => item.id === file.id)!;
        return `${this.serverUrl}${asset.presignedDownloadUrl}?token=${this.token}`;
    }

    handleDialogClose(): void {
        personalStatements.setPreviewOpen(false);
        personalStatements.setPreviewFileId('');
    }

    handlePagination(props: PaginationParamsType) {
        personalStatements.setOffset(props.offset);
        personalStatements.getStatements({ inBackground: true });
    }

    mounted() {
        personalStatements.setPreviewOpen(false);
    }
}
