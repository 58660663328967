import { RouteType } from '@/router/routes';

const defaultRoute: () => RouteType = () => ({
    name: '',
    path: '',
    title: '',
    subTitle: '',
});

export { defaultRoute };
