
import { Vue, Component, Prop } from 'vue-property-decorator';
import LegItem from '@/projectComponents/enrolleeLegItem/Item/index.vue';
import { CGet, Get } from '@/utils/vuex-module-mutators';
import downlineExplorerLegs from '@/modules/DownlineExplorer/leg';
import downlineExplorer from '@/modules/DownlineExplorer';
import businessShape from '@/modules/BusinessShape';
import { DownlineExplorerType, RankType } from '@/api/graphQL/graphNodes/types';
import rank from '@/modules/Rank';
import { DownlineLegItemType, LegType } from '@/modules/types';
import RankRequirementManager from '@/utils/rankRequirementManager';
import {
    MIN_PV,
    STOPLIGHT_STATUS_TYPE_ALMOST, STOPLIGHT_STATUS_TYPE_FULFILLED,
    STOPLIGHT_STATUS_TYPE_INSUFFICIENT,
} from '@/modules/constants';

@Component({
    components: {
        LegItem,
    },
})
export default class DownlineLegsListItem extends Vue {
    @Prop({ required: true }) id!: string;

    @CGet(downlineExplorerLegs) data!: DownlineLegItemType;

    @CGet(downlineExplorerLegs) loading!: boolean;

    @Get(downlineExplorer, 'data') parent!: DownlineExplorerType;

    @Get(downlineExplorer) expandedDownline!: string[];

    @Get(rank) ranks!: RankType[];

    @Get(downlineExplorer) activeSponsorDetailsModal!: string;

    get minPv() {
        return MIN_PV;
    }

    get insufficient() {
        return STOPLIGHT_STATUS_TYPE_INSUFFICIENT;
    }

    get fulfilled() {
        return STOPLIGHT_STATUS_TYPE_FULFILLED;
    }

    get almost() {
        return STOPLIGHT_STATUS_TYPE_ALMOST;
    }

    get calculatedStatus(): string {
        const { stats } = this.data;
        const pvStat = stats.find((item) => item.metric === RankRequirementManager
            .rankRequirementMetricConstants.pv);
        const pv = pvStat ? Number(pvStat.value) : 0;

        if (pv === 0) {
            return this.insufficient;
        } if (pv < this.minPv) {
            return this.almost;
        }

        return this.fulfilled;
    }

    get withExplore(): boolean {
        return this.data.directDownlineCount > 0;
    }

    getDownlineItemExpanded(leg: LegType) {
        return !!this.expandedDownline.find((item) => item === leg.id);
    }

    handleExpandedContentToggle(val: string) {
        downlineExplorer.toggleExpandedList(val);
        downlineExplorer.setActiveSponsorDetailsModal('');
    }

    beforeDestroy() {
        downlineExplorer.setActiveSponsorDetailsModal('');
    }

    handleExplore(legId:string) {
        downlineExplorer.setSearchQuery('');
        downlineExplorer.setDisplaySearchResults(false);
        downlineExplorer.getDownlineData({ id: legId });
        businessShape.getAmbassadorsCounts(legId);
    }

    handleSponsorDetailDialog(val: string) {
        downlineExplorer.setActiveSponsorDetailsModal(val);
    }
}
