const ambassadorInviteMutations = 'inviteAmbassador (email: String!, firstName: String!, lastName: String!): Invitation';

const registerInput = `input ProfileRegisterInput {
    firstName: String!,
    lastName: String!,
    email: String!,
    password: String,
    repeatPassword: String,
    phoneNumber: String,
    addressLookup: String!,
    birthDate: String,
    region: String,
    termsChecked: Boolean,
}`;

const hostessRegisterInput = `input HostessRegisterInput {
    firstName: String!,
    lastName: String!,
    email: String!,
    country: String!,
    password: String!,
    repeatPassword: String!,
    phoneNumber: String,
    termsChecked: Boolean!,
    region: String,
    addressLookup: String
}`;

const registerBankDetailsInput = `input RegisterBankDetailsInput {
    bankAccountName: String,
    bankAccountNumber: String,
    bankSortCode: String,
}`;

const registerMutations = `
    registerAmbassador (register: ProfileRegisterInput): Enrollee
    registerHostess (register: HostessRegisterInput): Enrollee
    registerCustomer (register: HostessRegisterInput): Enrollee
`;

export {
    ambassadorInviteMutations, registerMutations, registerInput, registerBankDetailsInput,
    hostessRegisterInput,
};
