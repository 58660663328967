
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import EventInvitationModule from '@/modules/Event/EventInvitation';
import EventInvitationTermsAndConditionsModule from '@/modules/Event/EventInvitation/termsAndConditionModule';
import { EVENT_ATTENDANCE_STATUS } from '@/modules/Event/constants';
import FileViewer from '@/projectComponents/fileViewer/index.vue';
import { AssetType } from '@/api/graphQL/graphNodes/types';

@Component({
    components: {
        FileViewer,
    },
})
export default class EventInvitationAttendance extends Vue {
    @Prop({ required: true }) private attending!: string;

    @Prop({ required: true }) private shopUrl!: string;

    @Prop({ required: true }) private hostessInvitation!: boolean;

    @Prop({ required: true }) private withTerms!: boolean;

    @Prop({ required: true }) private canceled!: boolean;

    @Get(EventInvitationModule) private responseSending!: boolean;

    @Get(EventInvitationModule) private declineModalOpen!: boolean;

    @Get(EventInvitationTermsAndConditionsModule) private termsAndConditions!: AssetType|null;

    @Get(EventInvitationTermsAndConditionsModule, 'loaded') private termsAndConditionsLoaded!: boolean;

    @Get(EventInvitationTermsAndConditionsModule) private dialogOpen!: boolean;

    @Sync(EventInvitationTermsAndConditionsModule) private agreed!: boolean;

    private termsAndConditionEnable = false;

    private isDocumentLoading = true;

    get buttonTooltipText(): string {
        return 'To perform this action you must agree to terms and conditions';
    }

    get isAccepted() {
        return this.attending === EVENT_ATTENDANCE_STATUS.yes;
    }

    get isDeclined() {
        return this.attending === EVENT_ATTENDANCE_STATUS.declined;
    }

    get isTentative() {
        return this.attending === EVENT_ATTENDANCE_STATUS.tentative;
    }

    get isResponded() {
        return this.attending !== null;
    }

    get attendingLabel() {
        switch (this.attending) {
        case EVENT_ATTENDANCE_STATUS.yes:
            return 'Accepted';
        case EVENT_ATTENDANCE_STATUS.declined:
            return 'Declined';
        case EVENT_ATTENDANCE_STATUS.tentative:
            return 'Tentative';
        default:
            return '';
        }
    }

    get attendingIcon() {
        switch (this.attending) {
        case EVENT_ATTENDANCE_STATUS.yes:
            return 'event_available';
        case EVENT_ATTENDANCE_STATUS.declined:
            return 'event_busy';
        case EVENT_ATTENDANCE_STATUS.tentative:
            return 'event_note';
        default:
            return '';
        }
    }

    get disableButtons(): boolean {
        return (!this.agreed && this.withTerms && this.termsAndConditionsExists)
         || this.canceled;
    }

    get termsAndConditionsExists() {
        return this.termsAndConditionsLoaded
         && this.termsAndConditions !== null;
    }

    handleAttendingSubmit(status: string) {
        const { hostessInvitation } = this;
        const token = this.$route.query.token.toString();

        switch (status) {
        case 'accept':
            EventInvitationModule.respondToInvitation({
                attending: EVENT_ATTENDANCE_STATUS.yes,
                token,
                hostessInvitation,
            });
            break;
        case 'decline':
            EventInvitationModule.respondToInvitation({
                attending: EVENT_ATTENDANCE_STATUS.declined,
                token,
                hostessInvitation,
            });
            break;
        case 'tentative':
            EventInvitationModule.respondToInvitation({
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                token,
                hostessInvitation,
            });
            break;
        default:
            break;
        }
    }

    handleDecline() {
        EventInvitationModule.setDeclineModalOpen(false);
        this.handleAttendingSubmit('decline');
    }

    handleDeclineModalCancel() {
        EventInvitationModule.setDeclineModalOpen(false);
    }

    handleDeclineModalOpen() {
        EventInvitationModule.setDeclineModalOpen(true);
    }

    handleTermsDialog() {
        EventInvitationTermsAndConditionsModule
            .setDialogOpen(!this.dialogOpen);
    }

    handleTermsChecked() {
        EventInvitationTermsAndConditionsModule.setAgreed(true);
        EventInvitationTermsAndConditionsModule
            .setDialogOpen(!this.dialogOpen);
    }

    handleLoaded() {
        setTimeout(this.setupReadDocument, 1000);
    }

    setupReadDocument() {
        this.isDocumentLoading = false;

        const el = this.$refs.termsAndConditionsPreviewContainer as Element;
        if (el.clientHeight > 0 && el.scrollHeight > el.clientHeight) {
            el.addEventListener('scroll', this.handleDocumentEnd);
        } else {
            this.termsAndConditionEnable = true;
        }
    }

    handleDocumentEnd(event) {
        const el = this.$refs.termsAndConditionsPreviewContainer as Element;

        const {
            scrollHeight, clientHeight, scrollTop,
        } = event.target;

        if (Math.abs(scrollHeight - clientHeight - scrollTop) < 20) {
            this.termsAndConditionEnable = true;
            el.removeEventListener('scroll', this.handleDocumentEnd);
        }
    }

    mounted() {
        EventInvitationTermsAndConditionsModule.setAgreed(false);
    }
}
