// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { NewStartersSearchType, NewStartersType } from './types';
import parentObject from './reusable/listItemEnrolleeParent';

export default class GetNewStartersQuery extends
    AbstractQueryResource<GetNewStartersResultType|GetNewStartersSearchResultType> {
    protected getQuery(options = { type: '' }): string {
        const { type } = options;
        return `query ${this.getName()}($date: DateTime!, $type: String!${type === '' ? ', $offset: Int!, $limit: Int!' : ', $query: String!'}) {
            profile {
                ${type === '' ? 'newStartersCommissionEngine' : 'searchNewStarters'}(date: $date, type: $type, ${type === '' ? 'offset: $offset, limit: $limit' : 'query: $query'}) {
                    id,
                    email,
                    phoneNumber,
                    address,
                    secondPhoneNumber,
                    secondAddress,
                    county,
                    country,
                    region,
                    town,
                    postCode,
                    ${parentObject.getQuery(true)},
                    firstName,
                    lastName,
                    kitPurchaseDate,
                    joinDate,
                    rank {
                        id,
                        label,
                        power,
                        requirements {
                            metric,
                            value
                        },
                    },
                    payRank {
                        id,
                        label,
                        power,
                    },
                    superStarterOneTotals {
                        pv,
                    },
                    superStarterTwoTotals {
                        pv,
                    },
                    totals {
                        pv,
                        gv,
                        ov
                    },
                    highestAchievedRank {
                        id,
                        label,
                        power,
                    },
                    personalRecruitsCount,
                    stats {
                        metric,
                        value,
                    },
                },
                ${type === '' ? 'newStartersCountCommissionEngine(date: $date, type: $type),' : ''}
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getNewStarters';
    }
}

export type GetNewStartersResultType = {
    profile: {
        newStartersCommissionEngine: NewStartersType[];
        newStartersCountCommissionEngine: number;
    }
}

export type GetNewStartersSearchResultType = {
    profile: {
        searchNewStarters: NewStartersSearchType[];
    }
}

export type NewStartersParamsType = {
    date: string;
    type: string;
    offset?: number;
    limit?: number;
}

export type NewStartersSearchParamsType = NewStartersParamsType & {
    query: string;
}
