import { EVENT_TYPE } from '@/modules/Event/constants';

export default (type: string): string => {
    switch (type) {
    case EVENT_TYPE.showcase: {
        return 'Product showcase';
    }
    case EVENT_TYPE.getTogether: {
        return 'Get together';
    }
    case EVENT_TYPE.campaignLaunch: {
        return 'Campaign launches';
    }
    case EVENT_TYPE.generalMeeting: {
        return 'General meeting';
    }
    case EVENT_TYPE.school: {
        return 'School';
    }
    default: {
        return '';
    }
    }
};
