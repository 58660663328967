export default class RankRequirementManager {
    static readonly rankRequirementMetricConstants = {
        starterKit: 'ambassadorKit',
        // personalRecruits: 'qualified_personal_legs',
        personalRecruits: 'qualified_group_branch_legs',
        qualifiedBranches: 'qualified_branches',
        pv: 'pv',
        gv: 'gv',
        uv: 'uv',
        ov: 'ov',
        leaderLegs: 'qualified_leader_branches',
        ambassadorLegs: 'qualified_group_legs',
        qualifiedLeaders: 'qualified_leaders',
        // activeOnFileStylists: 'active_on_file_direct_ambassadors',
        activeOnFileStylists: 'qualified_group_branch_active_on_file_legs',
        qualifiedFrontlineLeaders: 'qualified_frontline_leaders',
    }

    public static getRankRequirementLabel(val: string, amount: number = 0) {
        switch (val) {
        case RankRequirementManager.rankRequirementMetricConstants.starterKit: {
            return {
                title: 'Starter kit',
                withValue: false,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.personalRecruits: {
            return {
                title: 'Active Personal Stylists',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.qualifiedBranches: {
            return {
                title: 'Qualified Stylist Legs',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.activeOnFileStylists: {
            return {
                title: 'Active on File Personal Stylists',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.qualifiedFrontlineLeaders: {
            return {
                title: '1st Level Qualified Star Stylist Legs',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.pv: {
            return {
                title: 'FLV',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.gv: {
            return {
                title: 'Personal Team Sales',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.uv: {
            return {
                title: 'Unencumbered Personal Team Sales',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.ov: {
            return {
                title: 'OV',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.leaderLegs: {
            return {
                title: 'Qualified Star Stylist Legs',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.ambassadorLegs: {
            return {
                title: `Active Personal ${amount === 1 ? 'Stylist' : 'Stylists'}`,
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.qualifiedLeaders: {
            return {
                title: 'Qualified Leaders',
                withValue: true,
            };
        }
        default: {
            return {
                title: '',
                withValue: false,
            };
        }
        }
    }
}
