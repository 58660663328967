
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { RankType } from '@/api/graphQL/graphNodes/types';
import rank from '@/modules/Rank';
import system from '@/modules/System';
import leaderLegs from '@/modules/LeaderLegs';
import legsRenderingList from '@/modules/LeaderLegs/legsRenderingList';
import { PaginationParamsType } from '@/components/pagination/types';
import { scrollToTop } from '@plumtreesystems/utils';
import { LeaderLegsDisplayItemType } from '@/modules/LeaderLegs/types';
import LegItem from './item.vue';

@Component({
    components: {
        LegItem,
    },
})
export default class LegsList extends Vue {
    @Get(legsRenderingList) private legs!: LeaderLegsDisplayItemType[];

    @Get(legsRenderingList) private searchedLegs!: LeaderLegsDisplayItemType[];

    @Get(legsRenderingList) private expandedLegs!: string[];

    @Get(leaderLegs) private activeLegDetailsDialog!: string;

    @Get(leaderLegs) private expandedLeaderLegs!: string[];

    @Get(leaderLegs) private legsCount!: number;

    @Get(leaderLegs) private loadingInBackground!: boolean;

    @Get(leaderLegs) private limit!: number;

    @Get(leaderLegs) private offset!: number;

    @Get(leaderLegs) private displaySearchResults!: boolean;

    @Get(system) private screenType!: string;

    @Get(rank) private ranks!: RankType[];

    get displayLoadMore(): boolean {
        if (this.displaySearchResults) {
            return false;
        }
        return this.limit <= this.legsCount;
    }

    get isMobile(): boolean {
        return this.screenType === 'mobile';
    }

    get legsSource(): LeaderLegsDisplayItemType[] {
        if (this.displaySearchResults) {
            return this.searchedLegs;
        }
        return this.legs;
    }

    get displayLegs(): (LeaderLegsDisplayItemType[])[] {
        let legArr: LeaderLegsDisplayItemType[] = [];
        const res: (LeaderLegsDisplayItemType[])[] = [];
        let lockedLevel: null|number = null;

        this.legsSource.forEach((leg, index) => {
            if (leg.level === 0 && index !== 0) {
                res.push(legArr);
                legArr = [];
            }

            if (!lockedLevel || (lockedLevel && leg.level <= lockedLevel)) {
                if (this.showLeg(leg.parentId) || leg.level === 0) {
                    lockedLevel = null;
                    legArr.push(leg);
                } else {
                    lockedLevel = leg.level;
                }
            }

            if (index === this.legsSource.length - 1) {
                res.push(legArr);
            }
        });

        return res;
    }

    async handlePagination(props: PaginationParamsType) {
        const { offset } = props;
        await leaderLegs.getLeaderLegs({ loadPage: true, offset });
        leaderLegs.clearExpandedList();
        scrollToTop('#app > div.App__Layout > div > div.Scene__Content');
    }

    isFirstInLevel(): boolean {
    // isFirstInLevel(item, position) {
        // const list = this.displayLegs[item];
        // const thisItem = list[position];
        // const previousItem = list[position - 1];

        // if (!previousItem) {
        //     return true;
        // }

        // return thisItem.level !== previousItem.level;
        return true;
    }

    showLeg(id): boolean {
        return !!this.expandedLegs.find((item) => item === id);
    }
}
