import store from '@/store';
import { CollectionModule, CollectionVuexModule } from '@/utils/vuex-module-mutators';
import { getModule, Mutation } from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetLevelsLeaderLegsSearchResultType } from '@/api/graphQL/graphNodes/GetLeaderLegsQuery';
import { GenealogyEnrolleeType, LeaderLegsType } from '@/api/graphQL/graphNodes/types';
import legsRenderingList from './legsRenderingList';
import leaderLegsRepository from './services/leaderLegsRepository';
import { defaultMinimalParentLeg } from '../defaults';
import { BREAKAWAY_RANK } from './constants';

export class Leg {
    id: string = '';

    parentId: string = '';

    data: GenealogyEnrolleeType = defaultMinimalParentLeg();

    legs: string[] = [];

    level: number = -1;

    loading: boolean = false;

    loadingLegs: boolean = false;

    openLevelLegs: boolean = false;
}

@CollectionModule({
    namespaced: true, dynamic: true, store, name: 'leaderLegsLeg', item: Leg,
})
class LeaderLegsLegModule extends CollectionVuexModule<Leg> {
    @Mutation
    public setLoading(params: { id: string, val: boolean }) {
        const { id, val } = params;

        if (this.collection[id]) {
            this.collection[id].loading = val;
        }
    }

    @Mutation
    public setLoadingLegs(params: { id: string, val: boolean }) {
        const { id, val } = params;

        if (this.collection[id]) {
            this.collection[id].loadingLegs = val;
        }
    }

    @Mutation
    public setData(params: { id: string, val: GenealogyEnrolleeType}) {
        const { id, val } = params;

        if (this.collection[id]) {
            this.collection[id].data = { ...this.collection[id].data, ...val };
        }
    }

    @Mutation
    public addLegs(params: { id: string, val: string}) {
        const { id, val } = params;

        if (this.collection[id]) {
            const { legs } = this.collection[id];

            if (legs) {
                this.collection[id].legs = [...legs, val];
            }
        }
    }

    @Action()
    public async loadDetails(id) {
        try {
            this.setLoading({ id, val: true });
            const res = await leaderLegsRepository.getLeg({ id });
            this.setData({ id, val: res.enrollee });
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading({ id, val: false });
        }
    }

    @Action()
    public async loadLegs(props : {id: string, level: number, search: boolean }) {
        const { id, level, search } = props;

        try {
            this.setLoadingLegs({ id, val: true });
            const res: GetLevelsLeaderLegsSearchResultType = await leaderLegsRepository
                .getLeaderLegs({
                    limit: 20, offset: 0, id, breakawayRank: BREAKAWAY_RANK,
                }, 'levels') as GetLevelsLeaderLegsSearchResultType;

            res.enrollee.leaderLegs.forEach((item) => {
                this.handleLegCreate({
                    val: item, level, parentId: id, search,
                });
                this.addLegs({ id, val: item.id });
            });
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoadingLegs({ id, val: false });
        }
    }

    @Action()
    handleLegCreate(data: {val: LeaderLegsType, level: number, parentId: string, search: boolean}) {
        const {
            val, level, parentId, search,
        } = data;
        const leg = new Leg();
        const old = this.collection[val.id];

        leg.id = val.id;
        leg.parentId = parentId;
        leg.level = level;
        leg.data = { ...leg.data, ...val };

        if (search) {
            legsRenderingList.addSearchedLegs([{ id: val.id, parentId, level }]);
        } else {
            legsRenderingList.addData([{ id: val.id, parentId, level }]);
        }

        if (old) {
            this.removeElement(val.id);
        }

        this.addElement(leg);
    }
}

export default getModule(LeaderLegsLegModule);
