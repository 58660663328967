import {
    ResolverAchievementAwardType, ResolverLeaderboardListItem,
    ResolverAchievementAwardItemType,
} from '../types';
import { leaderboardItems } from './leaderboard';
import { enrollee } from './enrollee';

const achievementAwards: {
    enrolleeId: string,
    data: ResolverAchievementAwardType[],
    leaderboardList: string[],
}[] = [
    {
        enrolleeId: '0',
        data: [
            {
                tooltip: 'Any Stylist, any start date based on FLV from party order types only',
                type: 'top_partner_party',
                title: 'Top Stylist (Party)',
                description: 'FLV from Party orders',
                leaderboard: [],
            },
            {
                tooltip: 'Stylist who joined this year based on FLV from party order types only',
                type: 'top_new_partner_party',
                title: 'Top Stylist (Party)',
                description: 'FLV from Party orders',
                leaderboard: [],
            },
            // {
            //     tooltip: 'Any Stylist, any start date based on FLV
            //  from school order types only',
            //     type: 'top_partner_schools',
            //     title: 'Top Stylist (Schools)',
            //     description: 'FLV from School orders',
            //     leaderboard: [],
            // },
            // {
            //     tooltip: 'Stylist who joined this year based on FLV
            // from school order types only',
            //     type: 'top_new_partner_schools',
            //     title: 'Top Stylist (Schools)',
            //     description: 'FLV from School orders',
            //     leaderboard: leaderboardItems('1'),
            // },
            {
                tooltip: 'Any leadership, any start date based on personal team sales',
                type: 'top_leader',
                title: 'Top Leader',
                description: 'New Recruit Sales',
                leaderboard: leaderboardItems('0'),
            },
            {
                tooltip: 'Stylists who joined this year based on personal team sales',
                type: 'top_new_leader',
                title: 'Top New Leader',
                description: 'New Recruit Sales',
                leaderboard: leaderboardItems('3'),
            },
            {
                tooltip: 'Any Stylist, any start date based on points earned from QuickStart Achievements of Personal Recruits',
                type: 'top_recruiter',
                title: 'Top Recruiter',
                description: 'Team QuickStart Achievements',
                leaderboard: leaderboardItems('1'),
            },
            {
                tooltip: 'Stylist who joined this year based on points earned from QuickStart Achievements of Personal Recruits',
                type: 'top_new_recruiter',
                title: 'Top New Recruiter',
                description: 'Team QuickStart Achievements',
                leaderboard: leaderboardItems('2'),
            },
            {
                tooltip: 'Top Travel Incentive Sellers',
                type: 'top_travel_seller',
                title: 'Top Travel Incentive Sellers',
                description: 'Top Travel Incentive Sellers',
                leaderboard: leaderboardItems('2'),
            },
            {
                tooltip: 'Top Travel Incentive Recruiters',
                type: 'top_travel_recruiter',
                title: 'Top Travel Incentive Recruiters',
                description: 'Top Travel Incentive Recruiters',
                leaderboard: leaderboardItems('1'),
            },
            {
                tooltip: 'Top Travel Promoting',
                type: 'top_travel_promoting',
                title: 'Top Travel Promoting',
                description: 'Top Travel Promoting',
                leaderboard: leaderboardItems('3'),
            },
            {
                tooltip: 'Top Travel Incentive Leaders',
                type: 'top_travel_leader',
                title: 'Top Travel Incentive Leaders',
                description: 'Top Travel Incentive Leaders',
                leaderboard: leaderboardItems('0'),
            },
            {
                tooltip: 'TBC',
                type: 'top_travel_overall',
                title: 'Top Travel Overall',
                description: 'Top Travel Overall',
                leaderboard: leaderboardItems('0'),
            },
        ],
        leaderboardList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21'],
    },

];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const achievementAward: (enrolleeId: string) => ResolverAchievementAwardType[] = (enrolleeId) => {
    const res = achievementAwards.find((award) => award.enrolleeId === '0');
    return res ? res.data : [];
};

const achievementAwardByType: (enrolleeId: string, limit: number, skip: number,
 type?: string, query?: string) => ResolverAchievementAwardItemType = (
     enrolleeId, limit, skip, type = 'top_leader', query = '',
 ) => {
     const res = achievementAwards.find((award) => award.enrolleeId === '0');

     if (!res) {
         return {
             type,
             description: '',
             tooltip: '',
             leaderboard: [],
             title: '',
             leaderboardLength: 0,
         };
     }

     const award = res.data.find((item) => item.type === type)!;

     const leaderboard: ResolverLeaderboardListItem[] = res.leaderboardList.map((item, index) => {
         const leaderboardEnrollee = enrollee(item)!;
         const { firstName, lastName } = leaderboardEnrollee;
         const differ = index % 3;
         let previousPosition = index;

         switch (differ) {
         case 0:
             break;
         case 1:
             previousPosition = index + 2;
             break;
         case 2:
             previousPosition = index - 1;
             break;
         default:
             break;
         }

         return {
             awardId: `${index}`, // Not correct Id. It's not used in return query.
             position: index + 1,
             enrolleeId: item,
             name: `${firstName} ${lastName}`,
             previousPosition: previousPosition + 1,
             metricValue: `${Math.floor(index * 31.4)}`,
         };
     });

     if (award.leaderboard[0]) {
         // eslint-disable-next-line prefer-destructuring
         leaderboard[award.leaderboard[0].position - 1] = award.leaderboard[0];
     }

     let leaderboardRes = [...leaderboard];

     if (query && query !== '') {
         leaderboardRes = leaderboardRes
             .filter((item) => item.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()));
     }

     return {
         ...award,
         leaderboardLength: leaderboardRes.length,
         leaderboard: leaderboardRes.splice(skip, limit),
     };
 };

export { achievementAward, achievementAwardByType };
