const TEXT_FIELD_DISPLAY_TYPE = {
    iban: 'IBAN',
    sortCode: 'SORT_CODE',
    swift: 'SWIFT',
    birthDate: 'BIRTH_DATE',
    bankAccountNumber: 'BANK_ACCOUNT_NUMBER',
};

export {
    TEXT_FIELD_DISPLAY_TYPE,
};
