
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ErrorType } from '@plumtreesystems/utils';
import { EVENT_HOST_TYPE } from '@/modules/Event/constants';
import BaseAddressSelect from '@/projectComponents/addressSelect/base.vue';
import { HostessFormType } from '@/api/graphQL/graphNodes/types';

@Component({
    components: {
        BaseAddressSelect,
    },
})
export default class HostessForm extends Vue {
    @Prop() private eventHostType!: number;

    @Prop({ required: true }) private hostess!: HostessFormType;

    @Prop({ required: true }) private formErrors!: ErrorType;

    handleHostessUpdate(val, name) {
        const data = { ...this.hostess };
        data[name] = val;
        this.$emit('input', data);
    }

    get disabled(): boolean {
        return this.eventHostType === EVENT_HOST_TYPE.known;
    }
}
