
import { Vue, Component } from 'vue-property-decorator';
import eventCreate from '@/modules/Event/EventCreate';
import { EVENT_HOST_TYPE } from '@/modules/Event/constants';
import HostessMe from '@/views/Events/Components/HostContent/hostessMe.vue';
import KnownHostess from '@/views/Events/Components/HostContent/knownHostess.vue';
import NewHostess from '@/views/Events/Components/HostContent/newHostess.vue';
import NoHostess from '@/views/Events/Components/HostContent/noHostess.vue';
import { defaultEnrollee } from '@/modules/Calendar/defaults';

@Component({
    components: {
        HostessMe,
        KnownHostess,
        NewHostess,
        NoHostess,
    },
})
export default class CreateEventHostess extends Vue {
    get eventHostMe() {
        return EVENT_HOST_TYPE.me;
    }

    get eventHostKnown() {
        return EVENT_HOST_TYPE.known;
    }

    get eventHostNew() {
        return EVENT_HOST_TYPE.new;
    }

    handleHostSelect(host: number) {
        eventCreate.clearEventCreate();
        eventCreate.setEventHostType(host);

        if (host === this.eventHostKnown) {
            this.$router.push({ name: 'createEventKnownHostess' });
        } else {
            if (host !== this.eventHostMe) {
                eventCreate.setHostess(defaultEnrollee());
            }
            this.$router.push({ name: 'createEventDetails' });
        }
    }
}
