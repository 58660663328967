import { BusinessDetailsType } from '@/api/graphQL/graphNodes/types';
import { ResolverBusinessDetailsType } from '../types';

const details: ResolverBusinessDetailsType[] = [
    {
        businessName: 'Sarah business',
        businessAddress: '61 St Giles, Oxford OX1 3LZ, United Kingdom',
        id: '0',
    },
];

const businessDetails: (id: string) => null|BusinessDetailsType = (id) => {
    const res = details.find((item) => item.id === id);
    return res || null;
};

export {
    businessDetails,
};
