
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ProductType } from '@/api/graphQL/graphNodes/types';
import CurrencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component
export default class ProductTable extends Vue {
    @Prop() private product!: ProductType;

    @Prop() private region!: string;

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }
}
