
import { Component, Prop, Vue } from 'vue-property-decorator';
import { fileDownload } from '@plumtreesystems/utils';
import axios from 'axios';
import { assetFileResolver, getType } from '@/projectComponents/fileViewer/service/fileResolvers';
import { AssetType } from '@/api/graphQL/graphNodes/types';
import componentsControls from '@/modules/ComponentsControls';

@Component
export default class File extends Vue {
    @Prop({ required: true }) private file!: AssetType;

    @Prop({ default: true }) private downloadable!: boolean;

    @Prop({ default: false }) private preview!: boolean;

    @Prop({ default: false }) private comingSoon!: boolean;

    @Prop({ default: () => () => null }) private downloadNameGenerator!: any;

    private downloading = false;

    get downloadLink() {
        return this.downloadNameGenerator(this.file.id);
    }

    get title() {
        const { title, originalFileName } = this.file;

        if (title) {
            return title;
        }

        return originalFileName;
    }

    get type() {
        const type = getType(this.file);
        if (!type) {
            return '';
        }

        return assetFileResolver(type.value);
    }

    get isDownloadable() {
        return this.downloadable && this.type !== 'externalFile';
    }

    handlePreview() {
        if (this.type === 'externalFile') {
            window.open(this.file.originalFileName, '_blank');
        } else {
            this.$emit('preview');
        }
    }

    handleDownload() {
        this.downloading = true;
        componentsControls.showSuccessMessage({ message: `Downloading ${this.file.fileName}` });

        axios
            .get(this.downloadLink, {
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, this.file.originalFileName);
            })
            .catch(() => {
                componentsControls.showErrorMessage({ message: `File ${this.file.fileName} download failed` });
            })
            .finally(() => {
                this.downloading = false;
            });
    }
}
