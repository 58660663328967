
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class NavSection extends Vue {
    @Prop({ required: true }) private title!: string;

    get classes() {
        return [
            'Navigation__LinksSection',
            this.childrenCount === 0 ? 'Navigation__LinksSection--hidden' : '',
        ];
    }

    get childrenCount() {
        if (this.$slots.default) {
            return this.$slots.default!.length;
        }

        return 0;
    }
}
