
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import nextRankResolver from '@/modules/Rank/services/nextRankResolver';
import dashboard from '@/modules/Dashboard';
import dashboardStats from '@/modules/Dashboard/stats';
import { RankType, RunsType } from '@/api/graphQL/graphNodes/types';
import { rank as defaultRank } from '@/modules/Rank/defaults';
import { SelectOptionsType } from '@/components/select/types';
import Stats from '../Stats/index.vue';

@Component({
    components: {
        Stats,
    },
})
export default class RankContainer extends Vue {
    @Sync(dashboardStats) private selectedRun!: string;

    @Get(dashboard, 'data.participatedRuns') private participatedRuns!: RunsType[];

    @Get(dashboardStats) private ranks!: RankType[];

    @Get(dashboard) private activeTab!: string;

    @Get(dashboardStats, 'loading') private statsLoading!: boolean;

    @Get(dashboardStats) private commissionRunRankId!: string;

    @Get(dashboardStats) private commissionRunPayRankId!: string;

    get currentRank(): RankType {
        return this.ranks.find((rankItem) => rankItem.id === this.commissionRunRankId)!;
    }

    get payRank(): RankType {
        return this.ranks.find((rankItem) => rankItem.id === this.commissionRunPayRankId)!;
    }

    get nextRank(): RankType {
        if (this.currentRank === null) {
            return defaultRank();
        }

        const nextRankItem = nextRankResolver(this.currentRank.power);

        return nextRankItem || defaultRank();
    }

    get nextRankTitle(): string {
        const { label } = this.nextRank;
        if (label !== '') {
            return label;
        }

        return '-';
    }

    get options(): SelectOptionsType[] {
        return this.participatedRuns
            .map((item, index) => ({
                name: index === 0 ? `${item.label} (Current)` : item.label,
                value: item.id,
            }));
    }

    get payRankLabel(): string {
        const { payRank } = this;

        return payRank ? ` (${payRank.label})` : '';
    }

    get rankLabel(): string {
        const { currentRank } = this;

        if (currentRank === null) {
            return '';
        }

        return currentRank.label;
    }

    get currentTitle(): string {
        return `${this.rankLabel}${this.payRankLabel}`;
    }

    handleSelect() {
        dashboardStats.getStats();
    }

    tabChanged(val: string) {
        dashboard.setActiveTab(val);
    }
}
