
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class OrderDetails extends Vue {
    @Prop() private currencyPrefix!: string;

    @Prop() private value!: string;

    @Prop() private name!: string;

    @Prop() private commissionableValue!: string;

    @Prop() private psc!: string;

    @Prop() private shippingCost!: string;

    @Prop({ default: false }) private commissionableOrder!: boolean;

    @Prop() private manual!: boolean;

    @Prop() private isReturn!: boolean;

    @Prop() private referenceId!: string|null;

    @Prop() private orderNumber!: string;

    @Prop() private comment!: string|null;

    @Prop() private qualificationValue!: string|null;

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }

    get isOrdersView() {
        return this.$route.name === 'orders';
    }
}
