
import { Vue, Component } from 'vue-property-decorator';
import profile from '@/modules/Profile';
import auth from '@/modules/Auth';
import impersonate from '@/modules/Impersonation';
import { Get } from '@/utils/vuex-module-mutators';
import env from '@/environment';
import system from '@/modules/System';
import shopUrl from '@/modules/ShopUrl';
import { SHOP_REDIRECT_ERROR_MESSAGE, SHOP_REDIRECT_MESSAGE } from '@/modules/ShopUrl/messages';
import componentsControls from '@/modules/ComponentsControls';
import { UploadProfilePictureType } from '@/api/graphQL/graphNodes/types';
import NavItem from './Item/index.vue';
import Section from './Section/index.vue';

@Component({
    components: {
        NavItem,
        Section,
    },
})
export default class Navigation extends Vue {
    @Get(profile, 'originalData.firstName') private firstName!: string;

    @Get(profile, 'originalData.lastName') private lastName!: string;

    @Get(profile) private profilePicture!: UploadProfilePictureType;

    @Get(impersonate) private token!: string|null;

    @Get(system) private screenType!: string;

    @Get(shopUrl, 'navUrl') private url!: string|null;

    // @Get(profile) private shopLoginUrl!: string;

    get logoContainerClasses() {
        return [
            'Navigation__Logo',
            { 'Navigation__Logo--withProfilePic': this.link !== '' },
        ];
    }

    get isDevelopmentEnvironment(): boolean {
        return env.VUE_APP_DEV === 'true';
    }

    get isDesktop() {
        return this.screenType === 'desktop';
    }

    get impersonating(): boolean {
        return !!this.token;
    }

    get isAmbassador(): boolean {
        return auth.isAmbassador;
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }

    get baseUrl() {
        return env.BASE_URL;
    }

    get iconBaseUrl() {
        return `${env.BASE_URL}img/icons/`;
    }

    get imgUrl(): string {
        return `${this.awsProfile ? '' : this.serverUrl}${this.link}`;
    }

    get serverUrl(): string {
        return env.VUE_APP_SERVER_URL;
    }

    get endImpersonatingText(): string {
        return `End viewing as ${this.firstName} ${this.lastName}`;
    }

    get awsProfile(): boolean {
        if (this.profilePicture !== null) {
            const provider = this.profilePicture.context.find((item) => item.metric === 'provider');
            if (provider && provider.value === 'aws_profile') {
                return true;
            }
        }
        return false;
    }

    get link(): string {
        return this.profilePicture ? this.profilePicture.link : '';
    }

    get hubTrainingSuiteLink(): string {
        return 'https://hub.myoffice.meamora.co.uk/';
    }

    get dashSocialAssetsLink(): string {
        return 'https://meamorauk.dash.app/home?portal=meamora-stylist-image-portal';
    }

    get path(): string {
        return env.VUE_APP_FULL_PATH;
    }

    get redirectPlaceholderUrl(): string {
        return `${this.path}${this.$router.resolve({ name: 'redirectPlaceholder' }).href}`;
    }

    unlockedAfterStage(stage: number): boolean {
        return Number(env.VUE_APP_STAGE) > stage;
    }

    async handleOrderNew() {
        try {
            const newPage = window.open(this.redirectPlaceholderUrl, '_blank');
            componentsControls.showSuccessMessage({ message: SHOP_REDIRECT_MESSAGE, time: 2000 });

            await shopUrl.getNavUrl();
            if (this.url !== null) {
                newPage!.location.href = this.url;
            }
        } catch {
            componentsControls.showNextMessage({ message: SHOP_REDIRECT_ERROR_MESSAGE, type: 'error' });
        }
    }

    mounted() {
        shopUrl.setNavigationUrl(null);
    }
}
