// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { OrderType } from './types';

export default class GetOrdersQuery extends
    AbstractQueryResource<GetOrdersResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($query: String!, $limit: Int!, $offset: Int!) { 
            profile {
                searchOrders(query: $query, offset: $offset, limit: $limit) {
                    id,
                    value,
                    grossValue,
                    shippingCost,
                    enrollee {
                        id,
                        firstName,
                        lastName,
                    },
                    customer {
                        id,
                        firstName,
                        lastName,
                    }
                    commissionableValue,
                    date,
                    psc,
                    referenceId,
                    manual,
                    comment,
                    products {
                        id,
                        price,
                        description,
                        quantity,
                        total,
                        discounts,
                        cv,
                        qv,
                    },
                    paymentInfo {
                        paymentMethod
                    },
                    type,
                    qualificationValue,
                },
                searchOrdersCount(query: $query),
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getOrders';
    }
}

export type GetOrdersResultType = {
    profile: {
        searchOrders: OrderType[];
        searchOrdersCount: number;
    };
}

export type GetOrdersParamsType = {
    offset: number;
    limit: number;
    query: string;
}
