
import { Vue, Component, Prop } from 'vue-property-decorator';
import dateManager from '@/utils/time';
import DatePicker from './components/DatePicker/index.vue';

@Component({
    components: {
        DatePicker,
    },
})
export default class DatePickerDateComponent extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ required: true }) private value!: string;

    @Prop({ required: false }) private startDate!: string;

    @Prop({ default: '' }) private popupClasses!: string[];

    @Prop({ required: true }) private formattedVal!: string;

    @Prop({ required: true }) private originalVal!: string;

    @Prop({ default: false }) private readonly!: boolean;

    @Prop({ default: false }) private active!: boolean;

    @Prop() private withValueClear!: boolean;

    @Prop() private clearValue!: any;

    @Prop() private minDate!: string;

    @Prop() private maxDate!: string;

    get wrapperClasses() {
        return [
            'DatePickerField__ControlWrapper',
            { 'DatePickerField__ControlWrapper--clearWrapper': this.withValueClear },
        ];
    }

    elementOnBlur(el: any) {
        this.$emit('onBlur', el);
    }

    elementOnFocus(el: any) {
        if (!this.disabled) {
            this.$emit('onFocus', el);
            this.$emit('onClick', el);
        }
    }

    formattedDateValue(): string {
        const dateNoTimeZone = dateManager.getDateTimeWithoutTimezone(this.formattedVal);

        if (dateNoTimeZone) {
            return dateManager.getDateTime(dateNoTimeZone, 'DD-MM-YYYY');
        }

        return '';
    }

    handleDateUpdate(val: any) {
        this.$emit('onDateUpdate', val);
    }

    handleTextDateUpdate(val: string) {
        this.$emit('onTextDateUpdate', val);
    }

    handleClose() {
        this.$emit('close');
    }

    handleOnClick(e: Node) {
        if (!this.disabled) {
            this.$emit('onClick', e);
        }
    }

    handleClearValue() {
        this.$emit('clear', this.clearValue);
    }
}
