
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { RankType, DownlineLegType } from '@/api/graphQL/graphNodes/types';
import rank from '@/modules/Rank';
import downlineExplorer from '@/modules/DownlineExplorer';
import businessShape from '@/modules/BusinessShape';
import { PaginationParamsType } from '@/components/pagination/types';
import { scrollToTop } from '@plumtreesystems/utils';
import DownlineItem from './item.vue';

@Component({
    components: {
        DownlineItem,
    },
})
export default class Downline extends Vue {
    @Get(downlineExplorer, 'data.directDownline') private downline!: DownlineLegType[];

    @Get(downlineExplorer, 'data.id') private id!: string;

    @Get(downlineExplorer, 'data.directDownlineCount') private directDownlineCount!: number;

    @Get(downlineExplorer) private searchedLegs!: DownlineLegType[];

    @Get(downlineExplorer) private loadingInBackground!: boolean;

    @Get(downlineExplorer) private limit!: number;

    @Get(downlineExplorer) private offset!: number;

    @Get(rank) private ranks!: RankType[];

    @Get(downlineExplorer) private activeLegDetailsDialog!: string;

    @Get(downlineExplorer) private expandedDownline!: string[];

    @Get(downlineExplorer) private displaySearchResults!: boolean;

    get displayLoadMore(): boolean {
        if (this.displaySearchResults) {
            return false;
        }
        return this.limit <= this.directDownlineCount;
    }

    get displayLegs(): DownlineLegType[] {
        if (this.displaySearchResults) {
            return this.searchedLegs;
        }
        return this.downline;
    }

    getDownlineItemExpanded(leg: DownlineLegType) {
        return !!this.expandedDownline.find((item) => item === leg.id);
    }

    handleDetailModal(active: string) {
        downlineExplorer.setActiveLegDetailsDialog(active);
    }

    handleExplore(legId:string) {
        downlineExplorer.getDownlineData({ id: legId });
        businessShape.getAmbassadorsCounts(legId);
    }

    handleExpandedContentToggle(val: string) {
        downlineExplorer.toggleExpandedList(val);
    }

    async handlePagination(props: PaginationParamsType) {
        const { offset } = props;
        await downlineExplorer.getDownlineData({ id: this.id, loadPage: true, offset });
        scrollToTop('#app > div.DownlineExplorer__Container.App__Layout > div > div.Scene__Content > div > div.HeaderWrapper__Content');
    }
}
