
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import rank from '@/modules/Rank';
import auth from '@/modules/Auth';
import env from '@/environment';
import dashboard from '@/modules/Dashboard';
import Skeleton from './DashboardSkeleton/index.vue';
import RankContainer from './RankContainer/index.vue';
import MonthPerformance from './MonthPerformance/index.vue';
import RecruitmentPerformance from './RecruitmentPerformance/index.vue';
// import Pathway from './Pathway/index.vue';
// import AwardContainer from './Rankings/AchievementAwards/index.vue';

@Component({
    components: {
        // AwardContainer,
        RankContainer,
        MonthPerformance,
        RecruitmentPerformance,
        Skeleton,
        // Pathway,
    },
})
export default class Dashboard extends Vue {
    @Get(rank, 'loading') private ranksLoading!: boolean;

    @Get(dashboard, 'loading') private dashboardLoading!: boolean;

    @Get(dashboard) private loadingTotals!: boolean;

    get viewLoaded(): boolean {
        return !this.ranksLoading && !this.dashboardLoading && !this.loadingTotals;
    }

    get unlockAfterPhase(): boolean {
        return Number(env.VUE_APP_STAGE) > 1;
    }

    async beforeMount() {
        rank.getRanks();
        dashboard.getDashboardData();
    }

    get isAmbassador() {
        return auth.isAmbassador;
    }
}
