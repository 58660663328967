import { COUNTRY_SELECT_OPTIONS } from './constants';
import { BankingDetailsResolverType, LabelType } from './types';

export const gbLabels: () => LabelType = () => ({
    bankSortCode: 'Account Sort Code',
    bankAccountNumber: 'Account Number',
});

export const ieLabels: () => LabelType = () => ({
    bankSortCode: 'Bank SWIFT Code',
    bankAccountNumber: 'Bank IBAN Number',
});

export const resolveBankingDetails: (val: string|null) => BankingDetailsResolverType = (val) => {
    switch (val) {
    case COUNTRY_SELECT_OPTIONS.gb:
    case COUNTRY_SELECT_OPTIONS.ci:
    case COUNTRY_SELECT_OPTIONS.gg:
    case COUNTRY_SELECT_OPTIONS.im:
    case COUNTRY_SELECT_OPTIONS.nir:
        return {
            labels: gbLabels(),
            displayStyle: 'UK',
        };
    case COUNTRY_SELECT_OPTIONS.fr:
    case COUNTRY_SELECT_OPTIONS.de:
    case COUNTRY_SELECT_OPTIONS.roi:
    case COUNTRY_SELECT_OPTIONS.it:
    case COUNTRY_SELECT_OPTIONS.esp:
    case COUNTRY_SELECT_OPTIONS.pl:
    case COUNTRY_SELECT_OPTIONS.ro:
    case COUNTRY_SELECT_OPTIONS.sk:
    case COUNTRY_SELECT_OPTIONS.ie:
    default:
        return {
            labels: ieLabels(),
            displayStyle: 'OTHER',
        };
    }
};
