
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import profile from '@/modules/Profile';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { BusinessDetailsType } from '@/api/graphQL/graphNodes/types';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';

@Component({
    components: {
        PropertyInfoDisplay,
    },
})
export default class ProfileBusinessDetails extends Vue {
    @Get(profile) private businessDetails!: BusinessDetailsType;

    @Get(profile) private formErrors!: ErrorType;

    @Get(profile) private disableBusinessDetails!: boolean;

    handleBusinessValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.businessDetails);
        profile.removeFormError(key);
        profile.setBusinessDetails(res);
    }
}
