
import { Vue, Component } from 'vue-property-decorator';
import newStarters from '@/modules/NewStarters';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import { NewStartersSearchType } from '@/api/graphQL/graphNodes/types';
import system from '@/modules/System';
import { Debounced } from '@/utils/debounced';

@Component({
    components: {},
})
export default class NewStartersHeader extends Vue {
    @Sync(newStarters) private searchQuery!: string;

    @Sync(newStarters) private newStartersTimeFrame!: string;

    @Sync(newStarters) private type!: string;

    @Get(newStarters) private searchLoading!: boolean;

    @Get(newStarters) private minSearchLength!: number;

    @Get(newStarters) private searchedLegsOptions!: NewStartersSearchType[];

    @Get(newStarters) private displaySearchResults!: boolean;

    @Get(newStarters) private displaySearch!: boolean;

    @Get(system) private screenType!: string;

    get searchOptions(): SelectOptionsType[] {
        return this.searchedLegsOptions.map((leg) => ({
            name: `${leg.firstName} ${leg.lastName}`,
            value: `${leg.firstName} ${leg.lastName}`,
        }));
    }

    get showSearch() {
        return this.screenType !== 'mobile' || this.displaySearch;
    }

    get headerContainerClasses() {
        return [
            'NewStartersHeader__HeaderContainer',
            { 'NewStartersHeader__HeaderContainer--hidden': !this.showSearch },
        ];
    }

    get searchContainerClasses() {
        return [
            'pts-layout-item',
            'pts-size-40',
            'pts-small-size-100',
            'NewStartersHeader__Container',
            { 'NewStartersHeader__Container--show': this.showSearch },
        ];
    }

    get typeOrganisational() {
        return newStarters.typeOrganisational;
    }

    get typePersonal() {
        return newStarters.typePersonal;
    }

    get twoMonths() {
        return newStarters.typeTwoMonths;
    }

    get threeMonths() {
        return newStarters.typeThreeMonths;
    }

    get fourMonths() {
        return newStarters.typeFourMonths;
    }

    handleSelect(val) {
        newStarters.setSearchQuery(val.value);
        newStarters.setDisplaySearchResults(true);
        newStarters.search({ selectedSearch: true });
    }

    @Debounced(1000)
    handleChange() {
        if (this.searchQuery === '') {
            newStarters.setDisplaySearchResults(false);
            newStarters.setSearchedOptionsLegs([]);
            newStarters.setSearchedLegs([]);
        }
        if (this.searchQuery.length >= this.minSearchLength) { newStarters.search(); }
    }

    handleType(val: string) {
        newStarters.setType(val);
        const { searchQuery, displaySearchResults } = this;

        if (displaySearchResults) {
            newStarters.search({ query: searchQuery });
        } else {
            this.handleGetNewStarters();
        }
    }

    handleTimeFrame(val: string) {
        newStarters.setNewStartersTimeFrame(val);
        const { searchQuery, displaySearchResults } = this;

        if (displaySearchResults) {
            newStarters.search({ query: searchQuery });
        } else {
            this.handleGetNewStarters();
        }
    }

    handleGetNewStarters() {
        newStarters.getData({ loadInBackground: false });
    }
}
