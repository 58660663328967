import { CommissionStatementsType } from '@/api/graphQL/graphNodes/types';

const fileFormatter = (file: CommissionStatementsType, fileFormat: string = '') => ({
    id: file.id,
    title: file.label,
    context: [{
        metric: 'Content-Type',
        value: 'application/pdf',
    }],
    originalFileName: `${file.label}${fileFormat}`,
    category: '',
    subCategory: null,
    priority: 0,
    link: file.downloadUrl,
    fileName: file.label,
    uploadDate: '',
});

export default fileFormatter;
