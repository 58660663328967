
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import incomeSummary from '@/modules/IncomeSummary';
import personalStatements from '@/modules/IncomeSummary/personalStatements';
import Skeleton from './IncomeSummarySkeleton/index.vue';
import Totals from './Totals/index.vue';
import CommissionStatements from './Statements/CommissionStatements/index.vue';
import PersonalStatements from './Statements/PersonalStatements/index.vue';

@Component({
    components: {
        Totals,
        CommissionStatements,
        PersonalStatements,
        Skeleton,
    },
})
export default class IncomeSummary extends Vue {
    @Get(incomeSummary) private loading!: boolean;

    @Get(personalStatements, 'loading') private loadingPersonalStatements!: boolean;

    @Get(incomeSummary) private statementsLoading!: boolean;

    @Get(incomeSummary) private selectedYear!: string;

    beforeMount() {
        personalStatements.setOffset(0);
        personalStatements.getStatements({ inBackground: false });
        incomeSummary.getStatements();
        incomeSummary.getTotals(this.selectedYear);
    }

    get contentLoaded(): boolean {
        return !this.loading && !this.statementsLoading && !this.loadingPersonalStatements;
    }
}
