
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboardStats from '@/modules/Dashboard/stats';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import { RankRequirementsType } from '@/api/graphQL/graphNodes/types';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class UV extends Vue {
    @Get(dashboardStats) private stats!: RankRequirementsType[];

    @Prop() private requirements!: RankRequirementType[];

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(this.metric).title;
    }

    get metric(): string {
        return RankRequirementManager.rankRequirementMetricConstants.uv;
    }

    get uv(): number {
        const stat = this.stats.find((item) => item.metric === this.metric);
        return !stat ? 0 : Number(stat.value);
    }

    get requirement(): string | null {
        const uv = this.requirements
            .find((item) => item.metric === this.metric);

        return uv ? uv.value : null;
    }

    get progressValue(): number|null {
        const x = this.uv * 100;

        if (this.requirements.length === 0) {
            return null;
        }

        if (!this.requirement) {
            return null;
        }

        if (Number(this.requirement) === 0) {
            return 0;
        }

        return x / Number(this.requirement);
    }

    get displayData(): boolean {
        const req = this.requirement;

        if (!req) {
            return false;
        }

        return true;
    }
}
