import { EventEditFormType, EventType, EventViewType } from '@/api/graphQL/graphNodes/types';
import { defaultEnrollee } from '@/modules/Calendar/defaults';
import { defaultShippingAddress } from '../defaults';
import { CreateEventType } from './types';
import { EventFormType } from '../types';
import { EVENT_TYPE } from '../constants';

const initialEventData: () => EventType = () => ({
    id: '',
    title: '',
    eventDateFrom: '',
    eventDateTo: '',
    campaignDateFrom: '',
    campaignDateTo: '',
    address: '',
    videoUrl: '',
    description: '',
    allowGuestsToInvite: false,
    ambassador: defaultEnrollee(),
    hostess: defaultEnrollee(),
    invitations: [],
    inviteLink: '',
    eventOrders: [],
    confirmed: false,
    canceled: false,
    closed: false,
    type: EVENT_TYPE.party,
    totalSales: 0,
    shippingAddress: defaultShippingAddress(),
    eventShopUrl: '',
});

const initialEventFormData: () => EventFormType = () => ({
    ...initialEventData(),
    hostess: {
        address: '',
        email: '',
        firstName: '',
        id: '',
        lastName: '',
        phoneNumber: '',
    },
    addressLookup: null,
});

const initialEventEditFormData: () => EventEditFormType = () => ({
    id: '',
    address: '',
    campaignDateTo: '',
    eventDateFrom: '',
    eventDateTo: '',
    canceled: false,
    closed: false,
    confirmed: false,
    description: '',
    hostess: defaultEnrollee(),
    title: '',
    type: '',
    videoUrl: '',
    allowGuestsToInvite: false,

});

const initialEventViewData: () => EventViewType = () => ({
    ...initialEventData(),
});

const initialEventCreateData: () => CreateEventType = () => ({
    title: '',
    eventDateFrom: '',
    eventDateTo: '',
    campaignDateTo: '',
    type: EVENT_TYPE.party,
    address: '',
    videoUrl: '',
    description: {
        ops: '',
    },
    allowGuestsToInvite: false,
    hostess: null,
    shippingAddress: defaultShippingAddress(),
});

export {
    initialEventCreateData, initialEventViewData, initialEventData, initialEventFormData,
    initialEventEditFormData,
};
