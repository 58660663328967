import API from '@/api/graphQL';
import { GetShopUrlParamsType } from '@/api/graphQL/graphNodes/GetShopUrlQuery';

export default class ShopRepository {
    static getShopUrl(params: GetShopUrlParamsType) {
        return API
            .getShopUrl()
            .query(params);
    }
}
