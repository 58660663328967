
import { Vue, Component, Prop } from 'vue-property-decorator';
import { AssetType } from '@/api/graphQL/graphNodes/types';
import FileViewer from '@/projectComponents/fileViewer/index.vue';
import authenticator from '@/modules/Auth/services/authenticator';

@Component({
    components: {
        FileViewer,
    },
})
export default class IncomeSummaryAssetsPreview extends Vue {
    @Prop() private previewFileId!: string;

    @Prop() private fileList!: AssetType[];

    get token(): string {
        return authenticator.authTokenFromStorage || '';
    }

    get file(): AssetType|null {
        const res = this.fileList.find((file) => file.id === this.previewFileId);

        return res || null;
    }
}
