
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import dateManager from '@/utils/time';
import Calendar from './Calendar.vue';
import utils from '../../lib/date';

@Component({
    components: {
        Calendar,
    },
})
export default class DatePicker extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop() private startDate!: string|null;

    @Prop() private endDate!: string;

    @Prop({ default: false }) private singleDate!: boolean;

    @Prop({ default: false }) private disableArrow!: boolean;

    @Prop() private minDate!: string;

    @Prop() private maxDate!: string;

    private innerStartDate: string|null = null;

    private innerEndDate: string = '';

    private selectedDay: null = null;

    private currentYear: string = '0000';

    private currentMonth: string = '00';

    private monthNow: string = '00';

    get currentMonthString() {
        return utils.monthConfig[Number(this.currentMonth) - 1];
    }

    get resolveStartDate(): string {
        return this.startDate ? dateManager.getDateTime(this.startDate, dateManager.getDateFormat())
            : dateManager.getCurrentDate();
    }

    get iconRightDisabled() {
        const currentDate = dateManager.getCurrentDate('YYYY-MM');
        // const selectedMonth = this.currentMonth.toLocaleString('en-US',
        //  { minimumIntegerDigits: 2 });
        const selectedDay = `${this.currentYear}-${this.currentMonth}`;

        return selectedDay >= currentDate;
    }

    get iconLeftDisabled() {
        // const selectedMonth = this.currentMonth.toLocaleString('en-US',
        //  { minimumIntegerDigits: 2 });
        const selectedDay = `${this.currentYear}-${this.currentMonth}`;

        return selectedDay <= '1920-01';
    }

    // @Watch('currentMonth')
    callOnChange() {
        const {
            currentMonth: month,
            currentYear: year,
            innerStartDate: startDate,
            innerEndDate: endDate,
            selectedDay,
        } = this;

        const returnData = {
            month, year, startDate, endDate, selectedDay,
        };
        this.$emit('onChange', returnData);
    }

    @Watch('startDate')
    setInitialValues() {
        const startDate = this.resolveStartDate;
        if (this.startDate === null) {
            this.innerStartDate = null;
        } else {
            this.innerStartDate = startDate;
        }

        this.currentMonth = dateManager.getDateTime(startDate, 'MM');
        this.currentYear = dateManager.getDateTime(startDate, 'YYYY');
        this.monthNow = dateManager.getCurrentDate('MM');
        this.innerEndDate = this.endDate || dateManager.getDateWithOffset(2, startDate);
    }

    beforeMount() {
        this.setInitialValues();
    }

    addMonth() {
        if (Number(this.currentMonth) === 12) {
            this.currentMonth = '01';
            this.currentYear = String(Number(this.currentYear) + 1);
            return;
        }

        this.currentMonth = String(Number(this.currentMonth) + 1);
    }

    minusMonth(): void {
        if (Number(this.currentMonth) === 1) {
            this.currentMonth = '12';
            this.currentYear = String(Number(this.currentYear) - 1);
            return;
        }

        this.currentMonth = String(Number(this.currentMonth) - 1);
    }

    handleOnChange(data) {
        const { startDate, endDate, selectedDay } = data;
        this.innerStartDate = startDate;

        this.innerEndDate = endDate;
        this.selectedDay = selectedDay;

        return this.callOnChange();
    }

    handleClearSelected() {
        this.selectedDay = null;
    }
}
