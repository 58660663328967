

import { Get } from '@/utils/vuex-module-mutators';
import { Component, Vue, Prop } from 'vue-property-decorator';
import myCustomers from '@/modules/MyCustomers';
import { CUSTOMER_ADDRESS_BOOK, CUSTOMER_ORDERS } from '@/modules/MyCustomers/messages';

@Component
export default class DirectContainer extends Vue {
    @Get(myCustomers) private direct!: boolean;

    @Prop() private isLoading!: boolean;

    get customerAddressBookLabel() {
        return CUSTOMER_ADDRESS_BOOK;
    }

    get customerOrdersLabel() {
        return CUSTOMER_ORDERS;
    }

    handleClick(value: boolean) {
        myCustomers.setDirect(value);
        this.$emit('select');
    }
}
