// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { EventEditFormType } from './types';

export default class GetEventEditQuery extends AbstractQueryResource<GetEventEditResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($id: String!) {
            event(id: $id) {
                id,
                title,
                description,
                campaignDateFrom,
                campaignDateTo,
                eventDateFrom,
                eventDateTo,
                confirmed,
                address,
                videoUrl
                canceled,
                closed,
                type,
                hostess {
                    id,
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                    address
                },
                allowGuestsToInvite,
                shippingAddress {
                    title,
                    firstName,
                    lastName,
                    phoneNumber,
                    postcode,
                    address,
                    secondAddress,
                    city,
                    county,
                    country
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getEventEdit';
    }
}

export type GetEventEditResultType = {
    event: EventEditFormType;
}

export type GetEventEditParamsType = {
    id: string;
}
