
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import eventCreate from '@/modules/Event/EventCreate';
import { CreateEventHostessType, CreateEventType } from '@/modules/Event/Events/types';
import { SelectOptionsType } from '@/components/select/types';
import { EVENT_TYPE, EVENT_HOST_TYPE } from '@/modules/Event/constants';
import eventTypeLabelResolver from '@/modules/Event/services/eventTypeLabelResolver';
import { DATE_PICKER_TYPES } from '@/components/datePicker/constants';
import env from '@/environment';
import system from '@/modules/System';
import dateManager from '@/utils/time';
import EventDetails from './EventDetails/index.vue';
import ShippingDetails from './ShippingDetails/index.vue';

@Component({
    components: {
        EventDetails,
        ShippingDetails,
    },
})
export default class EventCreateForm extends Vue {
    @Get(eventCreate) eventHostType!: number;

    @Get(eventCreate) hostess!: CreateEventHostessType;

    @Get(system) screenWidth!: number;

    @Prop() value!: CreateEventType;

    @Prop() formErrors!: any;

    @Prop({ default: 'Add a Pop-Up description' }) descriptionPlaceholder!: any;

    @Prop({ default: false }) eventEditView!: boolean;

    @Prop({ default: false }) disabledAll!: boolean;

    @Prop({ default: false }) disabledCampaignFields!: boolean;

    get isMocked() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true';
    }

    get dateTypes() {
        return DATE_PICKER_TYPES;
    }

    get labelTo() {
        return this.screenWidth < 960 ? 'to' : '';
    }

    get options(): SelectOptionsType[] {
        return [
            {
                name: eventTypeLabelResolver(EVENT_TYPE.showcase),
                value: EVENT_TYPE.showcase,
            },
            {
                name: eventTypeLabelResolver(EVENT_TYPE.getTogether),
                value: EVENT_TYPE.getTogether,
            },
            {
                name: eventTypeLabelResolver(EVENT_TYPE.campaignLaunch),
                value: EVENT_TYPE.campaignLaunch,
            },
            {
                name: eventTypeLabelResolver(EVENT_TYPE.generalMeeting),
                value: EVENT_TYPE.generalMeeting,
            },
            {
                name: eventTypeLabelResolver(EVENT_TYPE.school),
                value: EVENT_TYPE.school,
            },
        ];
    }

    get showEventHostName(): boolean {
        return this.hostess
            && (this.eventHostType === EVENT_HOST_TYPE.me
                || this.eventHostType === EVENT_HOST_TYPE.known);
    }

    get eventHostName(): string {
        if (this.hostess) {
            const { firstName, lastName } = this.hostess;
            return `${firstName} ${lastName}`;
        }

        return '';
    }

    get minEventFromDate(): string {
        return dateManager.getCurrentDateTime('YYYY-MM-DDTHH:mmZZ');
    }

    get maxEventDate(): string {
        return dateManager.getDateWithOffset(3, dateManager.getCurrentDateTime(), 'YYYY-MM-DDTHH:mmZZ', 'month');
    }

    get displayCampaignEndDate(): string {
        return dateManager.getDateTime(this.value.campaignDateTo, 'DD-MM-YYYY HH:mm');
    }

    handleValue(value: string, name: string) {
        const res = { key: name, val: value };

        if (name === 'eventDateFrom') {
            this.$emit('input', { key: 'eventDateTo', val: dateManager.getDateWithOffset(1, value, 'YYYY-MM-DDTHH:mmZZ', 'hour') });
            this.$emit('input', { key: 'campaignDateTo', val: dateManager.getDateWithOffset(1, value, 'YYYY-MM-DDTHH:mmZZ', 'week') });
        }

        this.$emit('input', res);
    }
}
