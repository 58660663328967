
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import system from '@/modules/System';
import { MyCustomersType } from '@/api/graphQL/graphNodes/types';
import Contacts from '@/projectComponents/enrolleeLegItem/Item/Contacts/index.vue';
import DetailsDialog from '@/projectComponents/enrolleeLegItem/DetailsDialogContent/index.vue';

@Component({
    components: {
        Contacts,
        DetailsDialog,
    },
})
export default class CustomersListItem extends Vue {
    @Get(system) screenType!: string;

    @Prop() id!: string;

    @Prop() myId!: string;

    @Prop({ required: true }) customer!: MyCustomersType;

    @Prop() expandedList!: string[];

    @Prop({ default: true }) withSponsorDetails!: boolean;

    @Prop({ default: '' }) activeSponsorDetailsModal!: string;

    get expanded(): boolean {
        const { id } = this.customer;
        return this.expandedList.findIndex((item) => item === id) !== -1;
    }

    get sponsorDetailsOpen(): boolean {
        return this.activeSponsorDetailsModal === this.customer.id;
    }

    get displayOrdersButton(): boolean {
        return this.customer.searchOrdersCount > 0;
    }

    get displayEditButton(): boolean {
        const { customer, myId } = this;
        return customer.editable && myId !== customer.id;
    }

    handleOpenSponsor(id: string) {
        this.$emit('activeSponsorDetailsId', id);
    }

    handleExpandedToggle() {
        this.$emit('expandedToggle', this.customer.id);
    }

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }

    handleOrders(id: string) {
        this.$router.push({ name: 'myCustomerOrders', params: { id } });
    }

    handleEditCustomer(id: string) {
        this.$router.push({ name: 'editMyCustomer', params: { id } });
    }
}
