import { BusinessDetailsType, ProfileUpdateType } from '@/api/graphQL/graphNodes/types';
import { FIELD_REQUIRED_MESSAGE, INVALID_EMAIL_ADDRESS } from '@/utils/messages/formValidation';
import { validateEmail } from '@/utils/email-validation';
import { ValidationErrorType } from '../../types';
import { PROFILE_BUSINESS_DETAILS_ERROR } from '../formValidation';

const profileFormValidation:
    (val: ProfileUpdateType, businessDetails: BusinessDetailsType) =>
    ValidationErrorType[] = (val, businessDetails) => {
        const errors: ValidationErrorType[] = [];

        if (!val.email) {
            errors.push({ key: 'email', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!validateEmail(val.email)) {
            errors.push({ key: 'email', val: INVALID_EMAIL_ADDRESS });
        }

        if (!val.firstName) {
            errors.push({ key: 'firstName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.lastName) {
            errors.push({ key: 'lastName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.phoneNumber) {
            errors.push({ key: 'phoneNumber', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.address) {
            errors.push({ key: 'address', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.postCode) {
            errors.push({ key: 'postCode', val: FIELD_REQUIRED_MESSAGE });
        }

        if (businessDetails && ((!!businessDetails.businessAddress && businessDetails.businessName === '') || (
            !businessDetails.businessAddress && businessDetails.businessName !== ''
        ))) {
            errors.push({ key: 'businessDetails.businessAddress', val: PROFILE_BUSINESS_DETAILS_ERROR });
            errors.push({ key: 'businessDetails.businessName', val: PROFILE_BUSINESS_DETAILS_ERROR });
        }

        return errors;
    };

export default profileFormValidation;
