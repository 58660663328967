
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DownlineParentType, RankType } from '@/api/graphQL/graphNodes/types';
import impersonation from '@/modules/Impersonation';
import nextRankResolver from '@/modules/Rank/services/nextRankResolver';
import dateManager from '@/utils/time';
import { LegType } from '@/modules/types';
import requirementRisingStars from '@/modules/RisingStars/requirementRisingStars';
import requirementLeaderLegs from '@/modules/LeaderLegs/requirementLeaderLegs';
import CurrencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import RankRequirementManager from '@/utils/rankRequirementManager';
import Contacts from './Contacts/index.vue';
import RankRequirements from './RankRequirements/index.vue';
import Status from './Status/index.vue';
import RisingStarsStatus from './Status/risingStarsStatus.vue';
import DetailsDialog from '../DetailsDialogContent/index.vue';
import { RankRequirementData } from './RankRequirements/types';
import CheckedStatusIcon from './CheckedStatusIcon/index.vue';

@Component({
    components: {
        Contacts,
        RankRequirements,
        Status,
        DetailsDialog,
        CheckedStatusIcon,
        RisingStarsStatus,
    },
})
export default class LegItem extends Vue {
    @Prop({ default: '' }) private id!: string;

    @Prop() private leg!: LegType;

    @Prop() private parent!: DownlineParentType;

    @Prop() private ranks!: RankType[];

    @Prop({ default: false }) private loading!: boolean;

    @Prop({ default: '' }) private componentViewName!: string;

    @Prop({ default: false }) private withoutDefaultRankRequirements!: boolean;

    @Prop({ default: false }) private customHeaderSecondary!: boolean;

    @Prop({ default: false }) private withExplore!: boolean;

    @Prop() private expanded!: boolean;

    @Prop({ required: false }) private status!: string;

    @Prop({ required: false }) private statusProgress!: number;

    @Prop({ default: false }) private withImpersonate!: boolean;

    @Prop({ default: true }) private withStatus!: boolean;

    @Prop({ default: true }) private withSponsorDetails!: boolean;

    @Prop({ default: true }) private withRankRequirementsDetails!: boolean

    @Prop({ default: '' }) private activeSponsorDetailsModal!: string;

    get kitPurchaseDate(): string {
        if (this.leg.kitPurchaseDate) {
            return dateManager.getDateTime(this.leg.kitPurchaseDate, dateManager.getDateFormatUK());
        }

        return '-';
    }

    get showCurrentRankProgress(): boolean {
        const metRequirements: boolean = !!this.currentRequirements
            .find((req) => Number(req.current) >= Number(req.required));

        return this.isMyLeaders && !metRequirements;
    }

    get daysFromJoin(): number {
        const current = `${dateManager.getCurrentDate(dateManager.getDateFormat())} 00:00`;
        // @ts-ignore
        const { joinDate } = this.leg;

        const days = dateManager.getDifference(
            current,
            `${dateManager.getDateTime(joinDate, dateManager.getDateFormat())} 00:00`,
            'day',
        );

        return 28 + days;
    }

    get isRisingStars(): boolean {
        return this.componentViewName === 'myTeam';
    }

    // leader legs
    get isMyLeaders(): boolean {
        return this.componentViewName === 'myLeaders';
    }

    get isDownlineExplorer(): boolean {
        return this.componentViewName === 'myDownline';
    }

    get isNewStarters(): boolean {
        return this.componentViewName === 'newStarters';
    }

    get calculatedNextRank(): null|RankType {
        return nextRankResolver(this.leg.rank.power);
    }

    get qualifiedForRisingStars(): boolean {
        return Number(this.leg.totals.pv) >= 120;
    }

    get currentRequirements(): RankRequirementData[] {
        const { rank } = this.leg;
        return rank ? this.formatRequirements(rank.id) : [];
    }

    get requirements(): RankRequirementData[] {
        const { calculatedNextRank } = this;
        return calculatedNextRank ? this.formatRequirements(calculatedNextRank.id) : [];
    }

    get remainingLabel(): string {
        const { daysFromJoin } = this;
        if (daysFromJoin >= 0) {
            return `${daysFromJoin} ${daysFromJoin !== 1 ? 'Days' : 'Day'} remaining`;
        }

        const daysAfterPeriodEnd = daysFromJoin * -1;
        return `${daysAfterPeriodEnd + 28} Days since joining`;
    }

    get sponsorDetailsOpen(): boolean {
        return this.activeSponsorDetailsModal === this.leg.id;
    }

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    get psTitle() {
        return 'FLV';
    }

    get ov(): string {
        const ov = this.leg.stats
            .find((item) => item.metric === RankRequirementManager
                .rankRequirementMetricConstants.ov)!;
        return ov ? ov.value : '0';
    }

    get ovLabel(): string {
        return RankRequirementManager.getRankRequirementLabel(RankRequirementManager
            .rankRequirementMetricConstants.ov).title;
    }

    get pv(): string {
        const pv = this.leg.stats
            .find((item) => item.metric === RankRequirementManager
                .rankRequirementMetricConstants.pv);
        return pv ? pv.value : '0';
    }

    get pvValue() {
        return this.pv;
    }

    formatRequirements(rankId: string): RankRequirementData[] {
        const rank = this.ranks.find(((rankItem) => rankItem.id === rankId))!;
        if (rank === null || rank.titleRequirements === null) {
            return [];
        }

        const res: RankRequirementData[] = rank.titleRequirements.map((requirement) => {
            const stat = this.leg.stats.find((item) => item.metric === requirement.metric);

            return {
                metric: requirement.metric,
                required: requirement.value,
                current: stat ? stat.value : '0',
            };
        });

        return res;
    }

    handleExplore(legId:string) {
        this.$emit('explore', legId);
    }

    handleExpandedToggle() {
        this.$emit('expandedToggle', this.leg.id);
    }

    async handleImpersonate() {
        impersonation.handleImpersonating(this.leg.id);
    }

    handleOpenSponsor(id: string) {
        this.$emit('activeSponsorDetailsId', id);
    }

    created() {
        if (this.withRankRequirementsDetails) {
            requirementRisingStars.clearRisingStarsLegsDialogData();
            requirementLeaderLegs.clearLeaderLegsLegsDialogData();
        }
    }
}
