
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import rank from '@/modules/Rank';
import legsRenderingList from '@/modules/LeaderLegs/legsRenderingList';
import leaderLegs from '@/modules/LeaderLegs';
import Skeleton from '@/projectComponents/skeleton/List/index.vue';
import { LeaderLegsDisplayItemType } from '@/modules/LeaderLegs/types';
import impersonation from '@/modules/Impersonation';
import system from '@/modules/System';
import LegsList from './LegsList/index.vue';
import Header from './Header/index.vue';

@Component({
    components: {
        LegsList,
        Skeleton,
        Header,
    },
})
export default class LeaderLegs extends Vue {
    @Get(rank, 'loaded') private ranksLoaded!: boolean ;

    @Get(rank, 'loading') private ranksLoading!: boolean ;

    @Get(leaderLegs, 'loaded') private leaderLegsLoaded!: boolean;

    @Get(leaderLegs, 'loading') private leaderLegsLoading!: boolean;

    @Get(leaderLegs) private searchLoading!: boolean;

    @Get(leaderLegs) private displaySearchResults!: boolean;

    @Get(impersonation) private token!: string|null;

    @Get(legsRenderingList) private legs!: LeaderLegsDisplayItemType[];

    @Get(system) private screenType!: string;

    get elementClasses() {
        return [
            'pts-layout',
            { 'pts-gutter': this.legs.length === 0 },
        ];
    }

    get loading(): boolean {
        return !this.ranksLoading && !this.leaderLegsLoading && !this.searchLoading;
    }

    get isMobile() {
        return this.screenType === 'mobile';
    }

    @Watch('token')
    loadLeaderLegs() {
        leaderLegs.getLeaderLegs({});
        leaderLegs.clearExpandedList();
    }

    @Watch('displaySearchResults')
    loadData() {
        if (!this.leaderLegsLoaded && !this.displaySearchResults) {
            this.loadLeaderLegs();
        }
    }

    handleSearchToggle() {
        leaderLegs.toggleShowSearch();
    }

    beforeMount() {
        rank.getRanks();

        // TODO: remove after fixing impersonate
        leaderLegs.setOffset(0);
        leaderLegs.setDisplaySearchResults(false);
        // if (!this.displaySearchResults) {
        this.loadLeaderLegs();
        // }
    }

    beforeDestroy() {
        legsRenderingList.setSearchedLegs([]);
        leaderLegs.setSearchedOptionsLegs([]);
        legsRenderingList.setData([]);
        leaderLegs.clearExpandedList();
        leaderLegs.setLoaded(false);
        leaderLegs.setDisplaySearchResults(false);
        leaderLegs.setSearchQuery('');
    }
}
