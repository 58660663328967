
import { Component, Vue } from 'vue-property-decorator';
import componentsControls from '@/modules/ComponentsControls';
import auth from '@/modules/Auth';

@Component({})
export default class NoPermissionSuspended extends Vue {
    mounted() {
        componentsControls.showErrorMessage({
            message: 'Your account is temporary suspended. Contact support for more information.',
        });
        auth.loadMe();
        this.$router.push({ name: 'dashboard' });
    }
}

