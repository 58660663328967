import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { AssetType, CommissionStatementsType, IncomeSummaryType } from '@/api/graphQL/graphNodes/types';
import { GetIncomeSummaryParamsType, GetIncomeSummaryResultType } from '@/api/graphQL/graphNodes/GetIncomeSummaryQuery';
import { GetCommissionStatementsResultType } from '@/api/graphQL/graphNodes/GetCommissionStatementsQuery';
import IncomeSummaryRepository from './services/incomeSummaryRepository';
import { defaultData, defaultCurrentYear } from './defaults';
import fileFormatter from './services/fileFormatter';

@Module({
    namespaced: true, dynamic: true, store, name: 'incomeSummary',
})
@AutoMutations
export class IncomeSummary extends VuexModule {
    private data: IncomeSummaryType = defaultData();

    private selectedYear: string = defaultCurrentYear();

    private loading: boolean = false;

    private files: CommissionStatementsType[] = [];

    private loadingInBackground: boolean = false;

    private statementsLoading: boolean = false;

    private previewOpen: boolean = false;

    private previewFileId: string = '';

    get fileList(): AssetType[] {
        return this.files.map((file) => fileFormatter(file, '.pdf'));
    }

    @Mutation
    public setData(val: IncomeSummaryType) {
        this.data = { ...val };
    }

    @Mutation
    public setSelectedYear(val: string) {
        this.selectedYear = val;
    }

    @Mutation
    public setFiles(val: CommissionStatementsType[]) {
        this.files = [...val];
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setStatementsLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setPreviewOpen(val: boolean) {
        this.previewOpen = val;
    }

    @Mutation
    public setPreviewFileId(val: string) {
        this.previewFileId = val;
    }

    @Action()
    public async getTotals(year: string) {
        try {
            this.setLoading(true);

            const params: GetIncomeSummaryParamsType = {
                dateFrom: `${year}-01-01T00:00:00`,
                dateTo: `${year}-12-31T23:59:59`,
            };

            const res: GetIncomeSummaryResultType = await IncomeSummaryRepository
                .getAnnualTotals(params);
            this.setData(res.profile.incomeSummary);
            this.setSelectedYear(year);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async getStatements() {
        try {
            this.setStatementsLoading(true);

            const res: GetCommissionStatementsResultType = await IncomeSummaryRepository
                .getCommissionStatements();

            const { commissionStatements } = res.profile;

            if (commissionStatements) {
                this.setFiles(commissionStatements);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setStatementsLoading(false);
        }
    }
}

export default getModule(IncomeSummary);
