<template>
    <div class="RegisterCanNotRegister">
        <div
            class="Ui__Card
            pts-layout pts-layout-item
            pts-gutter RegisterCanNotRegister__Container"
        >
            <span>You cannot access this page</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RegisterCanNotRegister',
};
</script>

<style lang="scss">
    @import 'styles.scss';
</style>
