
import {
    Component, Vue,
} from 'vue-property-decorator';
import routes, { RouteType } from '@/router/routes';
import { defaultRoute } from '@/router/defaults';

@Component
export default class Header extends Vue {
    get currentRoute(): RouteType {
        return this.findCurrentRouteForTitleRecursively(routes(), this.$route.name!)
         || defaultRoute();
    }

    findCurrentRouteForTitleRecursively(routesList: RouteType[], current: string): RouteType|null {
        let res: RouteType|null = null;

        routesList.forEach((route) => {
            if (route.name === current) {
                res = route;
            }

            if (route.children && route.children.length > 0) {
                const childRes = this.findCurrentRouteForTitleRecursively(route.children, current);

                if (childRes !== null) {
                    res = childRes;
                }
            }
        });

        return res;
    }
}
