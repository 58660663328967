import { TEXT_FIELD_DISPLAY_TYPE } from '@/components/textField/constants';
import { MaskObjectType } from './types';

const defaultMaskObject: () => MaskObjectType = () => ({
    mask: null,
    maskToken: null,
});

const maskResolver: (type: string|null) => MaskObjectType = (type) => {
    let res: MaskObjectType = defaultMaskObject();

    switch (type) {
    case TEXT_FIELD_DISPLAY_TYPE.swift:
        res = {
            mask: '@@@@@@*****',
            maskToken: '',
        };
        break;
    case TEXT_FIELD_DISPLAY_TYPE.bankAccountNumber:
        res = {
            mask: '#0000000',
            maskToken: '0:[0-9]:optional',
        };
        break;
    case TEXT_FIELD_DISPLAY_TYPE.iban:
        res = {
            mask: '****************************',
            maskToken: null,
        };
        break;
    case TEXT_FIELD_DISPLAY_TYPE.sortCode:
        res = {
            mask: '#0-#0-#0',
            maskToken: '0:[0-9]:optional',
        };
        break;
    case TEXT_FIELD_DISPLAY_TYPE.birthDate:
        res = {
            mask: '#0-#0-#000',
            maskToken: '0:[0-9]:optional',
        };
        break;

    default:
        break;
    }

    return res;
};

export default maskResolver;
