import {
    BaseOrderType, DownlineProfileType, EnrolleeCustomerOrderType, OrderType,
} from '@/api/graphQL/graphNodes/types';
import { defaultEnrollee } from '../Calendar/defaults';
import { COUNTRY_SELECT_OPTIONS, REGION_OPTIONS } from '../constants';

const initialCustomerOrdersData: () => BaseOrderType = () => ({
    id: '0',
    customer: defaultEnrollee(),
    enrollee: defaultEnrollee(),
    value: 0,
    grossValue: 0,
    discount: 0,
    products: [],
    commissionableValue: 0,
    date: '',
    psc: 0,
    shippingCost: '0',
    paymentInfo: null,
    qualificationValue: 0,
});

const initialEnrolleeOrderData: () => EnrolleeCustomerOrderType = () => ({
    id: '',
    firstName: '',
    lastName: '',
    searchOrders: [],
    searchOrdersCount: 0,
});

const profile: () => DownlineProfileType = () => ({
    id: '',
    parentId: '',
    address: '',
    email: '',
    phoneNumber: '',
    rank: {
        id: '',
        label: '',
    },
    firstName: '',
    lastName: '',
    secondAddress: '',
    birthDate: '',
    postCode: '',
    town: '',
    county: '',
    region: REGION_OPTIONS.gb,
    country: COUNTRY_SELECT_OPTIONS.gb,
    hidden: false,
    profilePicture: {
        link: '',
        context: [],
    },
});

const initialOrder: () => OrderType = () => ({
    id: '',
    comment: '',
    commissionableValue: 0,
    customer: {
        firstName: '',
        id: '',
        lastName: '',
    },
    date: '',
    discount: 0,
    enrollee: {
        firstName: '',
        id: '',
        lastName: '',
    },
    grossValue: 0,
    manual: false,
    paymentInfo: [],
    products: [],
    psc: 0,
    referenceId: '',
    shippingCost: '',
    value: 0,
    isReturn: false,
    type: null,
    qualificationValue: 0,
});

export {
    initialCustomerOrdersData, initialEnrolleeOrderData, profile, initialOrder,
};
