
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import system from '@/modules/System';
import QrcodeVue from 'qrcode.vue';
import env from '@/environment';

@Component({
    components: {
        QrcodeVue,
    },
})
export default class SocialButtons extends Vue {
    @Get(system) private screenType!: string;

    @Prop() private title!: string;

    @Prop() private showQrCode!: boolean;

    @Prop({ default: 'Invite guests via QR code' }) private qrLabel!: string;

    @Prop() private description!: string;

    @Prop({ default: '[{ "insert": "" }]' }) private quillDescription!: string;

    @Prop() private eventInvitationLink!: string;

    @Prop({ default: false }) private customUrl!: boolean;

    @Prop({ default: '' }) private customModifier!: string;

    handleInvite() {
        this.$emit('handleInvite');
    }

    get descriptionHtml() {
        const converter = new QuillDeltaToHtmlConverter(JSON.parse(this.quillDescription));
        return converter.convert();
    }

    get descriptionDisplay(): string {
        return this.description || this.descriptionHtml;
    }

    shareViaWebShare() {
        const nav: any = navigator;
        nav.share({
            title: this.title,
            text: this.descriptionDisplay,
            url: this.invitiationUrl,
        });
    }

    get webShareApiSupported(): boolean {
        const nav: any = navigator;

        if (nav.share && this.screenType !== 'desktop') {
            return true;
        }

        return false;
    }

    get invitiationUrl() {
        if (this.customUrl) {
            return this.eventInvitationLink;
        }

        return `${env.VUE_APP_FULL_PATH}${this.eventInvitationLink}`;
    }

    get pictureBaseUrl() {
        return `${env.BASE_URL}img/icons/social/`;
    }

    get path() {
        return env.VUE_APP_FULL_PATH;
    }
}
