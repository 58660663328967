import API from '@/api/graphQL';
import { GetIncomeSummaryParamsType } from '@/api/graphQL/graphNodes/GetIncomeSummaryQuery';
import { GetPersonalStatementsParamsType } from '@/api/graphQL/graphNodes/GetPersonalStatementsQuery';

export default class IncomeSummaryRepository {
    static getCommissionStatements() {
        return API
            .getCommissionStatements()
            .query();
    }

    static getAnnualTotals(params: GetIncomeSummaryParamsType) {
        return API
            .getIncomeSummary()
            .query(params);
    }

    static getPersonalStatements(params: GetPersonalStatementsParamsType) {
        return API
            .getPersonalStatements()
            .query(params);
    }
}
