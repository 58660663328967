import { CountriesType } from '@/modules/Event/types';

const countries: () => CountriesType[] = () => ([
    {
        shortLabel: 'CI',
        longLabel: 'Channel Islands',
    },
    {
        shortLabel: 'FR',
        longLabel: 'France',
    },
    {
        shortLabel: 'DE',
        longLabel: 'Germany',
    },
    {
        shortLabel: 'IM',
        longLabel: 'Isle of Man',
    },
    {
        shortLabel: 'NIR',
        longLabel: 'Northern Ireland',
    },
    {
        shortLabel: 'ROI',
        longLabel: 'Republic of Ireland',
    },
    {
        shortLabel: 'IT',
        longLabel: 'Mainland Italy',
    },
    {
        shortLabel: 'ESP',
        longLabel: 'Mainland Spain',
    },
    {
        shortLabel: 'PL',
        longLabel: 'Poland',
    },
    {
        shortLabel: 'RO',
        longLabel: 'Romania',
    },
    {
        shortLabel: 'SK',
        longLabel: 'Slovakia',
    },
    {
        shortLabel: 'GB',
        longLabel: 'United Kingdom',
    },
]);

const regions: () => CountriesType[] = () => ([
    {
        shortLabel: 'CI',
        longLabel: 'Channel Islands',
    },
    {
        shortLabel: 'FR',
        longLabel: 'France',
    },
    {
        shortLabel: 'DE',
        longLabel: 'Germany',
    },
    {
        shortLabel: 'IM',
        longLabel: 'Isle of Man',
    },
    {
        shortLabel: 'NIR',
        longLabel: 'Northern Ireland',
    },
    {
        shortLabel: 'ROI',
        longLabel: 'Republic of Ireland',
    },
    {
        shortLabel: 'IT',
        longLabel: 'Mainland Italy',
    },
    {
        shortLabel: 'ESP',
        longLabel: 'Mainland Spain',
    },
    {
        shortLabel: 'PL',
        longLabel: 'Poland',
    },
    {
        shortLabel: 'RO',
        longLabel: 'Romania',
    },
    {
        shortLabel: 'SK',
        longLabel: 'Slovakia',
    },
    {
        shortLabel: 'UK',
        longLabel: 'United Kingdom',
    },
]);

export { countries, regions };
