
import { Vue, Component } from 'vue-property-decorator';
import eventEdit from '@/modules/Event/EventEdit';
import countries from '@/modules/Countries';
import { Get } from '@/utils/vuex-module-mutators';
import EventEditForm from '@/views/Events/Components/EventForm/index.vue';
import { ObjectPropertyType } from '@/modules/types';
import { EventType } from '@/api/graphQL/graphNodes/types';
import { ErrorType } from '@plumtreesystems/utils';
import auth from '@/modules/Auth';
import addressSelect from '@/modules/AddressSelect';
import { EVENT_UPDATED_MESSAGE } from '@/modules/Event/Events/messages';
import componentsControls from '@/modules/ComponentsControls';
import { descriptionLengthValidation } from '@/modules/Event/services/fieldUpdateValidations';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import env from '@/environment';
import FormErrorTooltip from '@/projectComponents/formErrorTooltip/index.vue';
import HostessDisplay from './hostess/index.vue';

@Component({
    components: {
        EventEditForm,
        HostessDisplay,
        FormErrorTooltip,
    },
})
export default class EditEvent extends Vue {
    @Get(auth) suspended!: boolean;

    @Get(eventEdit) loading!: boolean;

    @Get(eventEdit) eventData!: EventType;

    @Get(eventEdit) displayErrorTooltip!: boolean;

    @Get(eventEdit) formDisabled!: boolean;

    @Get(eventEdit) formCampaignDisabled!: boolean;

    @Get(eventEdit) formErrors!: ErrorType;

    @Get(countries, 'loading') countriesLoading!: boolean;

    get isLoading(): boolean {
        return this.loading || this.countriesLoading;
    }

    get isMocked() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true';
    }

    get currentDate(): string {
        return this.isMocked
            ? mockedDateManager.getCurrentDate(dateManager.getDayTimeFormat())
            : dateManager.getCurrentDateTime(dateManager.getDayTimeFormat());
    }

    get disabledSave(): boolean {
        return dateManager.isAfter(this.currentDate, this.eventData.eventDateFrom)
         || this.suspended;
    }

    beforeMount() {
        addressSelect.resetData();
        countries.getCountries();
        eventEdit.getEvent(this.$route.params.id);
        eventEdit.clearFormError();
    }

    beforeDestroy() {
        eventEdit.setDisplayErrorTooltip(false);
    }

    handleInput(value: ObjectPropertyType) {
        const { key, val } = value;
        if (key === 'description') {
            const error = descriptionLengthValidation(val);

            if (error) {
                eventEdit.setEventFormError({
                    key,
                    val: error,
                });
            } else {
                eventEdit.removeEventFormError(key);
            }
        }

        eventEdit.setEventProperty(value);
    }

    async handleSave() {
        try {
            await eventEdit.updateEvent();
            if (Object.keys(this.formErrors).length === 0) {
                this.$router.push({ name: 'eventView', params: { id: this.$route.params.id } });
                componentsControls.showSuccessMessage({ message: EVENT_UPDATED_MESSAGE });
            }
            // eslint-disable-next-line no-empty
        } finally {}
    }
}
