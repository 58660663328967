import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import {
    MyCustomersType, EnrolleeCustomerOrderType, BaseOrderType, DownlineProfileType,
    CustomerUpdateProfileType,
} from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetMyCustomersParamsType, GetMyCustomersResultType } from '@/api/graphQL/graphNodes/GetMyCustomersQuery';
import { GetCustomerOrdersParamsType } from '@/api/graphQL/graphNodes/GetCustomerOrdersQuery';
import { GetHostResultType, GetHostParamsType } from '@/api/graphQL/graphNodes/GetHostQuery';
import { UPDATED_SUCCESSFULLY } from '@/utils/messages/formValidation';
import { UpdateDownlineProfileResultType } from '@/api/graphQL/graphNodes/UpdateDownlineProfileQuery';
import componentsControl from '@/modules/ComponentsControls';
import customerFormValidation from './services/customerFormValidation';
import { FORM_ERROR_TOOLTIP } from '../constants';
import { gbLabels } from '../labels';
import MyCustomersRepository from './services/MyCustomersRepository';
import { initialEnrolleeOrderData, initialCustomerOrdersData, profile as defaultProfile } from './defaults';
import { LabelType, ObjectPropertyType } from '../types';
import addressSelect from '../AddressSelect';

@Module({
    namespaced: true, dynamic: true, store, name: 'myCustomers',
})
@AutoMutations
export class MyCustomers extends VuexModule {
    private customers: MyCustomersType[] = [];

    private customerOrders: EnrolleeCustomerOrderType = initialEnrolleeOrderData();

    private customersLoading: boolean = false;

    private customerLoading: boolean = false;

    private searchQuery: string = '';

    private labels: LabelType = { ...gbLabels() };

    private customersSearchQuery: string = '';

    private searchLoading: boolean = false;

    private customersSearchLoading: boolean = false;

    private ordersLoading: boolean = false;

    private activeSponsorDetailsModal: string = '';

    private displaySearchResults: boolean = false;

    private displayCustomersSearchResults: boolean = false;

    private orderDetailsDialogOpen: boolean = false;

    private provideOrderDetails: BaseOrderType = initialCustomerOrdersData();

    private sortDirection = 'DESC';

    private displaySearch: boolean = false;

    private displayOrdersSearch: boolean = false;

    private displayCustomersSearch: boolean = false;

    private expandedCustomers: string[] = [];

    private expandedOrders: string[] = [];

    private offset: number = 0;

    private searchedOrdersOptions: BaseOrderType[] = [];

    private searchedCustomersOptions: MyCustomersType[] = [];

    private customer: DownlineProfileType = defaultProfile();

    private originalCustomer: DownlineProfileType = defaultProfile();

    private orderOffset: number = 0;

    private limit: number = 10;

    private orderLimit: number = 10;

    private total: number = 0;

    private minSearchLength: number = 3;

    private orderTotal: number = 0;

    private loadingInBackground: boolean = false;

    private searchedOrders: BaseOrderType[] = [];

    private formErrors: ErrorType = {};

    private displayTooltip: boolean = false;

    private direct: boolean = true;

    @Mutation
    public setDisplayTooltip(val: boolean) {
        this.displayTooltip = val;
    }

    @Mutation
    public setOffset(val: number) {
        this.offset = val;
    }

    @Mutation
    public setLimit(val: number) {
        this.limit = val;
    }

    @Mutation
    public setTotal(val: number) {
        this.total = val;
    }

    @Mutation
    setDisplaySearchResults(val: boolean) {
        this.displaySearchResults = val;
    }

    @Mutation
    setDisplayCustomersSearchResults(val: boolean) {
        this.displayCustomersSearchResults = val;
    }

    @Mutation
    public setOrderOffset(val: number = 0) {
        this.orderOffset = val;
    }

    @Mutation
    setSearchedOrdersOptions(val: BaseOrderType[]) {
        this.searchedOrdersOptions = [...val];
    }

    @Mutation
    setSearchedCustomersOptions(val: MyCustomersType[]) {
        this.searchedCustomersOptions = [...val];
    }

    @Mutation
    setSearchedOrders(val: BaseOrderType[]) {
        this.searchedOrders = [...val];
    }

    @Mutation
    setSearchLoading(val: boolean) {
        this.searchLoading = val;
    }

    @Mutation
    setCustomersSearchLoading(val: boolean) {
        this.customersSearchLoading = val;
    }

    @Mutation
    public setOrderLimit(val: number) {
        this.orderLimit = val;
    }

    @Mutation
    setSearchQuery(val: string) {
        this.searchQuery = val;
    }

    @Mutation
    setCustomerSearchQuery(val: string) {
        this.customersSearchQuery = val;
    }

    @Mutation
    setActiveSponsorDetailsModal(val: string) {
        this.activeSponsorDetailsModal = val;
    }

    @Mutation
    public setOrderTotal(val: number) {
        this.orderTotal = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setCustomersLoading(val: boolean) {
        this.customersLoading = val;
    }

    @Mutation
    public setCustomerLoading(val: boolean) {
        this.customerLoading = val;
    }

    @Mutation
    public setOrdersLoading(val: boolean) {
        this.ordersLoading = val;
    }

    @Mutation
    public setCustomerOrdersData(val: EnrolleeCustomerOrderType = initialEnrolleeOrderData()) {
        this.customerOrders = { ...val };
    }

    @Mutation
    public setCustomers(val: MyCustomersType[]) {
        this.customers = [...val];
    }

    @Mutation
    public toggleOrderDetailsDialog() {
        this.orderDetailsDialogOpen = !this.orderDetailsDialogOpen;
    }

    @Mutation
    public setProvideOrderDetails(order: BaseOrderType) {
        this.provideOrderDetails = { ...order };
    }

    @Mutation
    public toggleSortDirection() {
        if (this.sortDirection === 'ASC') {
            this.sortDirection = 'DESC';
        } else {
            this.sortDirection = 'ASC';
        }
    }

    @Mutation
    toggleExpandedList(val: string) {
        if (this.expandedCustomers.find((item) => item === val)) {
            const index = this.expandedCustomers.findIndex((item) => item === val);
            this.expandedCustomers.splice(index, 1);
        } else {
            this.expandedCustomers.push(val);
        }
    }

    @Mutation
    toggleExpandedOrdersList(val: string) {
        if (this.expandedOrders.find((item) => item === val)) {
            const index = this.expandedOrders.findIndex((item) => item === val);
            this.expandedOrders.splice(index, 1);
        } else {
            this.expandedOrders.push(val);
        }
    }

    @Mutation
    toggleShowSearch() {
        this.displaySearch = !this.displaySearch;
    }

    @Mutation
    toggleShowOrdersSearch() {
        this.displayOrdersSearch = !this.displayOrdersSearch;
    }

    @Mutation
    toggleShowCustomersSearch() {
        this.displayCustomersSearch = !this.displayCustomersSearch;
    }

    @Mutation
    clearExpandedCustomersList() {
        this.expandedCustomers = [];
    }

    @Mutation
    clearExpandedOrdersList() {
        this.expandedOrders = [];
    }

    @Mutation
    public setLabels(payload: LabelType) {
        this.labels = { ...payload };
    }

    @Mutation
    public clearFormErrors() {
        this.formErrors = {};
    }

    @Mutation
    public removeFormError(key) {
        const { formErrors } = this;
        delete formErrors[key];
        this.formErrors = { ...formErrors };
    }

    @Mutation
    public setCustomer(data: Partial<DownlineProfileType>) {
        this.customer = { ...defaultProfile(), ...data };
    }

    @Mutation
    public setOriginalCustomer(data: Partial<DownlineProfileType>) {
        this.originalCustomer = { ...defaultProfile(), ...data };
    }

    @Mutation
    public setFormErrors(errors: any) {
        this.formErrors = { ...errors };
    }

    @Mutation
    public setFormError(payload: ObjectPropertyType) {
        this.formErrors[payload.key] = payload.val;
    }

    @Mutation
    public setDirect(val: boolean) {
        this.direct = val;
    }

    @Action()
    public validateInvitationForm(data: CustomerUpdateProfileType) {
        this.clearFormErrors();
        const formErrors = customerFormValidation(data);
        formErrors.forEach((error) => this.setFormError(error));
    }

    @Action()
    public displayFormErrorsTooltip() {
        this.setDisplayTooltip(true);
        setTimeout(() => {
            this.setDisplayTooltip(false);
        }, FORM_ERROR_TOOLTIP.timeOutInterval);
    }

    @Action()
    public async getMyCustomer(id: string) {
        try {
            this.setCustomerLoading(true);

            const params: GetHostParamsType = {
                id,
            };

            const data: GetHostResultType = await MyCustomersRepository
                .getMyCustomer(params);

            const formattedData = ObjectProcessor.removeEmptyProperties(data.enrollee);
            this.setCustomer(formattedData);
            this.setOriginalCustomer(formattedData);
        } catch (e) {
            ErrorsProcessor.process(e);
            throw e;
        } finally {
            this.setCustomerLoading(false);
        }
    }

    @Action()
    public async updateCustomer(enrolleeId: string) {
        try {
            const { addressId } = addressSelect;

            this.validateInvitationForm({ ...this.customer, addressLookup: addressId });

            let data: CustomerUpdateProfileType = {
                ...ObjectProcessor.updatedFields(this.originalCustomer, this.customer),
            };

            if (addressId !== '') {
                data = {
                    ...data,
                    addressLookup: addressId,
                };
            }

            const { hidden } = this.customer;

            if (hidden) {
                this.clearFormErrors();
            }

            if (Object.keys(this.formErrors).length === 0) {
                this.setCustomerLoading(true);

                const result: UpdateDownlineProfileResultType = await MyCustomersRepository
                    .updateCustomer(enrolleeId, data);

                const formattedData = ObjectProcessor
                    .removeEmptyProperties(result.updateDownlineProfile);
                this.setCustomer(formattedData);
                this.setOriginalCustomer(formattedData);
                componentsControl.showSuccessMessage({ message: UPDATED_SUCCESSFULLY });
                addressSelect.setAddress();
                addressSelect.clearOptions();
            } else {
                this.displayFormErrorsTooltip();
            }
        } catch (e) {
            this.displayFormErrorsTooltip();
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
            throw e;
        } finally {
            this.setCustomerLoading(false);
        }
    }

    @Action()
    public async getMyCustomers(payload: { loadPage?: boolean, offset?: number }) {
        const { loadPage = false, offset = 0 } = payload;
        const { direct, limit } = this;

        try {
            if (loadPage) {
                this.setLoadingInBackground(true);
            } else {
                this.setCustomersLoading(true);
            }

            const params: GetMyCustomersParamsType = {
                offset,
                limit,
                query: '',
                direct,
            };

            const result = await MyCustomersRepository.getMyCustomers(params);

            this.setCustomers(result.profile.searchCustomers);
            this.setOffset(offset);
            this.setTotal(result.profile.searchCustomersCount);
            this.clearExpandedCustomersList();
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setCustomersLoading(false);
            this.setLoadingInBackground(false);
        }
    }

    @Action()
    public async getCustomerOrders(data: {
        enrolleeId: string,
        loadPage?: boolean,
        offset?: number,
    }) {
        const { enrolleeId, loadPage = false, offset = 0 } = data;

        try {
            if (loadPage) {
                this.setLoadingInBackground(true);
            } else {
                this.setOrdersLoading(true);
            }

            const params: GetCustomerOrdersParamsType = {
                id: enrolleeId,
                limit: this.orderLimit,
                offset,
                query: '',
            };

            const result = await MyCustomersRepository.getCustomerOrders(params);
            this.setCustomerOrdersData(result.enrollee);
            this.setOrderOffset(offset);
            this.setOrderTotal(result.enrollee.searchOrdersCount);
            this.clearExpandedOrdersList();
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoadingInBackground(false);
            this.setOrdersLoading(false);
        }
    }

    @Action()
    async searchCustomers(data: {
        query?: string|null,
        selectedSearch?: boolean,
        offset?: number,
    }
    = { query: null, selectedSearch: false }) {
        const {
            query, selectedSearch, offset = 0,
        } = data;

        const { direct } = this;

        try {
            if (selectedSearch) {
                this.setCustomersSearchLoading(true);
            }

            const params: GetMyCustomersParamsType = {
                limit: this.limit,
                offset,
                query: query || query === '' ? query : this.customersSearchQuery,
                direct,
            };

            const result: GetMyCustomersResultType = await MyCustomersRepository
                .getMyCustomers(params) as GetMyCustomersResultType;

            const { searchCustomers, searchCustomersCount } = result.profile;
            if (selectedSearch) {
                this.setCustomers(searchCustomers);
                this.setTotal(searchCustomersCount);
            } else {
                this.setSearchedCustomersOptions(searchCustomers);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (selectedSearch) {
                this.setCustomersSearchLoading(false);
            }
        }
    }

    @Action()
    async searchOrders(data: {
        query?: string|null,
        selectedSearch?: boolean,
        offset?: number,
        enrolleeId: string,
    }
    = { enrolleeId: '', query: null, selectedSearch: false }) {
        const {
            enrolleeId, query, selectedSearch, offset = 0,
        } = data;

        try {
            if (selectedSearch) {
                this.setSearchLoading(true);
            }

            const params: GetCustomerOrdersParamsType = {
                id: enrolleeId,
                limit: this.limit,
                offset,
                query: query || query === '' ? query : this.searchQuery.replace('#', ''),
            };

            const result = await MyCustomersRepository
                .getCustomerOrders(params);

            const { searchOrders, searchOrdersCount } = result.enrollee;

            if (selectedSearch) {
                this.setSearchedOrders(searchOrders);
                this.setOrderTotal(searchOrdersCount);
            } else {
                this.setSearchedOrdersOptions(searchOrders);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (selectedSearch) {
                this.setSearchLoading(false);
            }
        }
    }

    @Action()
    public async handleSortChange() {
        this.toggleSortDirection();
        await this.getMyCustomers({ loadPage: true, offset: this.offset });
    }
}

export default getModule(MyCustomers);
