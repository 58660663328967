
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { AssetType } from '@/api/graphQL/graphNodes/types';
import profile from '@/modules/Profile';
import auth from '@/modules/Auth';
import FileViewer from '@/projectComponents/fileViewer/index.vue';

@Component({
    components: {
        FileViewer,
    },
})
export default class TermsAndConditions extends Vue {
    @Get(profile) private termsDialogOpen!: boolean;

    @Get(profile) private termsAndConditionsFile!: AssetType|null;

    get termsType(): string {
        if (auth.isAmbassador) {
            return 'Stylist';
        }

        return 'Host';
    }

    handleTermsAndConditionsDialog() {
        profile.toggleTermsDialogOpen();
    }
}
