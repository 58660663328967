var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"Navigation__Container"},[_c('div',{staticClass:"Navigation__LogoSection"},[_c('div',{class:_vm.logoContainerClasses},[_c('img',{attrs:{"src":`${_vm.baseUrl}img/meAmora-logo.png`,"alt":"MeAmora Logo"}})]),(_vm.link !== '')?_c('div',{staticClass:"Navigation__ProfilePic"},[_c('img',{attrs:{"src":_vm.imgUrl}})]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(`${_vm.impersonating ? "You're viewing as " : ""}${_vm.firstName} ${_vm.lastName}`)+" ")])]),(_vm.isAmbassador)?_c('Section',{attrs:{"title":"Home"}},[_c('NavItem',{attrs:{"title":"Dashboard","icon":"dashboard","route":{
                name: 'dashboard',
            }}})],1):_vm._e(),(_vm.isAmbassador)?_c('Section',{attrs:{"title":"Downline"}},[_c('NavItem',{attrs:{"title":"My New Stylists","icon":"fiber_new","route":{
                name: 'newStylists',
            }}}),_c('NavItem',{attrs:{"title":"My Stars","icon":"star_half","route":{
                name: 'myLeaders',
            }}}),_c('NavItem',{attrs:{"title":"Qualified Stylists","icon":"square_foot","route":{
                name: 'myBusinessShape',
            }}}),_c('NavItem',{attrs:{"title":"My Direct Team","icon":"account_tree","route":{
                name: 'myDownline',
            }}}),(_vm.isAmbassador)?_c('NavItem',{attrs:{"title":"My Customers","icon":"group","route":{
                name: 'myCustomers',
            }}}):_vm._e()],1):_vm._e(),(!_vm.impersonating && _vm.isAmbassador)?_c('Section',{attrs:{"title":"My Earnings"}},[_c('NavItem',{attrs:{"title":"My Statements","icon":"description","route":{
                name: 'incomeSummary',
            }}}),_c('NavItem',{attrs:{"title":"My Reports","icon":"view_list","route":{
                name: 'reports',
            }}}),_c('NavItem',{attrs:{"title":"My Discounts and Bonuses","icon":"receipt","route":{
                name: 'storeCredit',
            },"disabled":!_vm.unlockedAfterStage(1)}})],1):_vm._e(),(_vm.isAmbassador)?_c('Section',{attrs:{"title":"My Sales"}},[(!_vm.impersonating)?_c('NavItem',{attrs:{"title":"Order now*","icon":"shopping_cart","actionOnClick":true,"disabled":!_vm.unlockedAfterStage(1)},on:{"click":_vm.handleOrderNew}}):_vm._e(),_c('NavItem',{attrs:{"title":"My Sales","icon":"shopping_basket","route":{
                name: 'orders',
            },"disabled":!_vm.unlockedAfterStage(1)}})],1):_vm._e(),_c('Section',{attrs:{"title":"Pop-Up Planner"}},[(!_vm.impersonating && _vm.isAmbassador)?_c('NavItem',{attrs:{"title":"Create a Pop-Up","icon":"event","route":{
                name: 'createEvent',
            }}}):_vm._e(),_c('NavItem',{attrs:{"title":"My Pop-Up","icon":"date_range","route":{
                name: 'eventCalendar',
            }}})],1),(_vm.isAmbassador || (!_vm.isAmbassador && _vm.isDevelopmentEnvironment))?_c('Section',{attrs:{"title":"Share MeAmora"}},[(_vm.isAmbassador)?_c('NavItem',{attrs:{"title":"Share sign-up link","icon":"group","route":{
                name: 'invite',
            }}}):_vm._e(),(_vm.isAmbassador && _vm.unlockedAfterStage(1))?_c('NavItem',{attrs:{"title":"Share shopping link","icon":"share","route":{
                name: 'shareShoppingLink',
            }}}):_vm._e(),(_vm.isDevelopmentEnvironment)?_c('NavItem',{attrs:{"title":"*** UI Components","route":{
                name: 'uiComponents',
            }}}):_vm._e()],1):_vm._e(),_c('Section',{attrs:{"title":"MeAmora Tools"}},[_c('NavItem',{attrs:{"title":"The Hub Training Suite*","icon":"wb_incandescent","externalLink":_vm.hubTrainingSuiteLink}}),_c('NavItem',{attrs:{"title":"Dash Social Assets*","icon":"chrome_reader_mode","externalLink":_vm.dashSocialAssetsLink}})],1),_c('Section',{attrs:{"title":"My Account"}},[(_vm.impersonating)?_c('NavItem',{attrs:{"title":_vm.endImpersonatingText,"icon":"exit_to_app","route":{
                name: 'endImpersonation',
            }}}):_vm._e(),(!_vm.impersonating)?_c('NavItem',{attrs:{"title":"Personal Information","icon":"account_circle","route":{
                name: 'profile',
            }}}):_vm._e(),(!_vm.impersonating)?_c('NavItem',{attrs:{"title":"Logout","icon":"exit_to_app","route":{
                name: 'logout',
            }}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }