import { EnrolleeCustomerOrderType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetCustomerOrdersQuery extends
    AbstractQueryResource<GetCustomerOrdersResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($query: String!, $id: String!, $offset: Int, $limit: Int) {
            enrollee(id: $id) {
                id,
                firstName,
                lastName,
                searchOrders (query: $query, offset: $offset, limit: $limit) {
                    id,
                    value,
                    grossValue,
                    commissionableValue,
                    psc,
                    date,
                    shippingCost,
                    products {
                        id,
                        price,
                        description,
                        quantity,
                        total,
                        discounts,
                        cv,
                        qv,
                    },
                    enrollee {
                        id,
                        firstName,
                        lastName,
                    },
                    customer {
                        id,
                        firstName,
                        lastName,
                    },
                    type,
                    qualificationValue,
                },
                searchOrdersCount(query: $query)
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getCustomerOrders';
    }
}

export type GetCustomerOrdersResultType = {
    enrollee: EnrolleeCustomerOrderType;
}

export type GetCustomerOrdersParamsType = {
    id: string;
    offset: number;
    limit: number;
    query: string;
}
