import {
    ResolverAssetType, ResolverSearchAssetsCountType,
    ResolverSearchAssetsType,
} from '../types';

const assets: ResolverAssetType[] = [
    {
        id: '12',
        link: '/download/2257f3db8dbe36de9ee7e7c8890aaf17',
        fileName: 'Terms & Conditions',
        originalFileName: 'Terms & Conditions',
        title: 'Terms & Conditions',
        category: 'terms_and_conditions',
        subCategory: '',
        priority: 1,
        context: [
            {
                value: 'application/pdf',
                metric: 'Content-Type',
            },
            {
                metric: 'Embed-Link',
                value: 'https://www.africau.edu/images/default/sample.pdf',
            },
        ],
        uploadDate: '',
    },
];

export const filteredAssetList = (category) => (category !== ''
    ? assets.filter((item) => item.category === category) : assets);

const searchedAssets: (query: string, category: string, subCategoryId: string|null)
 => ResolverAssetType[] = (query, category, subCategoryId) => assets
     .filter((item) => ((item.category === category)
    && (subCategoryId ? item.subCategory === subCategoryId : true)
    && (query === '' ? true : item.fileName.includes(query))));

export const searchAssets: ResolverSearchAssetsType = (params = {
    query: '',
    category: '',
    subCategoryId: null,
    limit: 0,
    offset: 0,
}) => {
    const {
        query, category, subCategoryId, offset, limit,
    } = params;
    const filteredAssets = searchedAssets(query, category, subCategoryId);

    return filteredAssets.splice(offset, limit);
};

export const searchAssetsCount: ResolverSearchAssetsCountType = (params = {
    query: '', category: '', subCategoryId: '',
}) => {
    const { query, category, subCategoryId } = params;
    return searchedAssets(query, category, subCategoryId).length;
};

export { assets };
