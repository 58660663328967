import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import store from '@/store';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import {
    AmbassadorInvitationType, EventViewType, InvitationToEventEnrolleeType,
    InvitationToEventType,
} from '@/api/graphQL/graphNodes/types';
import { initialEventViewData } from '@/modules/Event/Events/defaults';
import EventRepository from '@/modules/Event/Events/services/eventRepository';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { ErrorType, ObjectProcessor, CustomAction as Action } from '@plumtreesystems/utils';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import profile from '@/modules/Profile';
import componentsControl from '@/modules/ComponentsControls';
import env from '@/environment';
import { ObjectPropertyType } from '@/modules/types';
import { initialInvitationData } from '@/modules/Invite/defaults';
import { eventCanceled } from './messages';
import { emailInviteFormValidation } from './services/emailInviteFormValidation';
import { EVENT_EMAIL_INVITE_FAILED, EVENT_EMAIL_INVITE_SENT } from '../Events/messages';
import { EVENT_INVITATION_TYPE } from '../constants';
import { defaultInvitationToEventEnrollee } from '../defaults';

@Module({
    namespaced: true, dynamic: true, store, name: 'eventView',
})
@AutoMutations
export class EventView extends VuexModule {
    private eventData: EventViewType = initialEventViewData();

    private loading: boolean = false;

    private showQrCode: boolean = false;

    private showEmailShare: boolean = false;

    private emailInviteForm: AmbassadorInvitationType = initialInvitationData();

    private eventInvitationLink: string = '';

    private inviteLinkLoading: boolean = false;

    private formErrors: ErrorType = {};

    private showOrderDetails: boolean = false;

    private cancelDialogOpen: boolean = false;

    private sendingInvite: boolean = false;

    private displayCustomerDetails: boolean = false;

    private displayCustomer: InvitationToEventEnrolleeType = defaultInvitationToEventEnrollee();

    private rewardsLoading: boolean = false;

    public showHostessBonusDetails: boolean = false;

    public inviteLinkLoadingInBackground: boolean = false;

    public showShopUrlQr: boolean = false;

    get currentDay() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true'
            ? mockedDateManager.getCurrentDate(dateManager.getDateFormat())
            : dateManager.getCurrentDate(dateManager.getDateFormat());
    }

    get currentDayTime() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true'
            ? mockedDateManager.getCurrentDate(dateManager.getDayTimeFormatUtc())
            : dateManager.getCurrentDate(dateManager.getDayTimeFormatUtc());
    }

    get eventCampaignEnded() {
        return dateManager
            .isAfter(this.currentDayTime, this.eventData.campaignDateTo);
    }

    get invitations(): InvitationToEventType[] {
        return this.eventData.invitations
            .filter((item) => item.type !== EVENT_INVITATION_TYPE.hostess);
    }

    get validForShare(): boolean {
        const { id } = profile.getProfileData;
        const {
            hostess, allowGuestsToInvite, ambassador, invitations,
        } = this.eventData;

        const guest = invitations
            .filter((invite) => invite.type === EVENT_INVITATION_TYPE.guest)
            .find((invite) => invite.enrollee && invite.enrollee.id === id);

        return (allowGuestsToInvite && !!guest)
         || (hostess && hostess.id === id) || ambassador.id === id;
    }

    get shareAvailable(): boolean {
        return this.validForShare;
    }

    @Mutation
    public setEventData(val: EventViewType) {
        this.eventData = { ...val };
    }

    @Mutation
    public setInviteLinkLoading(val: boolean) {
        this.inviteLinkLoading = val;
    }

    @Mutation
    public setInviteLinkLoadingInBackground(val: boolean) {
        this.inviteLinkLoadingInBackground = val;
    }

    @Mutation
    public setSendingInvite(val: boolean) {
        this.sendingInvite = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public toggleQrCode() {
        this.showQrCode = !this.showQrCode;
    }

    @Mutation
    public toggleEmailShare() {
        this.showEmailShare = !this.showEmailShare;
    }

    @Mutation
    public setEmailInviteForm(data: AmbassadorInvitationType) {
        this.emailInviteForm = { ...data };
    }

    @Mutation
    public setEventInvitationLink(val: string) {
        this.eventInvitationLink = val;
    }

    @Mutation
    public setFormErrors(errors: any) {
        this.formErrors = { ...errors };
    }

    @Mutation
    public setFormError(payload: ObjectPropertyType) {
        const { key, val } = payload;
        this.formErrors = ObjectProcessor.setPropertyByValue(key, val, this.formErrors);
    }

    @Mutation
    public clearFormErrors() {
        this.formErrors = {};
    }

    @Mutation
    public setShowOrderDetails(val: boolean) {
        this.showOrderDetails = val;
    }

    @Mutation
    public toggleShowOrderDetails() {
        this.showOrderDetails = !this.showOrderDetails;
    }

    @Mutation
    public setEventCanceled(val: boolean) {
        this.eventData.canceled = val;
    }

    @Mutation
    public setCancelDialogOpen(val: boolean) {
        this.cancelDialogOpen = val;
    }

    @Mutation
    public clearEmailInviteForm() {
        this.emailInviteForm = { ...initialInvitationData() };
    }

    @Mutation
    public setDisplayCustomerDetails(val: boolean) {
        this.displayCustomerDetails = val;
    }

    @Mutation
    public setDisplayCustomer(
        val: InvitationToEventEnrolleeType = defaultInvitationToEventEnrollee(),
    ) {
        this.displayCustomer = val;
    }

    @Mutation
    public setShowHostessBonusDetails(val: boolean) {
        this.showHostessBonusDetails = val;
    }

    @Mutation
    public setShowShopUrlQr(val: boolean) {
        this.showShopUrlQr = val;
    }

    @Mutation
    public toggleShowShopUrlQr() {
        this.showShopUrlQr = !this.showShopUrlQr;
    }

    @Action()
    public async getEvent(params: { id: string, myId: string }) {
        try {
            const { id, myId } = params;
            this.setLoading(true);
            const data = await EventRepository.getEvent({ id });
            this.setEventData(data.event);

            const { ambassador, hostess, invitations } = data.event;

            const canSeeShare = myId === ambassador.id || (hostess && myId === hostess.id)
                || !!invitations.find((invite) => invite.enrollee.id === myId);

            if (data.event.confirmed && canSeeShare && this.shareAvailable && dateManager
                .isBefore(this.currentDayTime, data.event.campaignDateTo)) {
                await this.getEventInvitationLink({ id, loadInBackground: false });
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async getEventInvitationLink(params: { id: string; loadInBackground: boolean; }) {
        try {
            const { id, loadInBackground } = params;

            if (loadInBackground) {
                this.setInviteLinkLoadingInBackground(true);
            } else {
                this.setInviteLinkLoading(true);
            }

            const data = await EventRepository.getEventInviteLink({ id });
            this.setEventInvitationLink(data.shareEvent);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setInviteLinkLoading(false);
            this.setInviteLinkLoadingInBackground(false);
        }
    }

    @Action()
    public async getGuestEventInvitationLink(payload: {
        id: string;
        token: string;
        loadInBackground: boolean;
    }) {
        try {
            const { id, token, loadInBackground } = payload;

            if (loadInBackground) {
                this.setInviteLinkLoadingInBackground(true);
            } else {
                this.setInviteLinkLoading(true);
            }

            const data = await EventRepository.getEventInviteLink({ id }, token);
            this.setEventInvitationLink(data.shareEvent);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setInviteLinkLoading(false);
            this.setInviteLinkLoadingInBackground(false);
        }
    }

    @Action()
    public async sendInviteEmail() {
        this.setSendingInvite(true);
        try {
            this.validateEmailInviteForm();

            if (Object.keys(this.formErrors).length === 0) {
                await EventRepository.inviteEventGuest({
                    eventId: this.eventData.id,
                    invitation: { ...this.emailInviteForm },
                });
                this.clearEmailInviteForm();
                componentsControl.showSuccessMessage({ message: EVENT_EMAIL_INVITE_SENT });
                this.toggleEmailShare();
            }
        } catch (e) {
            componentsControl.showErrorMessage({ message: EVENT_EMAIL_INVITE_FAILED });
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
        } finally {
            this.setSendingInvite(false);
        }
    }

    @Action()
    public async cancelEvent() {
        try {
            this.setLoading(true);
            this.setCancelDialogOpen(false);

            const res = await EventRepository.cancelEvent({ id: this.eventData.id });
            this.setEventCanceled(res.cancelEvent.canceled);
            componentsControl.showSuccessMessage({ message: eventCanceled });
        } catch (e) {
            ErrorsProcessor.process(e);
            throw e;
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public validateEmailInviteForm() {
        this.clearFormErrors();
        const errors = emailInviteFormValidation(this.emailInviteForm);
        errors.forEach((error) => this.setFormError({ key: error.key, val: error.val }));
    }
}

export default getModule(EventView);
