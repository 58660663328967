
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import incomeSummary from '@/modules/IncomeSummary';
import profile from '@/modules/Profile';
import { IncomeSummaryType } from '@/api/graphQL/graphNodes/types';
import CurrencyPrefix from '@/utils/currencyPrefix';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import env from '@/environment';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component
export default class IncomeSummaryTotals extends Vue {
    @Get(incomeSummary) private data!: IncomeSummaryType;

    @Get(incomeSummary) private selectedYear!: string;

    @Get(profile, 'data.region') private region!: string;

    @Get(profile, 'data.joinDate') private joinDate!: string;

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    get mocked() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true';
    }

    get defaultStartFromDate(): string {
        return env.VUE_APP_INCOME_SUMMARY_START_FROM;
    }

    get getYears(): SelectOptionsType[] {
        const { joinDate, defaultStartFromDate } = this;
        const startFrom = dateManager.isBefore(joinDate, defaultStartFromDate)
            ? defaultStartFromDate : joinDate;

        const startFromYear = this.mocked ? Number(mockedDateManager.getCurrentDate('YYYY'))
            : Number(dateManager.getDateTime(startFrom, 'YYYY'));

        const data = [] as SelectOptionsType[];
        const today = this.mocked ? Number(mockedDateManager.getCurrentDate('YYYY'))
            : Number(dateManager.getCurrentDate('YYYY'));

        for (let i = (startFromYear); i < today; i++) {
            data.push({ name: `${i === startFromYear ? startFromYear : i}`, value: `${i}` });
        }

        data.push({ name: 'Current', value: `${today}` });

        return data.sort((a, b) => Number(b.value) - Number(a.value));
    }

    handleSelect(val: string) {
        incomeSummary.getTotals(val);
    }

    format2DecimalPoints(val) {
        return Number(val).toFixed(2);
    }
}
