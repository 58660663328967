import {
    EventShippingAddressType, HostessRewardsType, InvitationToEventEnrolleeType,
} from '@/api/graphQL/graphNodes/types';

const defaultInvitationToEventEnrollee: () => InvitationToEventEnrolleeType = () => ({
    firstName: '',
    email: '',
    id: '',
    lastName: '',
    phoneNumber: '',
});

const defaultHostessRewards: () => HostessRewardsType = () => ({
    label: '0',
    thresholdEUR: 0,
    thresholdGBP: 0,
    rewards: [],
});

const defaultShippingAddress: () => EventShippingAddressType = () => ({
    address: '',
    secondAddress: '',
    city: '',
    country: '',
    county: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    postcode: '',
    title: 'Stylist Address',
    addressLookup: null,
});

export { defaultInvitationToEventEnrollee, defaultHostessRewards, defaultShippingAddress };
