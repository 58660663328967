
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import system from '@/modules/System';
import dateManager from '@/utils/time';
import DateComponent from './date.vue';
import DateTimeComponent from './dateTime.vue';
import TimeComponent from './time.vue';
import TimeSelectComponent from './timeSelect.vue';
import { DATE_PICKER_TYPES } from './constants';

enum DatePickerTypeEnum {
    'date',
    'datetime',
    'time',
    'fullDateTime',
}

@Component({
    components: {
        DateComponent,
        DateTimeComponent,
        TimeComponent,
        TimeSelectComponent,
    },
})
export default class DatePicker extends Vue {
    @Get(system) private screenWidth!: number;

    @Prop({ required: true }) private id!: string;

    @Prop({ default: '' }) private label!: string;

    @Prop({ required: true }) private value!: string;

    @Prop({ default: 'left' }) private position!: string;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: true }) private required!: boolean;

    @Prop({ default: '' }) private minDate!: string;

    @Prop({ default: '' }) private maxDate!: string;

    @Prop({
        default: DatePickerTypeEnum.date,
        validator: (val: string) => DatePickerTypeEnum[val] !== undefined,
    }) private type!: DatePickerTypeEnum;

    @Prop({ default: '' }) private error!: string;

    @Prop({ default: '' }) private helperText!: string;

    @Prop({ required: false }) private startDate!: string;

    @Prop({ required: false, default: '' }) private controlClass!: string;

    @Prop({ default: true }) private timezoneDisabled!: boolean;

    @Prop({ default: 15 }) private timeSelectInterval!: number;

    @Prop({ default: DATE_PICKER_TYPES.timeSelect }) private timeType!: string;

    @Prop({ default: DATE_PICKER_TYPES.yearInput }) private yearType!: string;

    @Prop({ default: '' }) private icon!: string;

    @Prop({ default: false }) private withValueClear!: boolean;

    @Prop({ default: null }) private clearValue!: any;

    private containerClass: string[] = [];

    private dataSelectContainerClass: string[] = [];

    private clickOutsideEvent: Function = () => {};

    private clickOutsideEventFirstCall: boolean = false;

    private openingElement: any = null;

    private transformDialogBox: boolean = false;

    private rightBoundaryOffset!: string;

    get dateTypes() {
        return DATE_PICKER_TYPES;
    }

    get displayTimeSelect() {
        return this.timeType === this.dateTypes.timeSelect;
    }

    get elementClasses() {
        return [
            'DatePickerField',
            ...this.containerClass,
            { 'DatePickerField--error': this.error !== '' },
            { 'DatePickerField--disabled': this.disabled },
            { 'DatePickerField--withIcon': this.icon !== '' },
        ];
    }

    get popupClass() {
        const res = [
            'DatePickerField__DateSelectContainer',
            ...this.dataSelectContainerClass,
        ];

        if (this.position === 'right') {
            res.push('DatePickerField__DateSelectContainer--right');
        }
        return res;
    }

    get componentsStartDate() {
        if (this.value === null) {
            return null;
        }
        if (this.value && typeof this.value === 'string') {
            const timeWithouthTimezone = this.value.split('+')[0];
            return dateManager.getDateTime(timeWithouthTimezone);
        }
        if (this.startDate) {
            const timeWithouthTimezone = this.startDate.split('+')[0];
            return dateManager.getDateTime(timeWithouthTimezone);
        }
        return dateManager.getCurrentDate();
    }

    get formattedVal() {
        return this.value == null ? '' : this.value;
    }

    get originalVal() {
        return this.formattedVal === '' ? dateManager.getCurrentDateTimeUtc() : this.value;
    }

    get controlClasses() {
        const { controlClass } = this;
        return `DatePickerField__Control ${controlClass}`;
    }

    get formatTimeValue() {
        const dateNoTimeZone = dateManager.getDateTimeWithoutTimezone(this.formattedVal);

        if (dateNoTimeZone) {
            return dateManager.getDateTime(dateNoTimeZone, 'HH:mm');
        }

        return '';
    }

    get formatYearValue() {
        const dateNoTimeZone = dateManager.getDateTimeWithoutTimezone(this.formattedVal);

        if (dateNoTimeZone) {
            return dateManager.getDateTime(dateNoTimeZone, 'YYYY');
        }

        return '';
    }

    get isSelectContainerActive() {
        return this.dataSelectContainerClass.includes('DatePickerField__DateSelectContainer--active');
    }

    optionalLabel(val: string) {
        return `${val} (optional)`;
    }

    handleTextDateUpdate(val: string) {
        const zone = dateManager.getTimeZoneRegex(this.formattedVal);

        if (zone) {
            const time = dateManager.getTime(zone.input);
            const resultDate = `${dateManager.getDateTime(val, 'YYYY-MM-DD')}T${time}${zone[0]}`;
            this.emitInput(resultDate);
        }
    }

    handleDateUpdate(val: any) {
        const zone = dateManager.getTimeZoneRegex(this.originalVal);

        if (zone) {
            const time = dateManager.getTime(zone.input);
            const formattedDate = `${dateManager.getDateTime(val.startDate, 'YYYY-MM-DD')}T${time}${zone[0]}`;
            this.emitInput(formattedDate);
        }
    }

    handleTimeUpdate(val: string) {
        const zone = dateManager.getTimeZoneRegex(this.originalVal);
        const dateNoTimeZone = dateManager.getDateTimeWithoutTimezone(this.originalVal);

        if (zone) {
            const formattedDate = `${dateManager.getDateTime(dateNoTimeZone, 'YYYY-MM-DD')}T${val}${zone[0]}`;

            this.emitInput(formattedDate);
        }
    }

    handleYearUpdate(val: string) {
        let formattedTime;

        if (this.formattedVal === '') {
            formattedTime = dateManager.getDateTime(dateManager.getCurrentDateTime(), 'MM-DD HH:mmZ');
        } else {
            formattedTime = dateManager.getDateTime(this.formattedVal, 'MM-DD HH:mmZ');
        }

        if (val === '') {
            this.emitInput('');
        } else if (formattedTime) {
            const formattedDate = `${val}-${formattedTime}`;
            this.emitInput(formattedDate);
        }
    }

    addToContainerClass(items: string[]) {
        const combinedObject = new Set([...this.containerClass, ...items]);
        this.containerClass = [...combinedObject];
    }

    removeFromContainerClass(items: string[]) {
        this.containerClass = this.containerClass.filter((classItem) => !items.includes(classItem));
    }

    addToDataSelectContainerClass(items: string[]) {
        const combinedObject = new Set([...this.dataSelectContainerClass, ...items]);
        this.dataSelectContainerClass = [...combinedObject];
    }

    removeFromDataSelectContainerClass(items: string[]) {
        this.dataSelectContainerClass = this
            .dataSelectContainerClass.filter((classItem) => !items.includes(classItem));
    }

    elementOnBlur(el: any) {
        this.setActive(el, false);
        this.removeFromContainerClass(['DatePickerField--focused']);
    }

    elementOnFocus(el: any) {
        if (!this.disabled) {
            this.setActive(el, true);
            this.addToContainerClass(['DatePickerField--focused']);
        }
    }

    setActive(el: any, active: boolean) {
        if (this.type.toString() !== 'time') {
            if (active) {
                this.addToContainerClass(['DatePickerField--isPulled']);
            } else if (el.value === '') {
                this.removeFromContainerClass(['DatePickerField--isPulled', 'DatePickerField--isFilled']);
            } else {
                this.addToContainerClass(['DatePickerField--isFilled']);
            }
        }
    }

    dialogElement() {
        return (this.$refs.dateComponentBox as HTMLElement).querySelector('.DatePickerField__DateSelectContainer')!;
    }

    inputElement() {
        return (this.$refs.dateComponentBox as HTMLElement).querySelector('.DatePickerField__Input')!;
    }

    dateTimeBoxBoundaries() {
        const inputEl = this.inputElement().getBoundingClientRect().right;
        const rightInputBoundaryOffset = this.screenWidth - inputEl;
        const el = this.dialogElement().getBoundingClientRect();
        this.rightBoundaryOffset = `${this.screenWidth - el.right - rightInputBoundaryOffset}px`;
        this.transformDialogBox = el.right <= this.screenWidth;
    }

    setOutsideClickListener(e: Node): void {
        if (this.openingElement === e || this.disabled) {
            return;
        }
        this.dateTimeBoxBoundaries();

        this.openingElement = e;
        this.clickOutsideEventFirstCall = true;
        this.addToDataSelectContainerClass(['DatePickerField__DateSelectContainer--active']);

        if (this.dialogElement && !this.transformDialogBox) {
            this.dialogElement().setAttribute('style', `transform: scale(1, 1) translate(${this.rightBoundaryOffset}, 0)`);
        }

        this.clickOutsideEvent = (event: Event) => {
            const index = this.icon === '' ? 0 : 1;
            const el: HTMLElement = this.$children[index].$refs.dateSelectContainer as HTMLElement;
            const selectedElementNode: Node = event.target as Node;

            if (!(el === event.target || el.contains(selectedElementNode))) {
                if (this.clickOutsideEventFirstCall) {
                    this.clickOutsideEventFirstCall = false;
                } else {
                    this.closeFormDataSelectContainer();
                }
            }
        };

        document.addEventListener('click', this.clickOutsideEvent as EventListener);
    }

    closeFormDataSelectContainer(): void {
        this.removeFromDataSelectContainerClass(['DatePickerField__DateSelectContainer--active']);
        if (this.dialogElement && !this.transformDialogBox) {
            this.dialogElement().removeAttribute('style');
        }

        this.openingElement = null;
        document.removeEventListener('click', this.clickOutsideEvent as EventListener);
    }

    handleClose() {
        this.closeFormDataSelectContainer();
    }

    handleValueChange(val: string) {
        this.emitInput(val);
    }

    handleClear(val) {
        this.emitInput(val, true);
    }

    emitInput(val, noCheck = false) {
        if (noCheck) {
            this.$emit('input', val);
            return;
        }
        const { minDate, maxDate } = this;
        if (minDate === '' && maxDate === '') {
            this.$emit('input', val);
        } else if (minDate && maxDate) {
            if (dateManager.getDifference(val, minDate) <= 0
             && dateManager.getDifference(val, maxDate) >= 0) {
                this.$emit('input', val);
            }
        } else if (minDate) {
            if (dateManager.getDifference(val, minDate) <= 0) {
                this.$emit('input', val);
            }
        } else if (maxDate) {
            if (dateManager.getDifference(val, maxDate) >= 0) {
                this.$emit('input', val);
            }
        }
    }

    created() {
        if (this.formattedVal) {
            this.addToContainerClass(['DatePickerField--isFilled', 'DatePickerField--isPulled']);
        }
    }

    beforeUpdate() {
        if (this.formattedVal) {
            if (
                (this.containerClass.indexOf('DatePickerField--isFilled') === -1)
                && (this.containerClass.indexOf('DatePickerField--isPulled') === -1)
            ) {
                this.addToContainerClass(['DatePickerField--isFilled', 'DatePickerField--isPulled']);
            }
        }
    }
}
