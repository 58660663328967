import { CustomerUpdateProfileType } from '@/api/graphQL/graphNodes/types';
import { FIELD_REQUIRED_MESSAGE } from '@/utils/messages/formValidation';
import { ValidationErrorType } from '../../types';

const customerFormValidation:
    (val: CustomerUpdateProfileType) =>
    ValidationErrorType[] = (val) => {
        const errors: ValidationErrorType[] = [];

        if (!val.lastName) {
            errors.push({ key: 'lastName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.firstName) {
            errors.push({ key: 'firstName', val: FIELD_REQUIRED_MESSAGE });
        }

        return errors;
    };

export default customerFormValidation;
