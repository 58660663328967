
import { RankRequirementsType } from '@/api/graphQL/graphNodes/types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BusinessShapeListItem extends Vue {
    @Prop({ required: true }) private maxValue!: number;

    @Prop({ required: true }) private level!: string;

    @Prop({ required: true }) private active!: RankRequirementsType;

    @Prop({ required: true }) private inactive!: RankRequirementsType;

    @Prop({ required: true }) private partiallyActive!: RankRequirementsType;

    itemStyles(value) {
        const width = (value * 100) / this.maxValue;
        return { width: `${width}%` };
    }
}
