import API from '@/api/graphQL';
import { GetDashboardParamsType } from '@/api/graphQL/graphNodes/GetDashboardQuery';
import { GetDashboardStatsParamsType } from '@/api/graphQL/graphNodes/GetDashboardStatsQuery';
import { GetRankingsParamsType } from '@/api/graphQL/graphNodes/GetRankingsQuery';

export default class DashboardRepository {
    static getData(data: GetDashboardParamsType) {
        return API
            .getDashboard()
            .query(data);
    }

    static getDataStats(data: GetDashboardStatsParamsType) {
        return API
            .getDashboardStats()
            .query(data);
    }

    static getRankings(params: GetRankingsParamsType) {
        return API
            .getRankings()
            .query(params);
    }
}
