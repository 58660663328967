import resolveStatusType from '@/projectComponents/enrolleeLegItem/Item/services/resolveStatusType';
import { CalculateStatusType } from '@/utils/graphql-mock-server/types';

const calculateStatus: (data: CalculateStatusType)
 => string = (data) => {
     const pv = data.stats.find((stat) => stat.metric === 'pv');
     if (pv) {
         const value = Number(pv.value);
         const percentage = value >= 150 ? 100 : (value * 100) / 150;
         return resolveStatusType(percentage);
     }

     return resolveStatusType(0);
 };

export default calculateStatus;
