
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import storeCredit from '@/modules/StoreCredit';
import { StatsType } from '@/api/graphQL/graphNodes/types';
import Skeleton from './StoreCreditSkeleton/index.vue';
import Transactions from './Transactions/index.vue';
import Balance from './Balance/index.vue';

@Component({
    components: {
        Transactions,
        Balance,
        Skeleton,
    },
})
export default class StoreCredit extends Vue {
    @Get(storeCredit) private balance!: StatsType;

    @Get(storeCredit) private loading!: boolean;

    mounted() {
        // TODO: remove after fixing impersonate
        storeCredit.setOffset(0);
        storeCredit.getSisterCreditData({ offset: 0 });
    }
}
