import {
    Module, VuexModule, Mutation, getModule,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import store from '@/store';
import dateManager from '@/utils/time';
import calendarEvents from './Events';
// import calendarStatistics from './Statistics';
import { CALENDAR_EVENTS_FINISH_STATUS_OPTIONS, CALENDAR_TIME_FRAME_OPTIONS, EVENT_TYPE } from './constants';
import { defaultCurrentDay } from './defaults';
import { CalendarDayObject } from './Events/types';

@Module({
    namespaced: true, dynamic: true, store, name: 'calendar',
})
@AutoMutations
export class Calendar extends VuexModule {
    private calendarTimeFrameOptions: SelectOptionsType[] = [
        {
            name: 'Month',
            value: CALENDAR_TIME_FRAME_OPTIONS.month,
        },
        {
            name: 'Week',
            value: CALENDAR_TIME_FRAME_OPTIONS.week,
        },
        {
            name: 'Day',
            value: CALENDAR_TIME_FRAME_OPTIONS.day,
        },
    ];

    private calendarEventsStatusOptions: SelectOptionsType[] = [
        {
            name: 'Not Done',
            value: CALENDAR_EVENTS_FINISH_STATUS_OPTIONS.notDone,
        },
        {
            name: 'Done',
            value: CALENDAR_EVENTS_FINISH_STATUS_OPTIONS.done,
        },
        {
            name: 'All',
            value: CALENDAR_EVENTS_FINISH_STATUS_OPTIONS.all,
        },
    ];

    private calendarTimeFrame: string = CALENDAR_TIME_FRAME_OPTIONS.month;

    private eventsStatus: string = CALENDAR_EVENTS_FINISH_STATUS_OPTIONS.notDone;

    private calendarDate: string = defaultCurrentDay();

    private eventsTypeFilter: string = EVENT_TYPE.personal

    private isDataLoaded: boolean = false;

    private isLoading: boolean = false;

    private isLoadingInBackground: boolean = false;

    get getFirstMonthDay() {
        return `${dateManager.getDateTime(this.calendarDate, 'YYYY-MM')}-01`;
    }

    get getLastMonthDay() {
        return `${dateManager.getDateWithOffset(-1, this.getNextMonth, dateManager.getDateFormat())}`;
    }

    get getFirstWeekDay() {
        const dayOfWeek = Number(dateManager.getDateTime(this.calendarDate, 'd'));
        const offset = 0 - (dayOfWeek - 1);
        return dateManager
            .getDateWithOffset(offset, this.calendarDate, dateManager.getDateFormat());
    }

    get getLastWeekDay() {
        const dayOfWeek = Number(dateManager.getDateTime(this.calendarDate, 'd'));
        const offset = 7 - dayOfWeek;
        return dateManager
            .getDateWithOffset(offset, this.calendarDate, dateManager.getDateFormat());
    }

    get getCalendarStart(): string {
        switch (this.calendarTimeFrame) {
        case CALENDAR_TIME_FRAME_OPTIONS.month: {
            const firstDate = this.getFirstMonthDay;
            const dayOfWeek = Number(dateManager.getDateTime(firstDate, 'd'));
            const offset = 0 - (dayOfWeek - 1);
            return dateManager
                .getDateWithOffset(offset, firstDate, dateManager.getDateFormat());
        }
        case CALENDAR_TIME_FRAME_OPTIONS.week: {
            return this.getFirstWeekDay;
        }
        case CALENDAR_TIME_FRAME_OPTIONS.day: {
            return this.calendarDate;
        }
        default: {
            return this.calendarDate;
        }
        }
    }

    get getNextMonth(): string {
        const month = Number(dateManager.getDateTime(this.calendarDate, 'M'));
        if (month < 12) {
            return `${dateManager.getDateTime(this.calendarDate, 'YYYY')}-${month < 9 ? '0' : ''}${month + 1}-01`;
        }

        const nextYear = Number(dateManager.getDateTime(this.calendarDate, 'YYYY')) + 1;
        return `${nextYear}-01-01`;
    }

    get getPreviousMonth(): string {
        const month = Number(dateManager.getDateTime(this.calendarDate, 'M'));
        if (month > 1) {
            return `${dateManager.getDateTime(this.calendarDate, 'YYYY')}-${month < 9 ? '0' : ''}${month - 1}-01`;
        }

        const year = Number(dateManager.getDateTime(this.calendarDate, 'YYYY'));
        return `${year - 1}-12-01`;
    }

    get getCalendarEnd(): string {
        switch (this.calendarTimeFrame) {
        case CALENDAR_TIME_FRAME_OPTIONS.month: {
            const dayOfWeek = Number(dateManager.getDateTime(this.getNextMonth, 'd'));
            if (dayOfWeek === 1) {
                return dateManager
                    .getDateWithOffset(-1, this.getNextMonth, dateManager.getDateFormat());
            }

            const offset = 7 - dayOfWeek;
            return dateManager
                .getDateWithOffset(offset, this.getNextMonth, dateManager.getDateFormat());
        }
        case CALENDAR_TIME_FRAME_OPTIONS.week: {
            return this.getLastWeekDay;
        }
        case CALENDAR_TIME_FRAME_OPTIONS.day: {
            return this.calendarDate;
        }
        default: {
            return this.calendarDate;
        }
        }
    }

    get getNextTimeFrame():string {
        switch (this.calendarTimeFrame) {
        case CALENDAR_TIME_FRAME_OPTIONS.month: {
            return this.getNextMonth;
        }
        case CALENDAR_TIME_FRAME_OPTIONS.week: {
            return dateManager.getDateWithOffset(7, this.calendarDate, dateManager.getDateFormat());
        }
        case CALENDAR_TIME_FRAME_OPTIONS.day: {
            return dateManager.getDateWithOffset(1, this.calendarDate, dateManager.getDateFormat());
        }
        default: {
            return dateManager.getDateWithOffset(1, this.calendarDate, dateManager.getDateFormat());
        }
        }
    }

    get getPreviousTimeFrame():string {
        switch (this.calendarTimeFrame) {
        case CALENDAR_TIME_FRAME_OPTIONS.month: {
            return this.getPreviousMonth;
        }
        case CALENDAR_TIME_FRAME_OPTIONS.week: {
            return dateManager
                .getDateWithOffset(-7, this.calendarDate, dateManager.getDateFormat());
        }
        case CALENDAR_TIME_FRAME_OPTIONS.day: {
            return dateManager
                .getDateWithOffset(-1, this.calendarDate, dateManager.getDateFormat());
        }
        default: {
            return dateManager
                .getDateWithOffset(-1, this.calendarDate, dateManager.getDateFormat());
        }
        }
    }

    get getTimeFrameDayDates(): string[] {
        const length = dateManager.getDifference(this.getCalendarStart, this.getCalendarEnd, 'day');

        const res: string[] = [];

        for (let i = 0; i ! <= length; i++) {
            res.push(dateManager
                .getDateWithOffset(i, this.getCalendarStart, dateManager.getDateFormat()));
        }

        return res;
    }

    get getOrganisationalEventType(): string {
        return EVENT_TYPE.organisational;
    }

    get getPersonalEventType(): string {
        return EVENT_TYPE.personal;
    }

    get getStatisticPeriodStart(): string {
        switch (this.calendarTimeFrame) {
        case CALENDAR_TIME_FRAME_OPTIONS.month: {
            return this.getFirstMonthDay;
        }
        case CALENDAR_TIME_FRAME_OPTIONS.week: {
            return this.getFirstWeekDay;
        }
        case CALENDAR_TIME_FRAME_OPTIONS.day: {
            return this.calendarDate;
        }
        default: {
            return this.calendarDate;
        }
        }
    }

    get getStatisticPeriodEnd(): string {
        switch (this.calendarTimeFrame) {
        case CALENDAR_TIME_FRAME_OPTIONS.month: {
            return this.getLastMonthDay;
        }
        case CALENDAR_TIME_FRAME_OPTIONS.week: {
            return this.getLastWeekDay;
        }
        case CALENDAR_TIME_FRAME_OPTIONS.day: {
            return this.calendarDate;
        }
        default: {
            return this.calendarDate;
        }
        }
    }

    get getSelectedMonthDate() {
        return dateManager.getDateTime(this.calendarDate, 'MMMM YYYY');
    }

    get getSelectedDayDisplayDate() {
        return `${dateManager.getDateTime(this.calendarDate, 'ddd')} ${dateManager.getDateTime(this.calendarDate, 'DD')}`;
    }

    get getDayEvents(): CalendarDayObject {
        const { calendarDate } = this;

        const days = calendarEvents.getDays;
        return days[calendarDate];
    }

    get currentDayTotal(): number {
        const day = this.getDayEvents;

        return day.statistics.totalEvents ? Number(day.statistics.totalEvents) : 0;
    }

    @Mutation
    setScheduleDate(date: string) {
        this.calendarDate = date;
    }

    @Mutation
    setScheduleTimeFrame(data: string) {
        this.calendarTimeFrame = data;
    }

    @Mutation
    setEventsStatus(data: string) {
        this.eventsStatus = data;
    }

    @Mutation
    setDataLoaded(state: boolean) {
        this.isDataLoaded = state;
    }

    @Mutation
    setLoading(state: boolean) {
        this.isLoading = state;
    }

    @Mutation
    setLoadingInBackground(val: boolean) {
        this.isLoadingInBackground = val;
    }

    @Mutation
    setEventsTypeFilter(value) {
        this.eventsTypeFilter = value;
    }

    @Action()
    async loadEvents(offset: number = 0) {
        this.setDataLoaded(false);
        await this.loadAction({ offset });
        this.setDataLoaded(true);
    }

    @Action()
    async loadAction(data: { offset: number, inBackground?: boolean }) {
        const { offset, inBackground } = data;

        const requests: Promise<void>[] = [
            calendarEvents
                .loadDayEvents({
                    dateFrom: this.getCalendarStart,
                    dateTo: this.getCalendarEnd,
                    offset,
                    type: this.eventsTypeFilter,
                    status: this.eventsStatus,
                    timeframe: this.calendarTimeFrame,
                }),
            // calendarStatistics
            //     .loadStatistics({
            //         dateFrom: this.getStatisticPeriodStart,
            //         dateTo: this.getStatisticPeriodEnd,
            //         type: this.eventsTypeFilter,
            //     }),
        ];

        if (inBackground) {
            this.setLoadingInBackground(true);
        } else {
            this.setLoading(true);
        }

        await Promise.all(requests)
            .finally(() => {
                if (inBackground) {
                    this.setLoadingInBackground(false);
                } else {
                    this.setLoading(false);
                }
            });
    }

    @Action()
    async changeSelectedDateTime(type: string) {
        switch (type) {
        case 'today':
            this.setScheduleDate(dateManager.getCurrentDateTime(dateManager.getDateFormat()));
            break;
        case 'back':
            this.setScheduleDate(this.getPreviousTimeFrame);
            break;
        case 'forward':
            this.setScheduleDate(this.getNextTimeFrame);
            break;
        default:
            break;
        }

        await this.loadEvents();
    }

    @Action()
    async handleTimeFrameChange(data: string) {
        this.setScheduleTimeFrame(data);
        await this.loadEvents();
    }

    @Action()
    async handleDisplayEventChange(data: string) {
        this.setEventsStatus(data);
        await this.loadEvents();
    }

    @Action()
    async handleEventsTypeFilter(value) {
        this.setEventsTypeFilter(value);
        await this.loadEvents();
    }
}

export default getModule(Calendar);
