
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { TransactionsType } from '@/api/graphQL/graphNodes/types';
import { TRANSACTION_CHANGE_TYPE } from '@/modules/StoreCredit/constants';
import storeCredit from '@/modules/StoreCredit';
import { PaginationParamsType } from '@/components/pagination/types';
import { scrollToTop } from '@plumtreesystems/utils';
import dateManager from '@/utils/time';
import { currencySign } from '@/modules/StoreCredit/helpers';
import { DATE_PICKER_TYPES } from '@/components/datePicker/constants';

@Component
export default class StoreCreditTransactions extends Vue {
    @Sync(storeCredit) private from!: string|null;

    @Sync(storeCredit) private to!: string|null;

    @Get(storeCredit) private transactions!: TransactionsType[];

    @Get(storeCredit) private offset!: number;

    @Get(storeCredit) private limit!: number;

    @Get(storeCredit) private total!: number;

    @Get(storeCredit) private loadingInBackground!: boolean;

    get displayPagination() {
        return this.total > this.limit;
    }

    get dateTypes() {
        return DATE_PICKER_TYPES;
    }

    tableColumnClasses(transactionType) {
        return [
            'Ui__TableColumn--right',
            { 'SisterCredit__TableColumnChange--deposit': transactionType === TRANSACTION_CHANGE_TYPE.deposit },
            { 'SisterCredit__TableColumnChange--charge': transactionType === TRANSACTION_CHANGE_TYPE.charge },
        ];
    }

    formatDate(date) {
        return dateManager.getDateTime(date, dateManager.getDayTimeFormatUK());
    }

    transactionCurrency(currency) {
        return currencySign(currency);
    }

    transactionSign(type: string) {
        switch (type) {
        case TRANSACTION_CHANGE_TYPE.deposit:
            return '+';
        case TRANSACTION_CHANGE_TYPE.charge:
            return '-';
        default:
            return '';
        }
    }

    formatCents(unformatted) {
        return Number(unformatted).toFixed(2);
    }

    async handlePagination(params: PaginationParamsType) {
        const { offset } = params;
        this.loadData(offset);
    }

    @Watch('from')
    handleFromFilterChange() {
        this.loadData(this.offset);
    }

    @Watch('to')
    handleToFilterChange() {
        this.loadData(this.offset);
    }

    async loadData(offset) {
        try {
            await storeCredit.getSisterCreditData({ loadPage: true, offset });
            scrollToTop('#app > div.App__Layout > div > div.Scene__Content.Scene__Content--noPadding');
        // eslint-disable-next-line no-empty
        } finally {}
    }
}
