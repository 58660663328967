export const REGISTERED_MESSAGE: string = 'Registered successfully';

export const FIELD_REQUIRED_MESSAGE: string = 'This field is required';

export const MINIMUM_TIME_FRAME_MESSAGE: string = 'Time period is too short. Minimum is 1 hour.';

export const INVALID_EMAIL_ADDRESS: string = 'Invalid email address';

export const AGE_RESTRICTION_MESSAGE: string = 'We require users to be 18 years old or over';

export const INVITATION_SENT_SUCCESSFULLY: string = 'Invitation sent successfully.';

export const INVITATION_RESENT_SUCCESSFULLY: string = 'Email has been resent to your inbox.';

export const UPDATED_SUCCESSFULLY: string = 'Updated successfully';

export const NATIONAL_NUMBER_MESSAGE: string = 'This field is part of our KYC process';

export const BIRTHDATE_MESSAGE: string = 'You must be over 18 to become an Independent MeAmora Stylist';

export const MATCHING_COUNTRIES_MESSAGE: string = 'Your address doesn\'t match selected country';

export const MUST_CONTAIN_ADDRESS: string = 'Event must contain an address';

export const BAD_DISPLAY_DATE_FORMAT: string = 'Birth date format: DD-MM-YYYY';
