
import { Vue, Component } from 'vue-property-decorator';
import componentsSandbox from '@/modules/ComponentsSandbox';
import { Sync } from '@/utils/vuex-module-mutators';
import { DATE_PICKER_TYPES } from '@/components/datePicker/constants';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UIDatePickers extends Vue {
    @Sync(componentsSandbox) date!: string;

    @Sync(componentsSandbox) dateWithClear!: string;

    @Sync(componentsSandbox) dateInput!: string;

    @Sync(componentsSandbox) minDate!: string;

    @Sync(componentsSandbox) maxDate!: string;

    get dateTypes() {
        return DATE_PICKER_TYPES;
    }
}
