import { EVENT_DESCRIPTION_MAX_LENGTH, EVENT_DESCRIPTION_MAX_LENGTH_ERROR } from '../constants';

const descriptionLengthValidation: (val: string) => string|null = (val) => {
    if (JSON.stringify(val).length > EVENT_DESCRIPTION_MAX_LENGTH) {
        return EVENT_DESCRIPTION_MAX_LENGTH_ERROR;
    }

    return null;
};

export { descriptionLengthValidation };
