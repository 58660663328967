// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetShopUrlQuery extends AbstractQueryResource<GetShopUrlResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($redirectTo: String) {
            profile {
                shopPreAuthUrl (redirectTo: $redirectTo),
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'GetShopUrl';
    }
}

export type GetShopUrlResultType = {
    profile: {
        shopPreAuthUrl: string|null;
    }
}

export type GetShopUrlParamsType = {
    redirectTo: string|null;
}
