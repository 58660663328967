import { ResolverEnrolleeType } from '../types';
import { enrollee } from './enrollee';
import { ranks } from './ranks';
import { stats } from './stats';
import { rankRequirement } from './rankRequirements';

const risingStars: (enrolleeId: string, params: { sort: string,
     limit: number, offset: number})
 => ResolverEnrolleeType[] = (enrolleeId, params) => {
     const { limit, offset } = params;

     const enrolleeItem: ResolverEnrolleeType = enrollee(enrolleeId);

     const res = enrolleeItem.risingStars.map((element) => enrollee(element));

     if (params.sort === '') {
         return res.sort((a, b) => b.totals.pv - a.totals.pv).splice(offset, limit);
     }

     const taRank = ranks.find((rank) => rank.label === 'Star Stylist');
     const taReq = rankRequirement(taRank!.id);
     const resWithTa = res.map((item) => {
         const itemStats = stats(item.id);
         let total = 0;
         let totalVal = 0;
         taReq.forEach((requirement) => {
             const stat = itemStats.find((statItem) => statItem.metric === requirement.metric);
             if (Number(stat!.value) >= Number(requirement.value)) {
                 totalVal += 100;
             } else {
                 totalVal += Math.floor((Number(stat!.value) * 100) / Number(requirement.value));
             }

             ++total;
         });

         return {
             enrollee: item, cof: totalVal / total,
         };
     });

     return resWithTa.sort((a, b) => b.cof - a.cof).map((item) => item.enrollee);
 };

export {
    risingStars,
};
