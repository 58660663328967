import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { AssetType, PersonalCommissionStatementsType } from '@/api/graphQL/graphNodes/types';
import { GetPersonalStatementsResultType } from '@/api/graphQL/graphNodes/GetPersonalStatementsQuery';
import IncomeSummaryRepository from './services/incomeSummaryRepository';
import fileFormatter from './services/fileFormatter';

@Module({
    namespaced: true, dynamic: true, store, name: 'personalStatements',
})
@AutoMutations
export class PersonalStatements extends VuexModule {
    private loading: boolean = false;

    private loadingInBackground: boolean = false;

    private files: PersonalCommissionStatementsType[] = [];

    private previewOpen: boolean = false;

    private previewFileId: string = '';

    private limit: number = 10;

    private offset: number = 0;

    private count: number = 0;

    get fileList(): AssetType[] {
        return this.files
            .map((file) => ({ ...file, downloadUrl: file.presignedDownloadUrl }))
            .map((file) => fileFormatter(file));
    }

    @Mutation
    public setFiles(val: PersonalCommissionStatementsType[]) {
        this.files = [...val];
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setPreviewOpen(val: boolean) {
        this.previewOpen = val;
    }

    @Mutation
    public setPreviewFileId(val: string) {
        this.previewFileId = val;
    }

    @Mutation
    public setCount(val: number) {
        this.count = val;
    }

    @Mutation
    public setLimit(val: number) {
        this.limit = val;
    }

    @Mutation
    public setOffset(val: number) {
        this.offset = val;
    }

    @Action()
    public async getStatements(props = { inBackground: false }) {
        const { inBackground } = props;

        try {
            if (inBackground) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const { offset, limit } = this;

            const res: GetPersonalStatementsResultType = await IncomeSummaryRepository
                .getPersonalStatements({ offset, limit });

            const { personalStatements, personalStatementsCount } = res.profile;
            this.setCount(personalStatementsCount);

            if (personalStatements) {
                this.setFiles(personalStatements);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (inBackground) {
                this.setLoadingInBackground(false);
            } else {
                this.setLoading(false);
            }
        }
    }
}

export default getModule(PersonalStatements);
