import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import store from '@/store';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import { defaultEnrollee } from '@/modules/Calendar/defaults';
import { CreateEventHostessType, CreateEventType } from '@/modules/Event/Events/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { CreateEventParamsType } from '@/api/graphQL/graphNodes/CreateEventQuery';
import { CreateEventResultType } from '@/api/graphQL/graphNodes/typesResult';
import profile from '@/modules/Profile/index';
import {
    EventShippingAddressType, HostessInvitationInputType,
    KnownHostessInvitationInputType,
} from '@/api/graphQL/graphNodes/types';
import { ObjectPropertyType } from '@/modules/types';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import env from '@/environment';
import settings from '@/modules/Settings';
import { initialEventCreateData } from '../Events/defaults';
import EventRepository from '../Events/services/eventRepository';
import { formEventValidation } from '../services/formEventValidation';
import formHostessValidation from '../services/formHostessValidation';
import { EVENT_HOST_TYPE, MAX_CAMPAIGN_LENGTH } from '../constants';
import { FORM_ERROR_TOOLTIP } from '../../constants';
import { defaultShippingAddress } from '../defaults';
import dateAccountedToWinterTime from '../services/dateAccountedToWinterTime';

@Module({
    namespaced: true, dynamic: true, store, name: 'eventCreate',
})
@AutoMutations
export class EventCreate extends VuexModule {
    private eventHostType: number = EVENT_HOST_TYPE.undefined;

    private eventData: CreateEventType = initialEventCreateData();

    private eventFormErrors: ErrorType = {};

    private hostess: null|CreateEventHostessType = defaultEnrollee();

    private hostessFormErrors: ErrorType = {};

    private loading: boolean = false;

    private hostessFilter: string = '';

    private displayTooltip: boolean = false;

    private redirectUrl: string|null = null;

    get getDateWithOffset() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true'
            ? mockedDateManager.getCurrentDateWithOffset(15, dateManager.getDayTimeFormatUtc(), 'm')
            : dateManager.getCurrentDateWithOffset(15, dateManager.getDayTimeFormatUtc(), 'm');
    }

    get getCampaignEndDateWithOffset() {
        if (env.VUE_APP_MOCK_GRAPHQL === 'true') {
            const dateWithOffset = mockedDateManager
                .getCurrentDateWithOffset(
                    MAX_CAMPAIGN_LENGTH - 1,
                    dateManager.getDayTimeFormatUtc(),
                );

            return dateManager.getDateWithOffset(15, dateWithOffset, dateManager.getDayTimeFormatUtc(), 'm');
        }
        const dateWithOffset = dateManager
            .getCurrentDateWithOffset(MAX_CAMPAIGN_LENGTH - 1, dateManager.getDayTimeFormatUtc());

        return dateManager.getDateWithOffset(15, dateWithOffset, dateManager.getDayTimeFormatUtc(), 'm');
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setDisplayTooltip(val: boolean) {
        this.displayTooltip = val;
    }

    @Mutation
    public setEventHostType(val: number) {
        this.eventHostType = val;
    }

    @Mutation
    public setHostessFormError(payload: ObjectPropertyType) {
        this.hostessFormErrors[payload.key] = payload.val;
    }

    @Mutation
    public setEventFormError(payload: ObjectPropertyType) {
        this.eventFormErrors[payload.key] = payload.val;
    }

    @Mutation
    public removeEventFormError(key) {
        const { eventFormErrors } = this;
        delete eventFormErrors[key];
        this.eventFormErrors = { ...eventFormErrors };
    }

    @Mutation
    public setEventFormErrors(errors: any) {
        this.eventFormErrors = { ...errors };
    }

    @Mutation
    public clearHostessFormError() {
        this.hostessFormErrors = {};
    }

    @Mutation
    public clearEventFormError() {
        this.eventFormErrors = {};
    }

    @Mutation
    public setHostess(hostess: CreateEventHostessType) {
        this.hostess = { ...hostess };
    }

    @Mutation
    public clearHostess() {
        this.hostess = null;
    }

    @Mutation
    public clearEventData() {
        this.eventData = { ...initialEventCreateData() };
    }

    @Mutation
    public setEventData(val: CreateEventType) {
        this.eventData = { ...val };
    }

    @Mutation
    public setEventProperty(value: ObjectPropertyType) {
        const { key, val } = value;

        this.eventData[key] = val;
    }

    @Mutation
    public setEventHostessData(val: CreateEventHostessType) {
        this.eventData.hostess = { ...val };
    }

    @Mutation
    public setEventType(val: string) {
        this.eventData.type = val;
    }

    @Mutation
    public setEventShippingData(val: EventShippingAddressType) {
        this.eventData.shippingAddress = { ...val };
    }

    @Mutation
    public setRedirectUrl(val: string|null = null) {
        this.redirectUrl = val;
    }

    @Action()
    public displayFormErrorsTooltip() {
        this.setDisplayTooltip(true);
        setTimeout(() => {
            this.setDisplayTooltip(false);
        }, FORM_ERROR_TOOLTIP.timeOutInterval);
    }

    @Action()
    public async eventFormSubmit(): Promise<CreateEventResultType> {
        this.setLoading(true);

        try {
            const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                hostess, description, shippingAddress, ...eventData
            } = this.eventData;

            // const {
            //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
            //     city, country, county, secondAddress, postcode, ...formattedShippingAddress
            // } = shippingAddress;
            let eventHostess: null|HostessInvitationInputType
                |KnownHostessInvitationInputType = null;

            if (this.hostess) {
                if (this.eventHostType === EVENT_HOST_TYPE.new) {
                    eventHostess = {
                        email: this.hostess.email,
                        address: this.hostess.address,
                        firstName: this.hostess.firstName,
                        lastName: this.hostess.lastName,
                        phoneNumber: this.hostess.phoneNumber,
                    };
                } else if (this.eventHostType === EVENT_HOST_TYPE.me
                     || this.eventHostType === EVENT_HOST_TYPE.known) {
                    eventHostess = {
                        id: this.hostess.id,
                    };
                }
            } else {
                throw Error("VIP doesn't exist");
            }

            const data: CreateEventParamsType = ObjectProcessor.removeEmptyProperties({
                ...eventData,
                hostessInvitation: eventHostess,
                description: JSON.stringify(description.ops),
                eventDateTo: dateAccountedToWinterTime(eventData.eventDateTo),
                eventDateFrom: dateAccountedToWinterTime(eventData.eventDateFrom),
                campaignDateTo: dateAccountedToWinterTime(eventData.campaignDateTo),
                // shippingAddress: formattedShippingAddress,
            });

            this.validateEventForm(data);

            if (this.eventHostType === EVENT_HOST_TYPE.new) {
                this.validateHostessForm();
            }

            if (this.eventHostType !== EVENT_HOST_TYPE.no) {
                this.setHostessToEvent();
            }

            if (Object.keys(this.eventFormErrors).length === 0
             && (this.eventHostType !== EVENT_HOST_TYPE.new
                || Object.keys(this.hostessFormErrors).length === 0
             )
            ) {
                const res: CreateEventResultType = await this.createEvent(data);
                return res;
            }

            this.displayFormErrorsTooltip();

            this.setLoading(false);
            throw new Error();
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public clearEventCreate() {
        this.setEventHostType(EVENT_HOST_TYPE.undefined);
        this.clearHostessFormError();
        this.clearEventFormError();
        this.clearHostess();
        this.setEventData({
            ...initialEventCreateData(),
            eventDateFrom: '',
            eventDateTo: '',
            campaignDateTo: '',
        });
    }

    @Action()
    public async setHostessMe() {
        const data = profile.getProfileData;
        this.setHostess(data as CreateEventHostessType);
    }

    @Action()
    public validateEventForm(data: CreateEventParamsType) {
        this.clearEventFormError();

        const errorList = formEventValidation(data);

        errorList.forEach((error) => this.setEventFormError(error));
    }

    @Action()
    public validateHostessForm() {
        this.clearHostessFormError();

        const errorList = formHostessValidation(this.hostess);
        errorList.forEach((error) => this.setHostessFormError(error));
    }

    @Action()
    async createEvent(data: CreateEventParamsType): Promise<CreateEventResultType> {
        try {
            let dataToSend = data;
            if (!settings.getEventGuestsInviteOptionEnabled) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { allowGuestsToInvite, ...resData } = data;
                dataToSend = { ...resData };
            }

            const resData: CreateEventResultType = await EventRepository
                .createEvent(dataToSend);

            return resData;
        } catch (e) {
            this.displayFormErrorsTooltip();

            const errors = ErrorsProcessor.process(e);
            this.setEventFormErrors(errors.form);
            throw e;
        }
    }

    @Action()
    setHostessToEvent() {
        if (this.hostess) {
            const {
                address, email, id, firstName, lastName, phoneNumber,
            } = this.hostess;

            this.setEventHostessData({
                address,
                email,
                id,
                firstName,
                lastName,
                phoneNumber,
            });
        }
    }

    @Action()
    populateShippingDetailsData() {
        const { firstName, lastName, phoneNumber } = profile.getProfileData;
        this.setEventShippingData({
            ...defaultShippingAddress(),
            firstName,
            lastName,
            phoneNumber,
        });
    }
}

export default getModule(EventCreate);
