const sale = `type Sale {
    id: String,
    enrollee: Enrollee,
    customer: Enrollee,
    date: String!
    value: Float!,
    grossValue: Float!,
    commissionableValue: Float!,
    psc: Float,
    discount: Float,
    products: [Product],
    shippingCost: Float,
    paymentInfo: [SalePaymentInfo],
    manual: Boolean!,
    referenceId: String,
    comment: String,
    type: String,
    qualificationValue: Float!,
}`;

const product = `type Product {
    id: String,
    cv: Float,
    price: Float,
    description: String,
    applicableVat: Float,
    category: String,
    net: Float,
    quantity: Int,
    total: Float,
    vat: Float,
    discounts: Float,
    qv: Float,
}`;

const paymentInfo = `type SalePaymentInfo {
    currency: String
    tenderType: String
    amount: String
    paymentMethod: String
    addressLine1: String
    addressLine2: String
    city: String
    county: String
    postalCode: String
    country: String
    paymentDate: String
}`;

const customerOrderQuery = `
    customerOrders(customerId: String): [Sale]
`;

export {
    sale, customerOrderQuery, product, paymentInfo,
};
