
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import register from '@/modules/Register';
import countries from '@/modules/Countries';
import registerProfilePicture from '@/modules/Register/registerProfilePicture';
import authenticator from '@/modules/Auth/services/authenticator';
import { LEXIS_NEXIS_STATUS } from '@/modules/Register/constants';
import addressSelect from '@/modules/AddressSelect';
import Content from './Content/index.vue';
import CanNotRegister from './CanNotRegister/index.vue';

@Component({
    components: {
        Content,
        CanNotRegister,
    },
})
export default class Register extends Vue {
    @Get(register) private loading!: boolean;

    @Get(countries) private countriesLoading!: boolean;

    @Get(register) private canRegister!: boolean;

    @Get(register) private lexisNexisStatus!: string|null;

    get contentLoaded(): boolean {
        return !this.loading && !this.countriesLoading;
    }

    get token(): string {
        const { token } = this.$route.query;
        return token.toString();
    }

    async mounted() {
        addressSelect.resetData();
        const { logged } = this.$route.query;

        if (logged !== 'true') {
            await authenticator.logout(false);
        }
        registerProfilePicture.clearImageData();
        register.clearFormData();
        register.clearFormErrors();
        register.setImageDialogOpen(false);
        register.setCameraDialogOpen(false);
        register.getUserData(this.token);
        countries.getCountries(this.token);
    }

    @Watch('lexisNexisStatus')
    handleAdditionalForm(): void {
        if (this.lexisNexisStatus === LEXIS_NEXIS_STATUS.refer) {
            this.$router.push({
                name: 'registerAdditionalDetails',
                query: { token: this.token },
            });
        }
    }
}
