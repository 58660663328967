
import { Vue, Component } from 'vue-property-decorator';
import LinkInvite from './LinkInvite/index.vue';

@Component({
    components: {
        LinkInvite,
    },
})
export default class shareShoppingLink extends Vue {}
