// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { CommissionStatementsType } from './types';

export default class GetCommissionStatementsQuery
    extends AbstractQueryResource<GetCommissionStatementsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            profile {
                commissionStatements {
                    id,
                    label,
                    downloadUrl
                },
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getCommissionStatements';
    }
}

export type GetCommissionStatementsResultType = {
    profile: {
        commissionStatements: CommissionStatementsType[];
    },
}
