
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboardStats from '@/modules/Dashboard/stats';
import { RankRequirementsType, RankType } from '@/api/graphQL/graphNodes/types';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import { MIN_PV } from '@/modules/constants';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class PBV extends Vue {
    @Get(dashboardStats) private stats!: RankRequirementsType[];

    @Prop() private requirements!: RankRequirementType[];

    @Prop() private rank!: RankType;

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(this.metric).title;
    }

    get metric(): string {
        return RankRequirementManager.rankRequirementMetricConstants.pv;
    }

    get pv(): number {
        const stat = this.stats.find((item) => item.metric === this.metric);
        return !stat ? 0 : Number(stat.value);
    }

    get display150req(): boolean {
        const { label } = this.rank;
        return label === 'Stylist';
    }

    get pbvRequirement(): string|null {
        const pv = this.requirements
            .find((item) => item.metric === this.metric);

        return pv ? pv.value : null;
    }

    get tooltipText(): string {
        if (this.display150req) {
            return `Stylist qualification requires minimum ${MIN_PV} FLV`;
        }

        return `${this.rank.label} qualification is ${this.pbvRequirement}`;
    }

    get progressValue(): number|null {
        const x = this.pv * 100;

        if (this.requirements.length === 0) {
            return null;
        }

        if (!this.pbvRequirement) {
            return null;
        }

        if (Number(this.pbvRequirement) === 0) {
            return 0;
        }

        return x / Number(this.pbvRequirement);
    }
}
