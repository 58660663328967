
import { DownlineProfileType } from '@/api/graphQL/graphNodes/types';
import { ErrorType } from '@plumtreesystems/utils';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { NATIONAL_NUMBER_MESSAGE, BIRTHDATE_MESSAGE } from '@/utils/messages/formValidation';
import {
    COUNTRY_SELECT_OPTIONS, TOOLTIP_FIRST_NAME_TOOLTIP,
    TOOLTIP_LAST_NAME_TOOLTIP,
} from '@/modules/constants';
import { SelectOptionsType } from '@/components/select/types';
import { DATE_PICKER_TYPES } from '@/components/datePicker/constants';
import FormFieldTooltip from '@/projectComponents/formFieldTooltip/index.vue';
import { CountriesType } from '@/modules/Event/types';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';
import system from '@/modules/System';
import { Get } from '@/utils/vuex-module-mutators';

@Component({
    components: {
        FormFieldTooltip,
        PropertyInfoDisplay,
    },
})
export default class PersonalDetails extends Vue {
    @Get(system) private screenType!: string;

    @Prop() idTag!: string;

    @Prop() formErrors!: ErrorType;

    @Prop() formData!: DownlineProfileType;

    @Prop({ default: false }) isCustomer!: boolean;

    @Prop({ default: false }) isHost!: boolean;

    @Prop({ default: [] }) countries!: CountriesType[];

    @Prop({ default: true }) canUpdateEmail!: boolean;

    @Prop({ default: '' }) componentView!: string;

    get firstNameTooltip(): string {
        return TOOLTIP_FIRST_NAME_TOOLTIP;
    }

    get lastNameTooltip(): string {
        return TOOLTIP_LAST_NAME_TOOLTIP;
    }

    get dateTypes() {
        return DATE_PICKER_TYPES;
    }

    get countrySelectOptions(): SelectOptionsType[] {
        return this.countries.map((item) => ({ name: item.longLabel, value: item.shortLabel }));
    }

    get gbCountry(): string {
        return COUNTRY_SELECT_OPTIONS.gb;
    }

    get isMobile(): boolean {
        return this.screenType === 'mobile';
    }

    get optionalContacts() {
        return this.componentView === 'editMyCustomer';
    }

    handleValue($event, value, key) {
        this.$emit('handleValue', $event, value, key);
    }

    getHelperText(val: string) {
        if (val === 'birthDate') {
            return BIRTHDATE_MESSAGE;
        }
        return NATIONAL_NUMBER_MESSAGE;
    }
}
