import { RankType } from '@/api/graphQL/graphNodes/types';
import resolveStatusType from '@/projectComponents/enrolleeLegItem/Item/services/resolveStatusType';
import { CalculateStatusType } from '@/utils/graphql-mock-server/types';

const calculateStatus: (teamAmbassador: RankType|undefined, data: CalculateStatusType,
     returnType?: string) => string = (teamAmbassador, data, returnType = 'status') => {
         if (!teamAmbassador) {
             return '';
         }

         let sum = 0;
         let count = 0;
         if (teamAmbassador.requirements === null) {
             return '';
         }

         teamAmbassador.requirements.forEach((requirement) => {
             const stat = data.stats.find((item) => item.metric === requirement.metric);

             if (stat) {
                 const statVal = Number(stat.value);
                 const requirementVal = Number(requirement.value);

                 if (statVal >= requirementVal) {
                     sum += 100;
                 } else {
                     const partRes = Math.round((statVal * 100)) / requirementVal;
                     sum += Math.round(partRes * 100) / 100;
                 }
                 count += 1;
             }
         });

         const percentage: number = sum > 0 && count > 0
             ? Math.round((sum / count) * 100) / 100 : 0;

         if (returnType === 'status') {
             return resolveStatusType(percentage);
         }

         return percentage.toString();
     };

export default calculateStatus;
