
import noPermission from '@/modules/NoPermission';
import auth from '@/modules/Auth';
import authenticator from '@/modules/Auth/services/authenticator';
import { ErrorType } from '@plumtreesystems/utils';
import env from '@/environment';
import { Get } from '@/utils/vuex-module-mutators';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RegistrationStepsOptionsType } from '@/projectComponents/registrationSteps/types';
import { REGISTRATION_STEPS } from '@/modules/Register/constants';
import RegistrationSteps from '@/projectComponents/registrationSteps/index.vue';
import { SHOP_REDIRECT_ERROR_MESSAGE, SHOP_REDIRECT_MESSAGE } from '@/modules/ShopUrl/messages';
import componentsControls from '@/modules/ComponentsControls';
import shopUrl from '@/modules/ShopUrl';

@Component({
    components: {
        RegistrationSteps,
    },
})
export default class NoPermissionAmbassadorKit extends Vue {
    @Get(noPermission) private errors!: ErrorType;

    @Get(auth) private paidForKit!: boolean;

    @Get(noPermission) private name!: string;

    @Get(shopUrl, 'url') private buyNowUrl!: string;

    get contentText() {
        return `You are logged in as ${this.name}. You need to purchase the Starter Kit to proceed.`;
    }

    get registrationSteps(): RegistrationStepsOptionsType[] {
        return REGISTRATION_STEPS;
    }

    get path(): string {
        return env.VUE_APP_FULL_PATH;
    }

    get redirectPlaceholderUrl(): string {
        return `${this.path}${this.$router.resolve({ name: 'redirectPlaceholder' }).href}`;
    }

    private kitCheck = 0;

    async handleOpenLink() {
        try {
            const newPage = window.open(this.redirectPlaceholderUrl, '_blank');
            componentsControls.showSuccessMessage({ message: SHOP_REDIRECT_MESSAGE, time: 2000 });

            await shopUrl.getUrl(env.VUE_APP_SHOP_KIT_PURCHASE_LINK);
            if (this.buyNowUrl !== null) {
                newPage!.location.href = this.buyNowUrl;
            }
        } catch {
            componentsControls.showNextMessage({ message: SHOP_REDIRECT_ERROR_MESSAGE, type: 'error' });
        }
    }

    handleLogOut() {
        authenticator.logout();
    }

    @Watch('paidForKit')
    handleKitBought(paidForKit) {
        if (paidForKit) {
            this.$router.push({ name: 'dashboard' });
        }
    }

    mounted() {
        this.kitCheck = setInterval(() => {
            auth.checkAmbassadorKit();
        }, 30000);
    }

    beforeDestroy() {
        clearInterval(this.kitCheck);
    }
}
