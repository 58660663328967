import Vue from 'vue';
import VueRouter from 'vue-router';

import env from '@/environment';
import authenticator from '@/modules/Auth/services/authenticator';
import auth from '@/modules/Auth';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import authLocalStorageManager from '@/modules/Auth/services/authLocalStorageManager';
import dateManager from '@/utils/time';
import { routerInstancesRemover, routerParentsObjectProcessor } from '@/modules/System/helpers';
import localStorageManager from '@/utils/local-storage-manager';
import { RETURN_ROUTE_LOCAL_STORAGE_KEY } from '@/modules/System/constants';
import routes from './routes';
import { availableRoute } from './services/availableRoute';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: env.BASE_URL,
    routes: routes(),
});

const publicRoutes = [
    'login', 'auth', 'authError', 'error', 'noPermission',
    'register', 'registerAdditionalDetails', 'sendRegistration', 'afterRegistration',
    'hostessRegistration', 'eventInvite', 'eventInvitationView', 'customerRegistration',
    'redirectPlaceholder',
];

const handleSetReturnRoute = (to: any = null) => {
    if (!to) {
        localStorageManager.clearStoreState(RETURN_ROUTE_LOCAL_STORAGE_KEY);
    } else {
        const { matched, query, ...valData } = to;

        const matchedList = matched.map((item) => {
            let itemData = routerInstancesRemover(item);

            itemData = routerParentsObjectProcessor(itemData);

            return itemData;
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { directLogin, ...formatedQuery } = query;

        localStorage.setItem(RETURN_ROUTE_LOCAL_STORAGE_KEY, JSON.stringify({
            returnRoute: { ...valData, query: formatedQuery, matched: [...matchedList] },
            expirationDate: dateManager.getCurrentDateWithOffset(10, dateManager.getDayTimeFormat(), 'm'),
        }));
    }
};

router.beforeEach((to, from, next) => {
    const { directLogin } = to.query;

    if (directLogin === '1' && !publicRoutes.includes(to.name || '')) {
        handleSetReturnRoute(to);
        authenticator.startLogin();
    }

    if (env.VUE_APP_TEST === 'true') {
        next();
    } else if (to.name === 'noPermission' && from.name === 'error') {
        next(false);
    } else if (authLocalStorageManager.getAuthToken() === null
    && !publicRoutes.includes(to.name || '')) {
        next(false);
        authenticator.logout(true, 'login');
    } else if (
        !authenticator.authTokenFromStorage
        && !publicRoutes.includes(to.name || '')
        && env.VUE_APP_MOCK_AUTHENTICATOR === 'false'
    ) {
        next('/login');
    } else if (!availableRoute(to.name!)) {
        const error = new Error('route is not allowed');
        ErrorsProcessor.process(error);
    } else {
        next();
    }
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach((to, from) => {
    if (!authenticator.getAuthenticated && !auth.authenticationInProgress
        && to.name && !['auth', 'register', 'registerAdditionalDetails', 'sendRegistration', 'afterRegistration',
        'hostessRegistration', 'customerRegistration', 'eventInvite', 'eventInvitationView'].includes(to.name)) {
        authenticator.handleUserDataLoading(to);
    }
});

authenticator.setRouter(router);
ErrorsProcessor.setRouter(router);

export default router;
