// eslint-disable-next-line import/no-cycle
import AbstractQueryResource from '../AbstractQueryResource';
import { StatsType, TransactionsType } from './types';

export default class GetSisterCreditQuery extends AbstractQueryResource<GetSisterCreditResultType> {
    protected getQuery() {
        return `query ${this.getName()}($offset: Int, $limit: Int, $from: DateTime, $to: DateTime) {
            profile {
                balance {
                    metric
                    value
                }
                transactions (offset: $offset, limit: $limit, from: $from, to: $to) {
                    date,
                    refId,
                    description,
                    type,
                    amount,
                    currency
                }
                transactionsCount(from: $from, to: $to)
            }
        }`;
    }

    protected getName() {
        return 'sisterCredit';
    }

    protected getCacheCondition() {
        return null;
    }
}

export type GetSisterCreditResultType = {
    profile: {
        balance: StatsType;
        transactions: TransactionsType[];
        transactionsCount: number;
    }
}

export type GetSisterCreditParamsType = {
    offset: number;
    limit: number;
    from: string|null;
    to: string|null;
}
