<template>
    <div :aria-hidden="true">
        <div class="BusinessShape__LegendItem">
            <div class="BusinessShape__LegendShape BusinessShape__LegendShape--active"></div>
            <span>Qualified</span>
            <div>
                <Icon icon="info" />
                <md-tooltip>
                    Stylists with 150+ FLV
                </md-tooltip>
            </div>
        </div>
        <div class="BusinessShape__LegendItem">
            <div class="BusinessShape__LegendShape
             BusinessShape__LegendShape--partiallyActive"></div>
            <span>Partially Qualified</span>
            <div>
                <Icon icon="info" />
                <md-tooltip>
                    Stylists with some FLV, but less than 150
                </md-tooltip>
            </div>
        </div>
        <div class="BusinessShape__LegendItem">
            <div class="BusinessShape__LegendShape BusinessShape__LegendShape--notActive"></div>
            <span>Not Qualified</span>
            <div>
                <Icon icon="info" />
                <md-tooltip>
                    Stylists with zero FLV
                </md-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BusinessShapeLegend',
};
</script>

<style lang="scss">
    @import './styles.scss';
</style>
