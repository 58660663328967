import dateManager from '@/utils/time';

const dateAccountedToWinterTime = (date): string => {
    // extract timezone based on DTS (summer/winter time)
    const timezone = dateManager.getDateTime(date, 'ZZ');

    // replace is required because from backend timezone is +00:00 and in frontend we use +0000
    return `${date.slice(0, -5).replace('+', '')}${timezone}`;
};

export default dateAccountedToWinterTime;
