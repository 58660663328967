// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { PersonalCommissionStatementsType } from './types';

export default class GetPersonalStatementsQueryQuery
    extends AbstractQueryResource<GetPersonalStatementsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($limit: Int!, $offset: Int!) {
            profile {
                personalStatements(limit: $limit, offset: $offset) {
                    id,
                    label,
                    downloadUrl,
                    presignedDownloadUrl,
                },
                personalStatementsCount
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getPersonalStatements';
    }
}

export type GetPersonalStatementsParamsType = {
    limit: number;
    offset: number;
}

export type GetPersonalStatementsResultType = {
    profile: {
        personalStatements: PersonalCommissionStatementsType[];
        personalStatementsCount: number;
    },
}
