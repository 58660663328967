
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import myCustomers from '@/modules/MyCustomers';
import system from '@/modules/System';
import profile from '@/modules/Profile';
import Skeleton from '@/projectComponents/skeleton/List/index.vue';
import { EnrolleeCustomerOrderType, BaseOrderType } from '@/api/graphQL/graphNodes/types';
import OrdersList from '@/projectComponents/OrdersList/index.vue';
import { PaginationParamsType } from '@/components/pagination/types';
import { scrollToTop } from '@plumtreesystems/utils';
import Header from './Header/index.vue';

@Component({ components: { OrdersList, Header, Skeleton } })
export default class MyCustomerOrders extends Vue {
    @Get(myCustomers) private customerOrders!: EnrolleeCustomerOrderType;

    @Get(myCustomers, 'ordersLoading') private loading!: boolean;

    @Get(myCustomers) private orderOffset!: number;

    @Get(myCustomers) private orderLimit!: number;

    @Get(myCustomers) private searchedOrders!: BaseOrderType[];

    @Get(myCustomers) private orderTotal!: number;

    @Get(myCustomers) private loadingInBackground!: number;

    @Get(myCustomers) private expandedOrders!: string[];

    @Get(myCustomers) private displaySearchResults!: boolean;

    @Get(myCustomers) private searchLoading!: boolean;

    @Get(profile, 'data.region') private region!: string;

    @Get(system) private screenType!: string;

    get contentLoaded(): boolean {
        return this.loading || this.searchLoading;
    }

    get displayOrders(): BaseOrderType[] {
        if (this.displaySearchResults) {
            return this.searchedOrders;
        }
        return this.customerOrders.searchOrders;
    }

    get enroleeId() {
        const { id } = this.$route.params;
        return id;
    }

    get subsceneTitle() {
        if (this.loading) {
            return 'Customer orders';
        }
        return `${this.customerFullName}'s orders`;
    }

    get isMobile() {
        return this.screenType === 'mobile';
    }

    get customerFullName(): string {
        if (this.customerOrders) {
            return `${this.customerOrders.firstName} ${this.customerOrders.lastName}`;
        }
        return '';
    }

    handleClose() {
        myCustomers.setOrderOffset();
    }

    handleSearchToggle() {
        myCustomers.toggleShowOrdersSearch();
    }

    handleOpen() {
        const { id } = this.$route.params;
        myCustomers.getCustomerOrders({ enrolleeId: id, offset: this.orderOffset });
    }

    handleOrderOpenToggle(id: string) {
        myCustomers.toggleExpandedOrdersList(id);
    }

    async handlePagination(params: PaginationParamsType) {
        try {
            const { offset } = params;
            const { id } = this.$route.params;
            await myCustomers.getCustomerOrders({ enrolleeId: id, loadPage: true, offset });
            scrollToTop('#app > div.MyCustomers__Scene.App__Layout > div.SubScene__Container.SubScene__Container--open > div.SubScene__Content.SubScene__Content--noPadding');
        // eslint-disable-next-line no-empty
        } finally {}
    }

    beforeDestroy() {
        myCustomers.setSearchedOrders([]);
        myCustomers.setSearchedOrdersOptions([]);
        myCustomers.setCustomerOrdersData();
        myCustomers.clearExpandedOrdersList();
        myCustomers.setDisplaySearchResults(false);
        myCustomers.setSearchQuery('');
    }
}
