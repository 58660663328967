
import { SelectOptionsType } from '@/components/select/types';
import {
    Vue, Component, Prop,
} from 'vue-property-decorator';
import SelectItem from './item.vue';

@Component({
    components: {
        SelectItem,
    },
})
export default class AddressSelectBase extends Vue {
    @Prop({ required: true }) searchWord!: string;

    @Prop({ required: true }) loading!: boolean;

    @Prop({ required: true }) options!: SelectOptionsType[];

    @Prop({ default: '' }) private value!: string;

    @Prop({ default: true }) private required!: boolean;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: 'Address' }) private label!: string;

    @Prop({ default: null }) private region!: string|null;

    @Prop({ required: true }) fullResultSize!: number;

    @Prop() error!: string;

    @Prop() icon!: string;

    get containerClasses() {
        return [
            'AddressSelect__Container',
        ];
    }

    get showKeepTypingHelper(): boolean {
        return this.options.length === 0 && this.fullResultSize > 0;
    }

    get selectOptions(): SelectOptionsType[] {
        return (this.showKeepTypingHelper) ? [{
            name: 'Your search was too broad, keep typing for a better result',
            value: null,
            clickable: false,
        }] : this.options;
    }

    handleInput(val: string = '') {
        this.$emit('input', val);
    }

    handleSelect(val: SelectOptionsType) {
        this.$emit('select', val);
    }

    loadOptions() {
        this.$emit('load');
    }
}
